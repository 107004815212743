import { format, subMinutes } from 'date-fns';
import {subDays, startOfMonth, differenceInHours, subHours, startOfToday, endOfToday, startOfDay, endOfDay} from 'date-fns'

export function formatDate(date: Date) {
  return format(subMinutes(date, date.getTimezoneOffset()), "yyyy-MM-dd hh:mm:ss aa");
}

const AUTOMATIC_LAUNCH_TIMESTAMP_SUBTRACTION_HOURS = 2;
export function calculateLaunchTimestamp(startDate: Date) {
  const diff = differenceInHours(startDate, new Date());
  const shouldLaunchNow = diff < AUTOMATIC_LAUNCH_TIMESTAMP_SUBTRACTION_HOURS;
  return shouldLaunchNow
    ? format(new Date(), "yyyy-MM-dd'T'HH:mm")
    : subHours(startDate, AUTOMATIC_LAUNCH_TIMESTAMP_SUBTRACTION_HOURS);
}


export type TDateRangeOption = 'Today' | 'Last 7 Days' | 'Last 30 Days' | 'This Month' | 'Custom';

export const DATE_RANGE_OPTIONS: Record<
  string,
  {from: Date; to: Date}
> = {
  'Today': {
    from : startOfToday(),
    to: endOfToday()
  },
  'Last 7 Days': {
    from : subDays(startOfToday(), 6),
    to: endOfToday()
  },
  'Last 30 Days': {
    from : subDays(startOfToday(), 29),
    to: endOfToday()
  },
  'This Month': {
    from: startOfMonth(startOfToday()),
    to: endOfToday()
  }
}

// export function retrieveFromTo(dateOption: TDateRangeOption) {
//   return DATE_RANGE_OPTIONS[dateOption];
// }
