import React, { useEffect, useRef } from "react";
import { TCluster, TParticipant, TTask } from "../../../types/app.types";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { DialogCloseReason, DialogCloseEvent } from "../../../types/common.types";
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';

export type AddParticipantDialogProp = {
  data: TTask;
  open: boolean;
  onClose: (e?: DialogCloseEvent) => void;
  onConfirm: (payload?: any) => void;
}
/**
 * Displays a dialog which allows user to pass in multiple emails for adding bulk users
 */
export default function AddParticipantDialog(props: AddParticipantDialogProp) {
  const { onClose, onConfirm, open, data } = props;
  const handleClose = (e: any, reason: DialogCloseReason) => {
    onClose({reason});
  };
  const [emails, setEmails] = React.useState<string[]>([]);
  const handleConfirm = () => {
    const participants: TParticipant[] = emails.map(email => ({
      firstName: "",
      lastName: "",
      email
    }))
    onConfirm(participants);
    onClose({reason: 'confirmClick'});
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="add-participant-dialog-title" open={open}>
      <DialogTitle data-testid="add-participant-dialog-title">
        Add additional participant(s)
      </DialogTitle>

      <DialogContent>
        <DialogContentText data-testid="add-participant-dialog-description">
          Task address: {data.publicIPv4Address}
        </DialogContentText>
        <ReactMultiEmail
          placeholder="placeholder"
          emails={emails}
          onChange={(_emails: string[]) => {
            setEmails(_emails);
          }}
          validateEmail={email => {
            return isEmail(email); // return boolean
          }}
          getLabel={(
            email: string,
            index: number,
            removeEmail: (index: number) => void,
          ) => {
            return (
              <div data-tag key={index}>
                {email}
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </span>
              </div>
            );
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button data-testid="add-participant-dialog-cancel-button" onClick={() => onClose({reason: 'cancelClick'})}>
          Cancel
        </Button>
        <Button data-testid="add-participant-dialog-confirm-button" color="primary" onClick={handleConfirm} autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
