import { TParticipant } from "../types/app.types";

/** simple utility function to get name with title */
export function formatParticipantName(participant: TParticipant): string {
  let formattedStr = "";
  const coerceStr = (val: string|undefined) => val && val.trim();
  const capitalize = (val: string|undefined) =>
    val ? (val.charAt(0).toUpperCase() + val.substr(1)) : "";

  if (coerceStr(participant.title)) {
    const title = (capitalize(participant.title) as string).replace(".", "");
    formattedStr += title + ". ";
  }
  if (coerceStr(participant.firstName))
    formattedStr += capitalize(participant.firstName) + " ";
  if (coerceStr(participant.lastName))
    formattedStr += capitalize(participant.lastName) + " ";

  return formattedStr.trim();
}
