import React, { useRef, useEffect, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import { CircularProgress, Paper } from '@material-ui/core';
import ParticipantRegistrationStep from './ParticipantRegistrationStep';
import ClassCreationReviewStep from './ClassCreationReviewStep';
import ClassInfoStepForm from './ClassInfoStepForm';
import { TClusterForm, TNewCluster, TParticipant } from '../../../types/app.types';
import { EMPTY_PARTICIPANT } from './ParticipantsList';
import { useFormik } from 'formik';
import * as EmailValidator from 'email-validator';
import { NavLink } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import { groupedTasksListOptionSelector } from '../../../store/selectors';
import { useSelector } from 'react-redux';
const CLASS_CREATION_STEPS = [
  { label: 'Fill out class information', headline: 'Class information' },
  { label: 'Register participants', headline: 'Participants' },
  { label: 'Review', headline: 'Participants' },
];

// comment following variable for UI testing
export const NEW_CLASS_INITIAL_VALUES: TNewCluster = {
  className: "",
  trainer: "",
  coTrainer: "",
  support: "",
  taskCount: 1,
  taskDefinition: "",
  startDate: "", // format(new Date(), "yyyy-MM-dd'T'HH:mm"),
  endDate: "",
  participants: [EMPTY_PARTICIPANT],
  initiatingUser: "",
  launchTimestamp: ""
};

const MOCK_PARTICIPANTS: TParticipant[] = [
  // {...EMPTY_PARTICIPANT, firstName: "Milo", lastName: "Kang",  email: 'test1@email.com'},
  // {...EMPTY_PARTICIPANT, firstName: "", lastName: "",  email: 'test2@email.com'},
  // EMPTY_PARTICIPANT,
  // EMPTY_PARTICIPANT,
  EMPTY_PARTICIPANT
];

// uncomment following variable for UI testing
// export const NEW_CLASS_INITIAL_VALUES: TNewCluster = {
//   className: "Test Class",
//   trainer: "30f1b763-dc64-4b03-8dc1-668a88786659",
//   coTrainer: "",
//   support: "",
//   taskCount: MOCK_PARTICIPANTS.length,
//   taskDefinition: "jira-fundamentals-class",
//   startDate: format(add(new Date(), {days: 2}), "yyyy-MM-dd'T'HH:mm"),
//   endDate: format(add(new Date(), {days: 4}), "yyyy-MM-dd'T'HH:mm"),
//   participants: MOCK_PARTICIPANTS
// };

type TParticipantValidationError = {
  email?: string;
}

type TParticipantValidationErrors = Record<string|number, TParticipantValidationError>;

type TNewClusterValidationError = {
  className?: string;
  taskDefinition?: string;
  trainer?: string;
  startDate?: string;
  endDate?: string;
  participants?: TParticipantValidationErrors;
}

/* istanbul ignore next */
function validate(values: TNewCluster): TNewClusterValidationError {
  const errors: TNewClusterValidationError = {};

  if (!values.className || values.className.trim() === "") {
    errors.className = 'Class name field is required';
 //  } else if (values.className.length > 15) {
 //    errors.className = 'Must be 15 characters or less';
  }

  if (!values.taskDefinition || values.taskDefinition.trim() === "") {
    errors.taskDefinition = 'Task definition field is required';
  }

  if (!values.trainer || values.trainer.trim() === "") {
    errors.trainer = 'Trainer field is required.';
  }

  if (!values.startDate || values.startDate.trim() === "")
    errors.startDate = 'Start date field is required';
  if (values.startDate && new Date(values.startDate).getTime() < Date.now())
    errors.startDate = 'Start date must be after current time';

  if (!values.endDate || values.endDate.trim() === "")
    errors.endDate = 'End date field is required';
  if (values.endDate && new Date(values.endDate).getTime() < Date.now())
    errors.startDate = 'End date must be after current time';
  if (values.startDate && values.endDate && new Date(values.endDate).getTime() < new Date(values.startDate).getTime())
    errors.endDate = 'End date must be after start date';

  
 if (values.participants.length) {
   errors.participants = values.participants
     .reduce((o: TParticipantValidationErrors, d: TParticipant, i) => {
        if (d.email && d.email.trim() !== "" && !EmailValidator.validate(d.email)) {
          o[i] = { email: 'You have entered invalid email address.' };
        }
       return o;
     }, {});
   if (Object.keys(errors.participants).length === 0) delete errors.participants;
 }
 return errors;
};

export type CreateClassStepperProp = {
  onSubmit: (event: {values: TNewCluster; createShared: boolean; launchOnStart: boolean; scheduleTermination: boolean}) => void
}
/**
 * Stepper Form
 */
export default function CreateClassStepper (props: CreateClassStepperProp) {
  const [activeStep, setActiveStep] = useState<number>(0);
  const steps = CLASS_CREATION_STEPS;
  // const taskDefinitions = useSelector(groupedTasksListOptionSelector);
  const [ createShared, setCreateSharedChecked] = useState(false);
  const [ launchOnStart, setLaunchOnStart] = useState(false);
  const [ scheduleTermination, setScheduleTermination] = useState(true);

  const formik = useFormik({
    initialValues: NEW_CLASS_INITIAL_VALUES,
    validate,
    // validateOnChange
    onSubmit: (values) => {
      if (props.onSubmit) props.onSubmit({values: {
        ...values,
        // taskDefinition: (
        //   createShared
        //   ? taskDefinitions.nonSharedMap[values.taskDefinition]
        //   : values.taskDefinition
        // ),
        taskCount: values.taskCount
      }, createShared, launchOnStart, scheduleTermination});
    },
  }); 

  

  const [ extraInstances, setExtra ] = useState<number>(0);
  const [ participantCount, setParticipantCount ] = useState<number>(
    formik.values.participants.length
    );

  const setParticipants = (val: TParticipant[]) => {
    // formik.values.participants = val;
    formik.setFieldValue('participants', val);
  };
  const handleTaskCount = (type: 'participant'|'extra', value: number) => {
    if (type === 'extra') {
      setExtra((prev: number) => {
        const newVal = value;
        // formik.values.taskCount = participants.length + newVal;
        // formik.setFieldValue('taskCount', participantCount + newVal);
        return newVal;
      });
    } else {
      if (value < participantCount) {
        setParticipants([...formik.values.participants].slice(0, value));
      }
  
      if (value > participantCount) {
        setParticipants([
          ...formik.values.participants,
          ...Array(value - participantCount).fill(EMPTY_PARTICIPANT)
        ]);
      }
  
      if (value !== participantCount) {
        // triggerRef.current = 'number-control';
        setParticipantCount(value);
        // formik.setFieldValue('taskCount', value + extraInstances);
      }
    }
  }

  const handleNext = async () => {
    // console.log('handleNext', activeStep, formik.dirty);
    if (activeStep === 0) {

      // setActiveStep((prevActiveStep) => prevActiveStep + 1);
      // formik.setFieldValue
      await formik.validateForm().then(error => {
        formik.setTouched({
          className: true,
          startDate: true,
          endDate: true,
          taskDefinition: true,
          trainer: true
        });

        const firstStepErrors = {...error};
        if (firstStepErrors.participants)
          delete firstStepErrors.participants;

        if(!Object.keys(firstStepErrors).length)
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
      });
    } else if (activeStep === 1) {
      await formik.validateForm().then(error => {
        if(!Object.keys(error).length)
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
      });
    } else if (activeStep === 2) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      formik.submitForm();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    // if (formik.isValid) {
    //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // }
      
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  function getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return <ClassInfoStepForm formik={formik}
          scheduleTermination={scheduleTermination}
          setScheduleTermination={setScheduleTermination}
          launchOnStart={launchOnStart}
          setLaunchOnStart={setLaunchOnStart}
          createShared={createShared}
          setCreateSharedChecked={setCreateSharedChecked}
          />;
      case 1:
        return <ParticipantRegistrationStep formik={formik}
          participantCount={participantCount}
          setParticipantCount={setParticipantCount}
          setParticipants={setParticipants}
          handleTaskCount={handleTaskCount} />;
      case 2:
        return <ClassCreationReviewStep formik={formik}
          handleTaskCount={handleTaskCount}
          createShared={createShared}
          extraInstances={extraInstances} />;
      default:
        return <div className="d-flex justify-content-center align-items-center" style={{minHeight: '300px'}}>
          <CircularProgress />
        </div>;
    }
  }

  useEffect(( ) => {
    // const shared = isSharedCluster(formik.values.taskDefinition);
    const taskCount = createShared ? (1 + extraInstances) : (participantCount + extraInstances);
    // console.log("taskCount", createShared, taskCount)
    formik.setFieldValue('taskCount', taskCount);
  }, [extraInstances, participantCount])

  return (
    <Paper data-testid="create-class-stepper-wrapper" className="d-flex flex-column p-2 p-md-3 p-lg-4" elevation={1}>
      <div style={{minHeight: '2rem', display: 'flex', justifyContent: "flex-end"}}>
        {createShared ? (
          <Chip size="small"
          label="Shared Instance"
          color="primary"
          variant="default"/>
          )
         : ""}
      </div>
      <Stepper activeStep={activeStep} className="p-2" style={{flexGrow: 1, backgroundColor: 'transparent'}}>
        {steps.map((step,i) => (
          <Step data-testid={`create-class-step-${i}`} key={step.label}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <form onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit(e);
      }}>
      {activeStep === steps.length ? (
        <div>
          <div className="d-flex flex-column justify-content-center align-items-center"
            style={{minHeight: '300px', height: '50vh'}}>
            <CircularProgress />
            <Typography variant="body1" component="p" className="text-center mt-5" style={{maxWidth: "500px"}}>
              Once Class is created you will be redirected to list class page. <br/>
              If not redirected, please click button below.
            </Typography>
            <Button className="mt-5">
              <NavLink to="/list-tasks">
                Go to list
              </NavLink>
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div data-testid="step-content" className="p-3 px-4 p-lg-4">
            {getStepContent(activeStep)}
          </div>
          <div className="d-flex justify-content-end">
            <Button data-testid="step-back" className="mr-3"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>
            <Button 
              data-testid="step-next"
              variant="contained"
              color="primary" onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </div>
        </div>
      )}
      {/** This is for debugging purpose */}
      {/* <div className="d-flex">
        <pre className="w-50">
          {JSON.stringify(formik.values, null, "\t")}
        </pre>
        <pre className="w-25">
          {JSON.stringify(formik.errors, null, "\t")}
        </pre>
        <pre className="w-25">
          {JSON.stringify(formik.touched, null, "\t")}
        </pre>
      </div> */}
      </form>
    </Paper>
  );
}
