import React, { useEffect, useRef, useState } from 'react';
// import ParticipantsList, { EMPTY_PARTICIPANT } from './ParticipantsList';
// import { useFormik } from 'formik';
// import * as EmailValidator from 'email-validator';
import { TNewCluster, TParticipant, TUser } from '../../../types/app.types';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import format from 'date-fns/format';
import UserAutoComplete from '../../users/UserAutoComplete';
import { addHours, differenceInHours } from 'date-fns';
import { subHours } from 'date-fns';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import { AUTO_DESTROY_ADD_HOURS, AUTO_START_HOURS_BEFORE_CLASS, END_DATE_HELPER_TEXT_ON_AUTO_TERMINATION, END_DATE_HELPER_TEXT_ON_MANUAL_TERMINATION } from '../../../constants/app.constants';
import { useSelector } from 'react-redux';
import { groupedTasksListOptionSelector } from '../../../store/selectors';

/* istanbul ignore next */
const getStartDateHelperText = (launchOnStart: boolean, formik: any) => {
  const shouldLaunchImmediately =
    differenceInHours(new Date(formik.values.startDate), new Date()) < AUTO_START_HOURS_BEFORE_CLASS;
  const errorText: string = (formik.touched.startDate && formik.errors.startDate) || "";

  const launchTimestamp = formik.values.launchTimestamp !== ""
    ? format(new Date(formik.values.launchTimestamp), "MM/dd/yyyy hh:mm aa")
    : "";

  return !launchOnStart
  ?
    `Launch at ${AUTO_START_HOURS_BEFORE_CLASS} hours prior to start date.`+
    (shouldLaunchImmediately ? 'now' : launchTimestamp) +
    errorText
  : errorText;
}

/* istanbul ignore next */
const getEndDateHelperText = (scheduleTermination: boolean, formik: any) => {
  const { touched, errors } = formik;
  return touched.endDate
  ? (errors.endDate ? errors.endDate : scheduleTermination
        ? `${END_DATE_HELPER_TEXT_ON_AUTO_TERMINATION} (${format(addHours(new Date(formik.values.endDate), AUTO_DESTROY_ADD_HOURS), "MM/dd/yyyy hh:mm aa")})`
        : END_DATE_HELPER_TEXT_ON_MANUAL_TERMINATION
    )
  : scheduleTermination ? END_DATE_HELPER_TEXT_ON_AUTO_TERMINATION : END_DATE_HELPER_TEXT_ON_MANUAL_TERMINATION
}

/**
 * Regex expression for last character of a string
 * which is not \w(letter, digits), and allowed special characters.
 */
const INVALID_CHARACTERS_REGEX = /[^\w_. :/=+\\-]+$/g;

export type ClassInfoStepFormProp = {
  formik: any,
  launchOnStart: boolean;
  setLaunchOnStart: React.Dispatch<React.SetStateAction<boolean>>;
  scheduleTermination: boolean;
  setScheduleTermination: React.Dispatch<React.SetStateAction<boolean>>;
  createShared: boolean;
  setCreateSharedChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * This form is Step 1 of the creation.
 * Required Fields
 * - startDate
 */
const ClassInfoStepForm: React.FC<ClassInfoStepFormProp> = ({
  formik,
  launchOnStart,
  setLaunchOnStart,
  scheduleTermination,
  setScheduleTermination,
  createShared,
  setCreateSharedChecked
}) => {
  const [error, setError] = useState<Record<string, string|undefined>>(formik.error);
  const classNameRef = useRef<HTMLTextAreaElement>(null);
  const taskDefinitions = useSelector(groupedTasksListOptionSelector);
  const [showClassNameInputHint, setClassNameInputHint] = useState(false);
  // by default when autocomplete doesn't have any value, it should be disabled
  const [disableShared, setDisableShared] = useState(false);

  const getClassNameHelperText = () => {
    return formik.touched.className && formik.errors.className
      ? formik.errors.className
      : `Invalid character can't be used in class name. Accepted characters alphanumeric and ()_.:/=+-`;
  }

  const handleSharedCheckChange = (event: any) => {
    setCreateSharedChecked(event.target.checked);
    const value = formik.values.taskDefinition;
    const newValue = value !== "" ? value : null;
    formik.setFieldValue('taskDefinition', newValue);
  };

  const handleSharedDisabled = (value: boolean) => {
    setDisableShared(value);
    if (value) {
      setCreateSharedChecked(false);
    }
  }

  useEffect(() => {
    // focus on first form control
    if (classNameRef.current) classNameRef.current.focus();
    return () => {

    }
  }, []);

  // useEffect(() => {
  //   console.log(disableShared);
  //   if (disableShared) {
  //     setCreateSharedChecked(false);
  //   }
  //   return () => {

  //   }
  // }, [disableShared])

  useEffect(() => {
    setError(formik.error)
    return () => {

    }
  }, [formik.error]);

  return (
    <div id="class-info-step-form">
      <div className="row">
        {/* <div className="col-12 mb-3">
          <Typography variant="h5" component="h2">Class Information</Typography>
        </div> */}
        <div className="col-12 mb-3">
          <TextField data-testid="class-form-field-className" multiline autoFocus rows={3}
            error={!!(formik.touched.className && formik.errors.className)}
            id="className"
            label="Name"
            type="text"
            fullWidth required
            value={formik.values.className}
            onBlur={formik.handleBlur}
            onChange={(e: any) => {
              const isInvalid = INVALID_CHARACTERS_REGEX.test(e.target.value);
              setClassNameInputHint(e.target.value !== "" && isInvalid);
              const LINE_TABS_REGEX = /[\r\n\t]+/g;
              if (isInvalid) {
                // replace invalid last character from input
                e.target.value = e.target.value.replace(INVALID_CHARACTERS_REGEX, '');
              }
              e.target.value = e.target.value.replace(LINE_TABS_REGEX, '');
              formik.handleChange(e);
            }}
            helperText={getClassNameHelperText()}
            InputProps={{ style: {fontSize: 20} }}
            InputLabelProps={{ shrink: true, style: { fontWeight: 500 } }} />
        </div>
        <div className="col-12 col-md-4 mb-3">
          <TextField
            data-testid="class-form-field-startDate" 
            error={!!(formik.touched.startDate && formik.errors.startDate)}
            id="startDate"
            label="Start date"
            type="datetime-local"
            fullWidth required 
            // defaultValue={format(new Date(), "yyyy-MM-dd'T'HH:mm")}
            value={formik.values.startDate}
            onBlur={formik.handleBlur}
            onChange={(e: any) => {
              formik.handleChange(e);
              if (e.target.value && e.target.value !== "") {
                const diff = differenceInHours(new Date(e.target.value), new Date());
                // console.log('diff', diff);
                setLaunchOnStart(diff < AUTO_START_HOURS_BEFORE_CLASS);
                const launchTimestamp = diff < AUTO_START_HOURS_BEFORE_CLASS
                  ? format(new Date(e.target.value), "yyyy-MM-dd'T'HH:mm")
                  : subHours(new Date(e.target.value), AUTO_START_HOURS_BEFORE_CLASS);
                // if (!formik.values.launchTimestamp || formik.values.launchTimestamp === "") {
                  formik.setFieldValue('launchTimestamp', format(new Date(launchTimestamp), "yyyy-MM-dd'T'HH:mm"));
                // }
                // formik.values.launchTimestamp = e.value;
              }
              
            }}
            inputProps={{
              min: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
            }}
            InputLabelProps={{
              shrink: true, style: { fontWeight: 500 }
            }}
            helperText={getStartDateHelperText(launchOnStart, formik)}
            // InputProps={{
            //   // startAdornment: (<span style={{minWidth: 110}}>Class starts at </span>)
            // }}
          />
          <div className="px-2 mb-3">
            <FormControlLabel
              control={
                <Checkbox checked={launchOnStart} onClick={() => {setLaunchOnStart(!launchOnStart)}} />
              }
              label={
                <Typography variant="caption">
                  Launch immediately.
                </Typography>
              }
            />
          </div>
        </div>
        {/* <div className="col-12 col-md-4 mb-3">
          <TextField
            error={!!(formik.touched.startDate && formik.errors.startDate)}
            id="launchTimestamp"
            label="Launch At"
            type="datetime-local"
            fullWidth required 
            // defaultValue={format(new Date(), "yyyy-MM-dd'T'HH:mm")}
            value={formik.values.launchTimestamp}
            onBlur={formik.handleBlur}
            onChange={(e: any) => {
              // formik.handleChange(e);
              formik.setFieldValue('launchTimestamp', format(new Date(e.target.value), "yyyy-MM-dd'T'HH:mm"));
              // formik.setFieldValue('launchTimestamp', e.target.value);
              // formik.values.launchTimestamp = e.value;
            }}
            inputProps={{
              min: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
              max: format(formik.values.startDate ? new Date(formik.values.startDate) : new Date(), "yyyy-MM-dd'T'HH:mm"),
            }}
            InputLabelProps={{
              shrink: true, style: { fontWeight: 500 }
            }}
            helperText={formik.touched.startDate && formik.errors.startDate}
            // InputProps={{
            //   // startAdornment: (<span style={{minWidth: 110}}>Class starts at </span>)
            // }}
          />
        </div> */}
        <div className="col-12 col-md-4 mb-3">
          <TextField
            data-testid="class-form-field-endDate" 
            error={!!(formik.touched.endDate && formik.errors.endDate)}
            id="endDate"
            label="End date"
            type="datetime-local"
            fullWidth required
            value={formik.values.endDate}
            helperText={
              getEndDateHelperText(scheduleTermination, formik)
            }
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            inputProps={{
              min: formik.values.startDate,
            }}
            InputLabelProps={{
              shrink: true, style: { fontWeight: 500 }
            }}
          />
          <div className="px-2 mb-3">
            <FormControlLabel
              control={
                <Checkbox checked={scheduleTermination} onClick={() => {setScheduleTermination(!scheduleTermination)}} />
              }
              label={
                <Typography variant="caption">
                  {scheduleTermination
                    ? "Terminate automatically"
                    : "Terminate manually"
                  }
                </Typography>
              }
            />
          </div>
        </div>
        
        <div className="col-12 col-md-4 mb-3">
          <Autocomplete
            data-testid="class-form-field-taskDefinition"
            id="taskDefinition"
            openOnFocus
            options={taskDefinitions.all}
            getOptionLabel={(option: string) => option}
            value={
              formik.values.taskDefinition !== ""
              // ? (!disableShared || createShared) || new Set(taskDefinitions.shared).has(formik.values.taskDefinition)
              //   ? taskDefinitions.sharedMap[formik.values.taskDefinition]
              //   : formik.values.taskDefinition
              ? formik.values.taskDefinition
              : null}
            onBlur={formik.handleBlur}
            onChange={(event, newValue) => {
              // const shouldDisable = !(newValue && newValue !== "") ||
              //   new Set(taskDefinitions.sharedDisabled).has(newValue);
              // handleSharedDisabled(shouldDisable);
              newValue = newValue && newValue !== ""
              ? newValue
              : null;
              formik.setFieldValue('taskDefinition', newValue);
            }}
            renderOption={(option: any, state: object) =>
              <Typography noWrap>{option}</Typography>
            }
            renderInput={(params: any) =>
              <TextField required {...params}
              label="Task Definitions"
              error={!!(formik.touched.taskDefinition && formik.errors.taskDefinition)}
              helperText={formik.touched.taskDefinition && formik.errors.taskDefinition}
              
              InputLabelProps={{
                shrink: true, style: { fontWeight: 500 }
              }} />
            }
          />
          <div className="px-2 mb-3">
            <FormControlLabel
              control={
                <Checkbox
                  disabled={disableShared}
                  checked={createShared}
                  onChange={handleSharedCheckChange}
                  name="createShared"
                  color="secondary"
                />
              }
              label={
                <Typography variant="caption">
                  Create shared instance
                </Typography>
              }
            />
          </div>
        </div>
        {/* <div className="col-12 mt-4 mb-4 d-flex justify-content-center">
          <Divider />
        </div> */}
      </div>
      
      <div className="row">
        {/* <div className="col-12 mb-3">
          <Typography variant="h5" component="h2">Select Trainers</Typography>
        </div> */}
        <div className="col-12 col-md-4 mb-3">
          <UserAutoComplete id="class-form-field-trainer" label="Trainer" required
            value={formik.values.trainer}
            error={!!(formik.touched.trainer && formik.errors.trainer)}
            helperText={formik.touched.trainer && formik.errors.trainer}
            onBlur={formik.handleBlur}
            getOptionValue={(val: TUser) => val.username}
            onChange={(e: any, v: TUser) => {
              formik.setFieldValue('trainer', v ? v.username : "")
              formik.setFieldValue('initiatingUser', v ? v.username : "")
            }} />
        </div>
        <div className="col-12 col-md-4 mb-3">
          <UserAutoComplete id="class-form-field-coTrainer" label="Co Trainer"
            value={formik.values.coTrainer}
            getOptionValue={(val: TUser) => val.username}
            onChange={(e: any, v: TUser) => formik.setFieldValue('coTrainer', v ? v.username : "")} />
        </div>
        <div className="col-12 col-md-4 mb-3">
          <UserAutoComplete id="class-form-field-support" label="Support"
            value={formik.values.support}
            getOptionValue={(val: TUser) => val.username}
            onChange={(e: any, v: TUser) => formik.setFieldValue('support', v ? v.username : "")} />
        </div>
      </div>
    </div>
  )
}


export default ClassInfoStepForm;
