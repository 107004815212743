// listTaskDefinitions
import API from "@aws-amplify/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API_NAME } from "../api";
import { sort } from "../utils/sort";

export const getTaskImages = createAsyncThunk<any, void, any>(
  'images/getTaskImages',
  async(arg, thunkAPI) => {
    const path = `/Prod/step-function?listImages=true`;
    let res: any[];
    try {
      res = await API.get(API_NAME, path, null);
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'getTaskImages',
        message: `Failed to getTaskImages`
      })
    }
    return res;
  }
)


export type TaskImage = {
  name: string;
  version: string;
  image: string;
};

export type TaskImageState = {
  data: TaskImage[];
  byImage: Record<string, TaskImage>;
  loading: boolean;
}

export const taskImageInitialState: TaskImageState = {
  loading: false,
  data: [],
  byImage: {}
}

export const taskImageSlice = createSlice({
  name: 'images',
  initialState: taskImageInitialState,
  reducers: {},
  extraReducers: builder => { builder
    .addCase(getTaskImages.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getTaskImages.fulfilled, (state, action) => {
      // sort it by name;
      const data = action.payload
        .filter((image: string) => image.includes("arielpartners-tim/"))
        .map((image: string) => {
          const [repoArn, imageDetail] = image.split("arielpartners-tim/");
          const [,,, region, acc] = repoArn.split(":");
          const value = `${acc}.dkr.ecr.${region}.amazonaws.com/arielpartners-tim/${imageDetail}:latest`
          const [name, version] = imageDetail.split("/");
          return {
            name,
            version,
            image: value,
          }
        })

      state.data = sort(data)
        .by((item: any) => item.name).asc();

      state.byImage = data.reduce((obj: Record<string, TaskImage>, item: TaskImage) => {
        obj[item.image] = item;
        return obj;
      }, {} as Record<string, TaskImage>)
    })
  }
})

export default taskImageSlice.reducer;
