import { createSlice } from "@reduxjs/toolkit";
import { TUser } from "../../types/app.types";
import { sort } from "../../utils/sort";
import {
  loadUsers,
  addUser,
  updateUser,
  removeUser,
  resetPassword
} from "./users.actions";

export type UserListState = {
  data: TUser[]; // is this right? probably have to change
  normalized: {
    usernames: string[];
    byUsername: Record<string, TUser>;
  },
  loading: boolean;
  error: string|null;
}

export const userListInitialState: UserListState = {
  data: [],
  normalized: {
    usernames: [],
    byUsername: {},
  },
  loading: false,
  error: null,
}

const userListSlice = createSlice({
  name: 'users',
  initialState: userListInitialState,
  reducers: {},
  extraReducers: builder => { builder

    .addCase(loadUsers.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(loadUsers.fulfilled, (state, action) => {
      state.loading = false;
      const sortedData = sort<TUser>([...action.payload])
        .by((item: TUser) => ((item.firstName && item.firstName?.toLowerCase()) || ""))
        .asc();
      state.data = sortedData;
      state.normalized = sortedData.reduce((o, d) => {
        o.byUsername[d.username] = d;
        o.usernames = [...o.usernames, d.username];
        return o;
      }, {usernames: [], byUsername: {}} as any);
    })

    .addCase(addUser.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(addUser.fulfilled, (state, action) => {
      state.loading = false;
      state.data = [...state.data, action.payload];
    })

    .addCase(updateUser.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(updateUser.fulfilled, (state, action) => {
      state.loading = false;
      state.data = state.data.map(user =>
        user.username === action.payload.username ? action.payload : user
      );
    })

    .addCase(removeUser.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(removeUser.fulfilled, (state, action) => {
      state.loading = false;
      state.data = state.data.filter(user => user.username !== action.payload.username)
    })

    .addCase(resetPassword.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(resetPassword.fulfilled, (state, action) => {
      state.loading = false;
    });
  }
})

export default userListSlice.reducer;
