import React from "react";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { DialogCloseReason, DialogCloseEvent } from "../../types/common.types";
import { TTaskDefinition, TUser } from "../../types/app.types";
import { useSelector } from "react-redux";
import { RootState } from "../../store/app.store";


const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

export type DeleteUserDialogProp = {
  data: TTaskDefinition;
  open: boolean;
  onClose: (e: DialogCloseEvent) => void;
  onConfirm: (payload: TTaskDefinition) => void;
}

/**
 * A user shouldn't get accidentally removed
 * default focus element should be cancel button
 */
const DeleteTaskDefinitionDialog = (props: DeleteUserDialogProp) => {
  const { onClose, onConfirm, open, data } = props;
  // class count of current task definition
  const taskInUse = useSelector((state: RootState) =>
    state.clusters.data.filter(cluster => cluster.taskDefinition === data.name).length
  );
  const handleClose = (e: any, reason: DialogCloseReason) => {
    onClose({reason});
  };
  const handleConfirm = () => {
    onConfirm(data);
    onClose({reason: 'confirmClick'});
  }
  return (
    <Dialog onClose={handleClose} data-testid="delete-task-definition-dialog" aria-labelledby="delete-confirmation-dialog" open={open}>
      <DialogTitle id="delete-task-definition-dialog-title"
        data-testid="delete-task-definition-dialog-title">
        You're about to delete this task definition
      </DialogTitle>

      <DialogContent>
        <DialogContentText data-testid="delete-task-definition-dialog-description" className="mb-2">
          {taskInUse > 0
          ? `${data.name} is being used in ${taskInUse} class${taskInUse > 1 ? "es" : ""}.`
          : `${data.name} is not being used in any class.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ColorButton data-testid="delete-task-definition-dialog-confirm-button" onClick={handleConfirm}>
          Delete
        </ColorButton>
        <Button data-testid="delete-task-definition-dialog-cancel-button" onClick={() => onClose({reason: 'cancelClick'})} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteTaskDefinitionDialog;
