import React, { useCallback } from 'react';
import Chip from '@material-ui/core/Chip';
import { useSelector } from 'react-redux';
import { useAppDispatch, RootState } from '../../../store/app.store';
import { ClusterTaskDefinitionType, setQuickFilter } from '../clusters.slice';
import Menu, {MenuProps} from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core';
const StyledMenu = withStyles({
  paper: { border: 'unset', width: '100px'},
})((props: MenuProps) => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

// import { Check } from 'react-feather';
const TASK_DEFINITIONS_TYPE_OPTIONS: ClusterTaskDefinitionType[] = [
  'all', 'regular', 'shared'
];
const ClusterQuickFilter = () => {
  const dispatch = useAppDispatch();
  const {onlyMine, inactive, taskDefinitionType, launched} = useSelector((state: RootState) => state.clusters.quickFilters);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [value, setValue] = React.useState<string|null>(data[type]?.username || null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleMenuTriggerClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (option: ClusterTaskDefinitionType) => {
    dispatch(setQuickFilter({onlyMine, launched, inactive, taskDefinitionType: option}))
  };

  return (
    <div data-testid="cluster-quick-filter" className="d-inline-block mb-3">
      <Chip data-testid="cluster-quick-filter-only-my-class" label="Only my classes"
        className="mr-2"
        color={onlyMine ? "primary" : "default"}
        onClick={() => dispatch(setQuickFilter({inactive, launched, onlyMine: !onlyMine, taskDefinitionType}))}
        onDelete={onlyMine ? () => dispatch(setQuickFilter({inactive, launched, onlyMine: !onlyMine, taskDefinitionType})) : undefined} />
      <Chip data-testid="cluster-quick-filter-inactive" label="Inactive"
        className="mr-2"
        color={inactive ? "primary" : "default"}
        onClick={() => dispatch(setQuickFilter({onlyMine, launched, inactive: !inactive, taskDefinitionType}))}
        onDelete={inactive ? () => dispatch(setQuickFilter({onlyMine, launched, inactive: !inactive, taskDefinitionType})) : undefined} />
      <Chip data-testid="cluster-quick-filter-launched" label={launched ? "Launched" : "Not launched"}
        className="mr-2"
        color={launched ? "primary" : "default"}
        onClick={() => dispatch(setQuickFilter({onlyMine, launched: !launched, inactive, taskDefinitionType}))}
        onDelete={launched ? () => dispatch(setQuickFilter({onlyMine, launched: !launched, inactive, taskDefinitionType})) : undefined} />
      <Chip data-testid="cluster-quick-filter-task-definition-type" label={(taskDefinitionType || "all") + " classes"}
        className="mr-2"
        style={{textTransform: 'capitalize'}}
        color="primary"
        onClick={handleMenuTriggerClick}
        onDelete={taskDefinitionType !== 'all' ? () => dispatch(setQuickFilter({onlyMine, launched, inactive, taskDefinitionType: 'all'})) : undefined} />

      <StyledMenu
        data-testid="cluster-quick-filter-task-definition"
        id="class-type-selector"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {TASK_DEFINITIONS_TYPE_OPTIONS.map(option =>
          <MenuItem onClick={() => handleSelect(option)} key={option}
          selected={taskDefinitionType === option}
          style={{textTransform: 'capitalize'}}>
          {option}
          </MenuItem>)}
      </StyledMenu>
    </div>
  );
}
export default ClusterQuickFilter;
