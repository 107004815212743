import React, { useEffect, useRef, useState } from "react";
import { Minus, Plus } from 'react-feather';
import { TParticipant, TParticipantAttendanceRow, TTask } from "../../../types/app.types";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { TextField, makeStyles } from "@material-ui/core";
import { AttendanceStatus, AttendanceStatusType } from "../../../types/status.types";
import Checkbox from '@material-ui/core/Checkbox';
import { Dispatch } from "@reduxjs/toolkit";
import { updateClusterTask } from "../clusters.actions";
import { updateAllParticipantsAttendance } from '../clusters.slice';
import { useAppDispatch } from "../../../store/app.store";
import {stableSort,getComparator} from '../../../utils/sort';
import { useFormik } from 'formik';

export const EMPTY_PARTICIPANT: TParticipant = { firstName: "", lastName: "", email: "", title: "" };
const labelStyle = {
  fontSize: '12px',
  fontWeight: 600,
};

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRow: {
    height: 'fit-content',
    // '&:first-child': {
    //   paddingLeft: 10,
    // }
  },
  tableCell: {
    verticalAlign: 'middle !important',
    '&:first-child': {
      paddingLeft: 15,
    }
  },
  headerCell: {
    fontSize: 12,
    textTransform: 'uppercase',
    '&:first-child': {
      paddingLeft: 15,
    }
  }
}));

type Column = {
  field: string;
  label: string;
  style?: {
    width?: number|string;
    minWidth?: number;
    maxWidth?: number;
    [key: string]: any;
  };
}
const columns: Column[] = [
  { field: 'title', label: 'Title', style: {...labelStyle} },
  { field: 'firstName', label: 'First name', style: {...labelStyle} },
  { field: 'lastName', label: 'Last name', style: {...labelStyle} },
  { field: 'email', label: 'Email', style: { ...labelStyle, width: '40%' } },
];


export type ParticipantAttendanceListProp = {
  // days for attendance
  days: number;
  formik: any;
}

/** This table focus only on handling participants attendance */
export const ParticipantAttendanceList: React.FC<ParticipantAttendanceListProp> = ({formik, days}) => {
  const [order, setOrder] = useState<'asc'|'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof TParticipantAttendanceRow>('email');
  const tbodyRef = useRef<HTMLTableSectionElement>(null);
  const classes = useStyles();

  const dispatch: Dispatch<any> = useAppDispatch();

  const [selected, setSelected] = useState<Record<number, Set<number>>|null>(
    days ? formik.values.participants.reduce((o: any, d: TParticipantAttendanceRow) => {
      // o[]
      new Array(days||0).fill(null).forEach((v, attendanceIndex) => {
        if (!o[attendanceIndex]) o[attendanceIndex] = new Set();
        if (d.attendances && d.attendances[attendanceIndex]) o[attendanceIndex].add(d.index);
      })
      return o;
    }, {}) : {}
  );

  // console.log(selected)

  const handleRequestSort = (event: any, property: keyof TParticipantAttendanceRow) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof TParticipantAttendanceRow) => (event: any) => {
    if (handleRequestSort) handleRequestSort(event, property);
  };

  const attendanceColumns: (numberOfDays: number) => Column[] = (numberOfDays) =>
    numberOfDays ? new Array(numberOfDays).fill(null).map((d,i)=> ({
      field: `day${i+1}`, label: `Day ${i+1}`, style: {...labelStyle}
    })) : [];

  const tableColumns = [...columns, ...attendanceColumns(days)];
  const handleAttendanceClick = (participantIndex: number, attendanceIndex: number) => {
    // console.log("handleAttendanceClick")
    const participant = formik.values.participants[participantIndex];
    const prevValue = participant.attendances[attendanceIndex];

    if (selected && selected[attendanceIndex]) {
      if (prevValue) {
        setSelected(set => {
          if (set && set[attendanceIndex])
            set[attendanceIndex].delete(participantIndex);
          return set;
        });
      } else {
        setSelected(set => {
          if (set && set[attendanceIndex])
            set[attendanceIndex].add(participantIndex);
          return set;
        });
      }
    }
    formik.setFieldValue(`participants[${participantIndex}].attendances[${attendanceIndex}]`, !prevValue);
    const task = formik.values.tasks.find((task: TTask) => task.taskArn === participant.taskArn)
    dispatch(updateClusterTask({
      messages: {
        onSucceed: `Attendance for ${participant.firstName} ${participant.lastName} for Day ${attendanceIndex+1} has been saved`,
        onFailed: `Failed to save attendance for ${participant.firstName} ${participant.lastName} for Day ${attendanceIndex+1}`,
      },
      clusterName: formik.values.clusterName,
      className: formik.values.className,
      task: {
        ...task,
        participants: (formik.values.participants as TParticipantAttendanceRow[])
          .filter((item: TParticipantAttendanceRow) => item.taskArn === task.taskArn)
          .map(({email, firstName, lastName, title, attendances}) => ({
            email, firstName, lastName, title,
            attendances: [...(attendances||[] as boolean[])].map<boolean>((v, i) => i === attendanceIndex ? !v : v)
          }))
      }
    }))
    // return formik.values.participants[participantIndex].attendances[attendanceIndex] = !formik.values[participantIndex].attendances[attendanceIndex];
  }

  const handleUpdateAllParticipantsAttendance = (attendanceIndex: number, value: boolean) => {
    // console.log(updateAllParticipantAttendance)
    const participants = formik.values.participants
      .map((participant: TParticipantAttendanceRow) => ({
        ...participant,
        attendances: (participant.attendances || [])
          .map((attendance: boolean, i: number) =>
            i === attendanceIndex ? value : attendance
          )
      }));

    
    setSelected(set => {
      if (set && set[attendanceIndex])
        set[attendanceIndex] = value ? new Set(participants.map((d: TParticipantAttendanceRow) => d.index)) : new Set()
      return set;
    });
    formik.setFieldValue('participants', participants);
    dispatch(updateAllParticipantsAttendance({
      clusterName: formik.values.clusterName,
      participants,
      attendanceIndex,
      value
    }))

    // const participantsToTaskArnMap = participants.reduce((o: any, d: TParticipantAttendanceRow) => {
    //   if (!o[d.taskArn as string]) o[d.taskArn as string] = [];
    //   o[d.taskArn as string].push({
    //     email: d.email,
    //     firstName: d.firstName,
    //     lastName: d.lastName,
    //     title: d.title,
    //     attendances: [...(d.attendances||[] as boolean[])].map<boolean>((v, i) => i === attendanceIndex ? value : v)
    //   });
    //   return o;
    // }, {});

    // formik.values.tasks.forEach((task: TTask, i: number) => {
    //   // TODO: change following to support multiple participants
    //   // TODO: fix glitch
    //   const participant = participantsToTaskArnMap[task.taskArn] && participantsToTaskArnMap[task.taskArn][0];
    //   const isLastTask = i === formik.values.tasks.length - 1;
    //   console.log(isLastTask)
    //   dispatch(updateClusterTask({
    //     messages: isLastTask && participant ? {
    //       onSucceed: `Attendance for all ${participants.length} participants for Day ${attendanceIndex+1} has been saved`,
    //       onFailed: `Failed to save attendance for some participant for Day ${attendanceIndex+1}`,
    //     } : null,
    //     silent: !isLastTask,
    //     clusterName: formik.values.clusterName,
    //     className: formik.values.className,
    //     task: {
    //       ...task,
    //       participants: participantsToTaskArnMap[task.taskArn] || task.participants
    //     }
    //   }));
    //   // dispatch(pushNotification({
    //   //   status: StatusVariant.Success,
    //   //   message: payload.message
    //   // }))
    // });
  }

  const handleAttendanceHeaderCheck = (attendanceIndex: number) => {
    const shouldSelectAll = ((selected && selected[attendanceIndex] && selected[attendanceIndex].size) || 0) !== formik.values.participants.length;
    handleUpdateAllParticipantsAttendance(attendanceIndex, shouldSelectAll);
  };

  // const 
  return (
    <Table data-testid="participant-attendance-list" className="table" size="small" >
      <TableHead>
        <TableRow>
          {columns.map((column,i) => (
          <TableCell
            data-testid={`participant-attendance-list-header-cell-${i}`}
            key={column.field}
            align={'left'}
            className={'p-1 '+ classes.headerCell}
            sortDirection={orderBy === column.field ? order : false}
          >
            <TableSortLabel
              data-testid={`participant-attendance-list-header-sort-${i}`}
              active={orderBy === column.field}
              direction={orderBy === column.field ? order : 'asc'}
              onClick={createSortHandler(column.field as keyof TParticipantAttendanceRow)}
            >
              <span className="text-uppercase">{column.label}</span>
              {orderBy === column.field ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
          ))}
          {attendanceColumns(days).map((column, attendanceIndex) => 
          <TableCell
            data-testid={`participant-attendance-list-attendance-header-cell-${attendanceIndex}`}
            padding="checkbox"
            key={column.field}
            align={'left'}
            className={'p-1 justify-content-center '+ classes.headerCell}
          >
            <span className="d-block text-uppercase text-center">{column.label}</span>
            <Checkbox
              data-testid={`day-${attendanceIndex}-attendance-checkbox-header`}
              color={((selected && selected[attendanceIndex] && selected[attendanceIndex].size) || 0) === formik.values.participants.length ? "primary" : "default"}
              indeterminate={((selected && selected[attendanceIndex] && selected[attendanceIndex].size) || 0) !== formik.values.participants.length && ((selected && selected[attendanceIndex] && selected[attendanceIndex].size) || 0) > 0}
              checked={((selected && selected[attendanceIndex] && selected[attendanceIndex].size) || 0) > 0}
              onClick={() => handleAttendanceHeaderCheck(attendanceIndex)}
              inputProps={{ 'aria-labelledby': column.field }}
            />
          </TableCell>
          )}
          {/** Last column is for action button */}
          {/* <TableCell scope="col" style={{width: '24px'}}>&nbsp;</TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody data-testid="participant-attendance-list-body" ref={tbodyRef}>
        {formik.values.participants && formik.values.participants.length
        ? stableSort<TParticipantAttendanceRow>(formik.values.participants, getComparator(order, orderBy))
        .map((participant: TParticipantAttendanceRow, participantIndex: number) =>
        <TableRow data-testid={`participant-attendance-list-row-${participantIndex}`} key={participantIndex}>
          <TableCell className="p-1">
            <div className="w-100">{participant.title}</div>
          </TableCell>
          <TableCell className="p-1">
            <div className="w-100">{participant.firstName}</div>
          </TableCell>
          <TableCell className="p-1">
            <div className="w-100">{participant.lastName}</div>
          </TableCell>
          <TableCell className="p-1" id={participant.email}>
            <div className="w-100">{participant.email}</div>
          </TableCell>
          {participant.attendances && participant.attendances.length ?
          attendanceColumns(days).map((column, attendanceIndex) =>
            <TableCell className="p-1" key={attendanceIndex} padding="checkbox">
              <Checkbox
                data-testid={`day-${attendanceIndex}-attendance-checkbox-${participantIndex}`}
                id={`day-${attendanceIndex}-attendance-checkbox-${participantIndex}`}
                color="primary"
                data-checked={participant.attendances ? participant.attendances[attendanceIndex] : false}
                checked={participant.attendances ? participant.attendances[attendanceIndex] : false}
                onClick={() => handleAttendanceClick(participant.index||0, attendanceIndex)}
                inputProps={{ 'aria-labelledby': participant.email }}
              />
              {/* <div className="w-100">{(participant.attendances ? participant.attendances[attendanceIndex] : false)+""}</div> */}
            </TableCell>
          ): <></>}
        </TableRow>
        ) : (
        <TableRow>
          <TableCell colSpan={columns.length + days}>
            <div className="d-flex justify-content-center align-items-center" style={{minHeight: '100px'}}>
              There are no participants assigned to this class
            </div>
          </TableCell>
        </TableRow>)}
      </TableBody>
    </Table>
  )
}


// handleAttendanceClick
export default ParticipantAttendanceList;
