import API from "@aws-amplify/api";
import { TAuditTrailEntry, TAuditTrailData, TNewUser, TUser, TUserWithPassword } from "./types/app.types";
// import { TAuditTrailEntry } from "./store/audit.slice";
/**
 * This file lists all api service functions, for testing and mocking purpose
 */
export const API_NAME = "step-function-rest";

export type GetAuditLogBetweenArg = {start: string, end: string};
export function getAuditLogBetween(arg: GetAuditLogBetweenArg) {
  const {start, end} = arg;
  const path = `/Prod/step-function?getAuditLogBetween=true&start=${start}&end=${end}`;
  return API.get(API_NAME, path, null)
    .then((resp: TAuditTrailEntry[]) => (resp || []).map(d => ({
      date: d.date,
      time: d.time,
      action: d.Action,
      clusterName: d.ClusterName,
      source: d.Source,
      prevState: d.PrevState,
      username: d.Username,
      class: d.Class
    } as TAuditTrailData)))
    .catch((e) => ([])) // handles when http error happens
}
export type GetAuditLogArg = {day: string, operator: string};
// this API is not ideal way to fetch audit with timezone difference
export function getAuditLog(arg: GetAuditLogArg) {
  const {day, operator} = arg;
  const path = `/Prod/step-function?getAuditLog=true&day=${day}&time=0&operator=${operator}`;
  return API.get(API_NAME, path, null)
    .then((resp: TAuditTrailEntry[]) => resp.map(d => ({
      date: d.date,
      time: d.time,
      action: d.Action,
      clusterName: d.ClusterName,
      source: d.Source,
      prevState: d.PrevState,
      username: d.Username,
    } as TAuditTrailData)))
}

export function getVersion() {
  const path = "/Prod/step-function?version=true";
  return API.get(API_NAME, path, null)
    .then((resp: { version: string }) => resp && resp.version || "")
}

export function loadUsers(): Promise<TUser[]>{
  const path = "/Prod/step-function?listUsers=true";
  return API.get(API_NAME, path, null);
}

export function addUser(user: TNewUser): Promise<TUser> {
  const path = "/Prod/step-function?addUser=true";
  return API.post(API_NAME, path, {
    body: {...user, username: user.email},
    headers: {}
  });
}

export function updateUser(user: TUser): Promise<TUser> {
  const path = "/Prod/step-function?updateUser=true";
  return API.put(API_NAME, path, {
    body: {...user},
    headers: {}
  });
}

export function removeUser(user: TUser): Promise<any> {
  const path = "/Prod/step-function?removeUser=true";
  return API.del(API_NAME, path, {
    body: { username: user.username },
    headers: {}
  });
}

export function resetPassword(user: TUserWithPassword): Promise<any> {
  const path = "/Prod/step-function?resetPassword=true";
  const { username, email, password, permanent } = user;
  return API.post(API_NAME, path, {
    body: { username, password, permanent },
    headers: {}
  });
}

export function resendEmail(user: TUser): Promise<any> {
  const path = "/Prod/step-function?resendEmail=true";
  const { username, email } = user;
  return API.post(API_NAME, path, {
    body: { username },
    headers: {}
  });
}

/**
 * export all API methods as a module
 * for easier way to mock functions in testing environment
 */
const API_MODULE = {
  getAuditLogBetween,
  getAuditLog,
  getVersion,
  loadUsers,
  addUser,
  updateUser,
  removeUser,
  resetPassword,
  resendEmail,
}

export default API_MODULE;
