// constants.js
const prod = {
  url: {
      API_URL: "https://1j2e5sofuk.execute-api.us-east-1.amazonaws.com",
  },
  aws: {
      region: "us-east-1",
      userPoolId: "us-east-1_bERw5Kupd",
      userPoolWebClientId: "6c2gqtt07fqiifm0b54v5vrd35",
  },
  websocket: 'wss://bwygftrs66.execute-api.us-east-1.amazonaws.com/Prod'
};
const dev = {
  url: {
      API_URL: "http://localhost:8080",
  },
  aws: {
      region: "us-west-1",
      userPoolId: "us-west-1_hy4vNBfor",
      userPoolWebClientId: "3ed2s1t9j7ge42cai5tr0l1h48",
  },
  websocket: `wss://0u2y3do7yl.execute-api.us-west-1.amazonaws.com/Prod`
};

if(window.location.hostname === "tim-dev.arielpartners.com") {
  dev.url.API_URL = "https://67dtnp6h05.execute-api.us-west-1.amazonaws.com"
}

export const config = process.env.NODE_ENV === "development" || window.location.hostname === "tim-dev.arielpartners.com" || window.location.hostname === "localhost:8080" ? dev : prod;
