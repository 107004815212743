/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { RefreshCcw, Filter } from 'react-feather';
import { useConditions } from '../../hooks/useConditions';
import ClustersFilter, { ClusterFilterConditionValues, TClusterFilterCondition } from '../../components/clusters/list/ClustersFilter';
import CompactClusterList from '../../components/clusters/list/CompactClusterList';
import { RootState } from '../../store/app.store';
import { loadingSelector, statisticsSelector } from '../../store/selectors';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { loadClusters } from '../../components/clusters/clusters.actions';
import { pushNotification } from '../../components/notification/notification.slice';
import { StatusVariant, StatusVariantType } from '../../types/status.types';
import { Collapse, Paper, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import PollOutlinedIcon from "@material-ui/icons/PollOutlined";
import ClusterQuickFilter from '../../components/clusters/list/ClusterQuickFilter';
import ClusterSearch from '../../components/clusters/list/ClustersSearch';
import ClusterSort from '../../components/clusters/list/ClusterSort';
import { loadUsers } from '../../components/users/users.actions';

const StatisticOverview: React.FC<{statistics: any}> = ({statistics}) => {
  const { classesCount, launched, regular, shared, instancesCount, assigned, unassigned, healthy, unhealthy, running, pending, requested } = statistics;
  return (
    <div className="d-flex flex-column flex-md-row align-items-md-center">
      <div className="d-flex flex-column flex-sm-row rounded border border-secondary mr-2 mb-2 mb-lg-0" style={{overflow:'hidden'}}>
        <Typography variant="caption" className=" bg-secondary text-light py-0 px-2">
          classes
        </Typography>
        <Typography variant="caption" className=" bg-light text-dark py-0 px-2">
          total: <span>{classesCount}</span> | launched: <span>{launched}</span>  | regular: <span>{regular}</span> | shared: <span>{shared}</span>
        </Typography>
      </div>

      <div className="d-flex flex-column flex-sm-row  rounded border border-secondary mr-2 mb-2 mb-lg-0" style={{overflow:'hidden'}}>
        <Typography variant="caption" className=" bg-secondary text-light py-0 px-2">
          instances
        </Typography>
        <Typography variant="caption" className=" bg-light text-dark py-0 px-2">
          total: <span>{instancesCount}</span> | assigned: <span>{assigned}</span> | unassigned: <span>{unassigned}</span>
        </Typography>
      </div>

      <div className="d-flex flex-column flex-sm-row  rounded border border-secondary mr-2 mb-2 mb-lg-0" style={{overflow:'hidden'}}>
        <Typography variant="caption" className=" bg-secondary text-light py-0 px-2">
          status
        </Typography>
        <Typography variant="caption" className=" bg-light text-dark py-0 px-2">
          healthy: <span>{healthy}</span> | unhealthy: <span>{unhealthy}</span> | pending: <span>{pending}</span> | requested: <span>{requested}</span>
        </Typography>
      </div>
    </div>
  )
}


const mapStateToProps = (state: RootState, props: any) => ({
	total: state.clusters.data.length,
	loading: loadingSelector(state),
	statistics: statisticsSelector(state)
});

const mapDispatchToProps = (dispatch: any) => ({
	loadClusters:() => dispatch(loadClusters()),
	loadUsers:() => dispatch(loadUsers()),
	pushNotification: (status: StatusVariantType, message: string) =>
		dispatch(pushNotification({ status, message }))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const V2ClassListPage: React.FC<PropsFromRedux> = ({
  statistics, loading, pushNotification, loadClusters, loadUsers
}) => {
  const [showFilter, setFilterOpen] = useState<boolean>(false);
  const toggleFilter = () => {
		setFilterOpen(!showFilter);
	};

  const [showStatistics, setStatisticsOpen] = useState<boolean>(false);
  const toggleStatistics = () => {
		setStatisticsOpen(!showStatistics);
	};

	// For filter list, passed down to <ClustersFilter>
  const [condition, onFieldChange, unsetCondition] = useConditions<TClusterFilterCondition>({
    taskDefinition: null,
    // taskDefinitionType: null,
    className: "",
  });

	const quickFilters = useSelector((state: RootState) => state.clusters.quickFilters);

	const { classesCount, regular, shared, instancesCount, assigned, unassigned, healthy, unhealthy, running, pending } = statistics;

	useEffect(() => {
		loadUsers();
    // loadClusters();
    return () => { }
  }, []);

	return (
    <>
			<div className="container-fluid px-3 pt-3 px-lg-5">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">
					<div className="d-flex">
						<Typography variant="h4" component="h1" className="d-inline-block mr-2">
							List Classes
						</Typography>
            <Hidden mdDown>
              <StatisticOverview statistics={statistics} />
            </Hidden>
					</div>
					<div>
            <Hidden lgUp>
              <Button aria-label="Statistics" size="small"
                color={showStatistics ? "secondary" : "default"}
                onClick={() => toggleStatistics()}>
                <PollOutlinedIcon />&nbsp;&nbsp;
                Statistics
              </Button>
            </Hidden>
						<Button aria-label="Filter Classes" size="small"
              color={showFilter ? "secondary" : "default"}
							onClick={() => toggleFilter()}>
							<Filter size={16} />&nbsp;&nbsp;
							{showFilter ? "Close Filter" : "Filter"}
						</Button>
						<Button aria-label="Refresh Classes"  size="small"
              color={loading ? "secondary" : "default"}
							onClick={() => {
								loadClusters();
								pushNotification(StatusVariant.Success, 'Refreshing the data')
							}}>
							<RefreshCcw className={loading ? 'rotate' : ''} size={16} />&nbsp;&nbsp;
							Refresh
						</Button>
					</div>
				</div>
				<div className="row">
          <div className="col-12">
            
            <Hidden lgUp>
              <Collapse in={showStatistics}>
                <StatisticOverview statistics={statistics} />
              </Collapse>
            </Hidden>
            
            <Collapse in={showFilter}>
              <ClustersFilter
                condition={condition}
                unsetCondition={unsetCondition}
                onFieldChange={onFieldChange} />
            </Collapse>

            
          </div>
					<div className="col-12 col-md-6 col-lg-8">
						<div className="d-flex flex-wrap align-items-center justify-content-start">
							<ClusterQuickFilter />
							<ClusterFilterConditionValues condition={condition} unsetCondition={unsetCondition} />
              <ClusterSort />
						</div>
					</div>
					<div className="col-12 col-md-6 col-lg-4">
						<ClusterSearch />
					</div>
					<div className="col-12">
            <CompactClusterList
                condition={condition}
								quickFilters={quickFilters}
                />
					</div>
				</div>
			</div>
    </>
	)
};

export default connector(V2ClassListPage);
