import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import thunkMiddleware from 'redux-thunk';
import { useDispatch } from 'react-redux'
import clusterListReducer, { ClustersState } from '../components/clusters/clusters.slice';
import notificationReducer, { NotificationState } from '../components/notification/notification.slice';
import { pushNotificationEpic } from './notification.epics';
import { clustersEpic } from '../components/clusters/clusters.epic';
import authenticatedUserReducer, { AuthenticatedUserState } from './authenticatedUser.slice';
import userListReducer, { UserListState } from '../components/users/users.slice';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import auditReducer, { AuditTrailState } from './audit.slice';
import reduxWebsocket from '@giantmachines/redux-websocket';
import { websocketEpic } from './websocket.epics';
// import { auditEpic } from './audit.epic';
import versionReducer, { VersionState } from './version.slice';
import taskListReducer, { TaskListState } from '../components/task-definitions/taskList.slice';
import { Dispatch } from '@reduxjs/toolkit';
import { PersistPartial } from 'redux-persist/lib/persistReducer';
import {Reducer, AnyAction, CombinedState} from 'redux';
import { taskDefinitionEpic } from '../components/task-definitions/taskDefinitions.epic';
import taskImageReducer from './images.slice';
// import { PersistPartial } from 'redux-persist/es/persistReducer';

const persistConfig = {
  root: {
    key: 'root',
    storage,
    blacklist: ['clusters', 'sort', 'audit', 'auth, "tasks', "images"]
  },
  clusters: {
    key: 'clusters',
    storage,
    blacklist: ['pollingQueues', 'sort']
  }
}

export const rootEpic = combineEpics(
  pushNotificationEpic,
  clustersEpic,
  websocketEpic,
  taskDefinitionEpic
  // auditEpic
);

export const rootReducer = combineReducers({
  clusters: persistReducer(persistConfig.clusters, clusterListReducer),
  // clusters: (process.env.CI
  //     ? clusterListReducer as Reducer<ClustersState, AnyAction>
  //     : persistReducer(persistConfig.clusters, clusterListReducer) as Reducer<ClustersState & PersistPartial, AnyAction>),
  notification: notificationReducer,
  auth: authenticatedUserReducer,
  users: userListReducer,
  audit: auditReducer,
  version: versionReducer,
  tasks: taskListReducer,
  images: taskImageReducer,
})


const persistedReducer = persistReducer(persistConfig.root, rootReducer);


const epicMiddleware = createEpicMiddleware();
const reduxWebsocketMiddleware = reduxWebsocket({
  reconnectOnClose: true
  // onOpen: (socket: WebSocket) => {  
  //   // store.dispatch({type: 'socketOpened'})
  //   // store.dispatch(send({ action: 'refreshClasses' }));
  // },
  // // Custom function to serialize your payload before sending. Defaults to JSON.stringify
  // // but you could use this function to send any format you like, including binary
  // serializer: (payload: any) => {},
  // // Custom function to deserialize the message data sent from the server. By default the
  // // message data gets passed through as is.
  // deserializer: (message: any) => any;
});
export const middleware = [
  ...getDefaultMiddleware({
    // https://github.com/rt2zz/redux-persist/issues/988
    serializableCheck: false
  }),
  reduxWebsocketMiddleware,
  thunkMiddleware,
  epicMiddleware
];

const jestStore = configureStore({
  reducer: combineReducers({
    clusters: clusterListReducer,
    notification: notificationReducer,
    auth: authenticatedUserReducer,
    users: userListReducer,
    audit: auditReducer,
    version: versionReducer,
    tasks: taskListReducer,
    images: taskImageReducer,
  }),
  middleware,
  devTools: process.env.NODE_ENV === 'development',
});
const store = process.env.CI ? jestStore : configureStore({
  reducer: persistedReducer,
  middleware,
  devTools: process.env.NODE_ENV === 'development',
});


export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export default store;

export type AppDispatch = Dispatch<any>;
// Export a hook that can be reused to resolve types
export function useAppDispatch () {
  return useDispatch<AppDispatch>();
}

epicMiddleware.run(rootEpic);
