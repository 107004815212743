/* istanbul ignore file */

export enum StatusVariant {
  Success = 'success',
  Errors = 'errors',
  Warnings = 'warnings',
  Info = 'info',
  Help = 'help'
}

export type StatusVariantType =
  StatusVariant.Success|
  StatusVariant.Errors|
  StatusVariant.Warnings|
  StatusVariant.Info|
  StatusVariant.Help;


export enum UserStatus {
  // User has been created but not confirmed.
  UNCONFIRMED = 'UNCONFIRMED',
  // User has been created but not confirmed.
  CONFIRMED = 'CONFIRMED',
  // User is no longer active.
  ARCHIVED = 'ARCHIVED',
  // User is disabled due to a potential security threat.
  COMPROMISED = 'COMPROMISED',
  // User status is not known.
  UNKNOWN = 'UNKNOWN',
  // User is confirmed, but the user must request a code and reset his or her password before he or she can sign in.
  RESET_REQUIRED = 'RESET_REQUIRED',
  // The user is confirmed and the user can sign in using a temporary password, but on first sign-in, the user must change his or her password to a new value before doing anything else.
  FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
}

export type UserStatusType =
  UserStatus.UNCONFIRMED|
  UserStatus.CONFIRMED|
  UserStatus.ARCHIVED|
  UserStatus.COMPROMISED|
  UserStatus.UNKNOWN|
  UserStatus.RESET_REQUIRED|
  UserStatus.FORCE_CHANGE_PASSWORD;


  // https://docs.aws.amazon.com/AmazonECS/latest/developerguide/task_definition_parameters.html

export enum ECSTaskHealthStatus {
  // The container health check has passed successfully.
  HEALTHY = 'HEALTHY',
  // The container health check has failed.
  UNHEALTHY = 'UNHEALTHY',
  // The container health check is being evaluated or there is no container health check defined
  UNKNOWN = 'UNKNOWN'
}

export type ECSTaskHealthStatusType =
  ECSTaskHealthStatus.HEALTHY|
  ECSTaskHealthStatus.UNHEALTHY|
  ECSTaskHealthStatus.UNKNOWN;


export enum ECSTaskLifeCycle {
  // Amazon ECS has to perform additional steps before the task is launched. For example, for tasks that use the awsvpc network mode, the elastic network interface needs to be provisioned.
  PROVISIONING = 'PROVISIONING',
  // This is a transition state where Amazon ECS is waiting on the container agent to take further action.
  PENDING = 'PENDING',
  // Amazon ECS has to perform additional steps after the task is launched but before the task can transition to the RUNNING state. For example, for tasks that have service discovery configured, the service discovery resources must be created. For tasks that are part of a service that is configured to use multiple Elastic Load Balancing target groups, the target group registration occurs during this state.
  ACTIVATING = 'ACTIVATING',
  // The task is successfully running.
  RUNNING = 'RUNNING',
  // Amazon ECS has to perform additional steps before the task is stopped. For example, for tasks that are part of a service that is configured to use multiple Elastic Load Balancing target groups, the target group deregistration occurs during this state.
  DEACTIVATING = 'DEACTIVATING',
  // This is a transition state where Amazon ECS is waiting on the container agent to take further action.
  STOPPING = 'STOPPING',
  // Amazon ECS has to perform additional steps after the task has stopped but before the task transitions to the STOPPED state. For example, for tasks that use the awsvpc network mode, the elastic network interface needs to be detached and deleted.
  DEPROVISIONING = 'DEPROVISIONING',
  // The task has been successfully stopped.
  STOPPED = "STOPPED",
}

export type ECSTaskLifeCycleType =
  ECSTaskLifeCycle.PROVISIONING|
  ECSTaskLifeCycle.PENDING|
  ECSTaskLifeCycle.ACTIVATING|
  ECSTaskLifeCycle.RUNNING|
  ECSTaskLifeCycle.DEACTIVATING|
  ECSTaskLifeCycle.STOPPING|
  ECSTaskLifeCycle.DEPROVISIONING|
  ECSTaskLifeCycle.STOPPED;

export enum AttendanceStatus {
  ATTENDED = 'ATTENDED',
  LATE = 'LATE',
  UNATTENDED = 'UNATTENDED'
};

export type AttendanceStatusType = AttendanceStatus.ATTENDED|AttendanceStatus.UNATTENDED|AttendanceStatus.LATE;
