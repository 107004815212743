import React, { useState } from "react";
import { TCluster, TClusterForm } from "../../../types/app.types";
import InlineEditInput from "../../inline-edit/InlineEditInput";
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { Input, InputAdornment, InputLabel, Typography } from "@material-ui/core";
// import * as EmailValidator from 'email-validator';
// import { RootState } from "../../../store/app.store";
// import { useSelector } from "react-redux";
// import { useFormik } from 'formik';
import format from "date-fns/format";
// import formatDistance from 'date-fns/formatDistance'

const style = {
  sectionTitle: {
    fontSize: '18px'
  },
  smallText: {
    fontSize: '12px'
  },
  
}

const useStyles = makeStyles((theme) => ({
  viewField: {
    cursor: 'edit',
    color: `${theme.palette.grey[900]} !important`,
    '&:before':{
      borderBottomStyle: 'solid !important'
    }
  },
  avatar: {
    color: `${theme.palette.grey[900]} !important`,
    backgroundColor: 'transparent',
    fontSize: '12px !important',
    fontWeight: 600,
    marginLeft: '4px !important'
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  userInputLabel: {
    width: 85,
    fontSize: 13,
    margin: 0
  }
}));

function DateViewer(props: {dataTestid: string, date: number|string|Date; label: string;}) {
  // console.log(props.date)
  return (
    <div data-testid={props.dataTestid} className="d-flex">
      {/* <Typography variant="caption" component="div">
        {props.label}
      </Typography> */}
      { props.date ? <>
        <Typography className="cluster-info-date" variant="caption" component="div">
          {format(new Date(props.date), 'MM/dd/yyyy hh:mm aa')}
        </Typography>
      </> : <>
        <Typography className="cluster-info-date" variant="caption" component="div">
          No date
        </Typography>
        {/* <Typography variant="caption" component="div">
          January
        </Typography>
        <Typography variant="caption" component="div">
          00:00 AM
        </Typography> */}
      </>}
    </div>
  )
}

export type ClusterInfoProp = {
  cluster: TCluster;
  isPrint?: boolean;
  statistics: {
    total:number;
    assigned:number;
    unassigned:number;
    healthy:number;
    unhealthy:number;
    provisioningTasks: number;
    runningTasks: number;
  }
  updateCluster?: (cluster: TClusterForm) => void;
}

/** This is only used for pdf print page so all values are really read only */
const ClusterInfo: React.FC<ClusterInfoProp> = ({ cluster, statistics, isPrint, updateCluster }) => {
  const classes = useStyles();
  const {
    clusterName, className, createdAt,
    trainer, coTrainer, support, startDate, endDate,
    taskDefinition, tasks
  } = cluster;

  // console.log('ClusterInfo', cluster)
  // const {usernames, byUsername} = useSelector((state: RootState) => state.users.normalized);

  // const onClassNameSave = (val: any) => {
  //   if (isPrint || !updateCluster) return;
  //   updateCluster({
  //     ...cluster,
  //     className: val,
  //     trainer: {
  //       username: (cluster.trainer?.username || "")
  //     },
  //     coTrainer: {
  //       username: (cluster.coTrainer?.username || "")
  //     },
  //     support: {
  //       username: (cluster.support?.username || "")
  //     },
  //   });
  // }

  // const handleSelect = (type:'trainer'|'coTrainer'|'support') => (e: any) => {
  //   if (isPrint || !updateCluster) return;
    
  //   // console.log('handleSelect', type, e.target.value, cluster[type])
  //   // formik.handleChange(e);
  //   // if (cluster[type])
  //     updateCluster({...cluster,
  //       trainer: {
  //         username: (cluster.trainer?.username || "")
  //       },
  //       coTrainer: {
  //         username: (cluster.coTrainer?.username || "")
  //       },
  //       support: {
  //         username: (cluster.support?.username || "")
  //       },
  //       [type]: {
  //         username: e.target.value
  //       }
  //     });
  // }
  
  // if (!isPrint) console.log(clusterName, className);
  
  return isPrint ? (
    <>
      <div id="print-header" className="d-flex justify-content-between">
        <span className="w-100 label label-default mb-2 text-left">
        [printed: {new Date(Date.now()).toLocaleString("en-US", {timeZoneName: "short"})}]
        </span>
        <span className="w-100 label label-default mb-2 text-right">
        [created: {createdAt && createdAt !== '' && createdAt !== '0001-01-01T00:00:00Z'
          ? new Date(Date.parse(createdAt)).toLocaleString("en-US", {timeZoneName: "short"})
          : 'unknown'
        }]
        </span>
      </div>
      <div id="class-info-wrapper" className="mt-3 mb-3">
        <div className="d-flex justify-content-between mt-3 mb-3">
          <div className="label label-default mb-3" style={style.sectionTitle}>
            {className}
          </div>

          <span className="p-1 mt-2 mb-2"
            style={style.smallText}>
            Task Definition: {taskDefinition || 'unknown'}
          </span>
        </div>
        <div className="d-flex justify-content-between mt-3 mb-3">
          <div className="p-3" style={{width: '400px'}}>
            <div className="w-100 d-flex justify-content-start">
              <span className="mr-3">Trainer:</span>
              <span>{(trainer && trainer.email !== "" && trainer.email) || "none" }</span>
            </div>
            <div className="w-100 d-flex justify-content-start">
              <span className="mr-3">Co-Trainer:</span>
              <span>{(coTrainer && coTrainer.email !== "" && coTrainer.email) || "none" }</span>
            </div>
            <div className="w-100 d-flex justify-content-start">
              <span className="mr-3">Support:</span>
              <span>{(support && support.email !== "" && support.email) || "none" }</span>
            </div>
          </div>

          <div className="p-3" style={{width: '200px'}}>
            <div className="d-flex justify-content-between">
              <span>Total Instances:</span>
              <span>{statistics.total}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span>Running Instances:</span>
              <span>{statistics.runningTasks}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span>Requested Instances:</span>
              <span>{cluster.requestedTasks}</span>
            </div>

            <div className="d-flex justify-content-between">
              <span>Healthy:</span>
              <span>{statistics.healthy}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span>Unhealthy:</span>
              <span>{statistics.unhealthy}</span>
            </div>
            
            <div className="d-flex justify-content-between">
              <span>Assigned:</span>
              <span>{statistics.assigned}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span>Unassigned:</span>
              <span>{statistics.total - statistics.assigned}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between mb-2">
          <DateViewer dataTestid="cluster-info-start-date" date={startDate} label="Starts" />
          <Typography variant="caption" component="span" className="mx-3">~</Typography>
          <DateViewer dataTestid="cluster-info-end-date" date={endDate} label="Ends" />
        </div>
        <div>
          {/* <Typography variant="h6" component="div">
            for {formatDistance(new Date(startDate), new Date(endDate))}
          </Typography> */}
        </div>
      </div>
      <div data-testid="cluster-info-class-name" className="label label-default mb-3" style={style.sectionTitle}>
        <InlineEditInput
          id="className"
          defaultValue={cluster.className}
          multiline={true}
          rows={3}
          rowsMax={3}
          placeholder="enter class name"
          viewFieldPlaceholder="click to edit class name"
          style={{fontSize: '18px'}}
          viewFieldStyle={{fontSize: '18px'}} />
      </div>
      <div className="d-flex flex-column align-items-center">
        <div className="w-100 mb-3">
          <InputLabel className={classes.userInputLabel}htmlFor="trainer">Trainer</InputLabel>
          <div data-testid="cluster-info-trainer"  id="trainer">
            {trainer?.username || ""}
          </div>
          {/* <NativeSelect data-testid="cluster-info-trainer" id="trainer"
            name="trainer"
            fullWidth
            value={trainer?.username || ""}
            onChange={handleSelect("trainer")}>
            <option aria-label="None" value="">-</option>
            {usernames.map((username, i) =>
            <option key={i} value={username}>
              {byUsername[username].firstName} {byUsername[username].lastName}
            </option>)
            }
          </NativeSelect> */}
        </div>
        <div className="w-100 mb-3">
          <InputLabel className={classes.userInputLabel}htmlFor="coTrainer">Co Trainer</InputLabel>
          <div data-testid="cluster-info-co-trainer" id="coTrainer">
            {coTrainer?.username || ""}
          </div>
          {/* <NativeSelect data-testid="cluster-info-co-trainer" id="coTrainer"
            fullWidth
            value={coTrainer?.username || ""}
            onChange={handleSelect("coTrainer")}>
            <option aria-label="None" value="">-</option>
            {usernames.map((username, i) =>
            <option key={i} value={username}>
              {byUsername[username].firstName} {byUsername[username].lastName}
            </option>)
            }
          </NativeSelect> */}
        </div>
        <div className="w-100 mb-3">
          <InputLabel className={classes.userInputLabel}htmlFor="coTrainer">Support</InputLabel>
          <div data-testid="cluster-info-support" id="support">
            {support?.username || ""}
          </div>
          {/* <NativeSelect data-testid="cluster-info-support" id="support"
            fullWidth
            value={support?.username || ""}
            onChange={handleSelect("support")}>
            <option aria-label="None" value="">-</option>
            {usernames.map((username, i) =>
            <option key={i} value={username}>
              {byUsername[username].firstName} {byUsername[username].lastName}
            </option>)
            }
          </NativeSelect> */}
        </div>
        <div className="w-100 mb-3">
          <Input data-testid="cluster-info-created-at"
            type="text" disabled
            className={classes.viewField}
            fullWidth
            value={createdAt && createdAt !== '' && createdAt !== '0001-01-01T00:00:00Z' ? new Date(Date.parse(createdAt)).toLocaleString("en-US", {timeZoneName: "short"}) : 'unknown'}
            startAdornment={
              <InputAdornment position="start">
                <InputLabel className={classes.userInputLabel}
                  htmlFor="createdAt">
                  Created At
                </InputLabel>
              </InputAdornment>
            }/>
        </div>
        <span data-testid="cluster-info-task-definition" className="badge badge-light p-1 mt-2"
          style={style.smallText}>
          Task Definition: {taskDefinition || 'unknown'}
        </span>

        <div className="mt-3">
          <Chip data-testid="cluster-info-task-total" avatar={<Avatar className={classes.avatar}>{statistics.total}</Avatar>}
            size="small"
            label="Total Instances"
            variant="outlined"
            className={classes.chip} />
          <Chip data-testid="cluster-info-task-assigned" avatar={<Avatar className={classes.avatar}>{statistics.assigned}</Avatar>}
            size="small"
            label="Assigned"
            variant="outlined"
            className={classes.chip}
          />
          <Chip data-testid="cluster-info-task-unassigned" avatar={<Avatar className={classes.avatar}>{statistics.unassigned}</Avatar>}
            size="small"
            label="Unassigned"
            variant="outlined"
            className={classes.chip}
          />


          <Chip data-testid="cluster-info-task-healthy" avatar={<Avatar className={classes.avatar}>{statistics.healthy}</Avatar>}
            size="small"
            label="Healthy"
            variant="outlined"
            className={classes.chip}
          />
          <Chip data-testid="cluster-info-task-unhealthy" avatar={<Avatar className={classes.avatar}>{statistics.unhealthy}</Avatar>}
            size="small"
            label="Unhealthy"
            variant="outlined"
            className={classes.chip}
          />
          {/* <Chip data-testid="cluster-info-task-pending" avatar={<Avatar className={classes.avatar}>{statistics.provisioningTasks}</Avatar>}
            size="small"
            label="Pending"
            variant="outlined"
            className={classes.chip}
          /> */}
          <Chip data-testid="cluster-info-task-requested" avatar={<Avatar className={classes.avatar}>{cluster.requestedTasks}</Avatar>}
            size="small"
            label="Requested"
            variant="outlined"
            className={classes.chip}
          />
        </div>
      </div>
    </>
  )
}

export default ClusterInfo;
