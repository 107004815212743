import React, { useCallback } from 'react';
import Chip from '@material-ui/core/Chip';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store/app.store';
import { ClusterSortable, ClusterSortableType, DEFAULT_SORT_STATE, ParticipantSortable, sortCluster, TaskSortable, TaskSortableType } from '../clusters.slice';
import { Tooltip } from '@material-ui/core';

type SortOption<Field> = {
  field: Field;
  label: string;
}

export const clusterSortOptions: SortOption<ClusterSortableType>[] = [
  { field: ClusterSortable.ClassStartDate,  label: 'Class start date' },
  { field: ClusterSortable.ClassName,       label: 'Class name' },
];

export const normalizedClusterOptions = clusterSortOptions.reduce((o, d) => {
  o[d.field] = d;
  return o;
}, {} as Record<string, SortOption<ClusterSortableType>>)

export const taskSortOptions: SortOption<TaskSortableType>[] = [
  { field: TaskSortable.InstanceAddress,             label: 'Instance address' },
  { field: ParticipantSortable.ParticipantFirstName, label: 'Participant first name' },
  { field: ParticipantSortable.ParticipantLastName,  label: 'Participant last name' },
  { field: ParticipantSortable.ParticipantEmail,     label: 'Participant email' },
];

export const normalizedTaskOptions = taskSortOptions.reduce((o, d) => {
  o[d.field] = d;
  return o;
}, {} as Record<string, SortOption<TaskSortableType>>)


const ClusterSort: React.FC<any> = ({}) => {
  const clusterOrder = useSelector((state: RootState) =>
    state.clusters.sort.cluster?.order || DEFAULT_SORT_STATE.cluster.order
  );
  const clusterOrderBy = useSelector((state: RootState) =>
    state.clusters.sort.cluster?.orderBy || DEFAULT_SORT_STATE.cluster.orderBy
  );
  const taskOrder = useSelector((state: RootState) =>
    state.clusters.sort.task?.order || DEFAULT_SORT_STATE.task.order
  );
  const taskOrderBy = useSelector((state: RootState) =>
    state.clusters.sort.task?.orderBy || DEFAULT_SORT_STATE.task.orderBy
  );

  const [clusterSortEl, setClusterSortEl] = React.useState(null);
  const [taskSortEl, setTaskSortEl] = React.useState(null);
  const [selectedClusterOption, setClusterOption] = React.useState<SortOption<ClusterSortableType>|null>(normalizedClusterOptions[clusterOrderBy]);
  const [selectedTaskOption, setTaskOption] = React.useState<SortOption<TaskSortableType>|null>(normalizedTaskOptions[taskOrderBy]);

  

  // const [taskAnchorEl, setTaskAnchorEl] = React.useState(null);
  // const [taskSortSelected, setTaskSortSOption] = React.useState<SortOption|null>(normalizedOptions[orderBy]);

  const dispatch = useAppDispatch();

  const handleClick = (type: 'cluster'|'task') => (event: any) => {
    if (type === 'cluster') {
      setClusterSortEl(event.currentTarget);
    } else {
      setTaskSortEl(event.currentTarget);
    }
    
  };

  const handleSortOptionClick = (type: 'cluster'|'task') => (field: ClusterSortableType|TaskSortableType) => (event: any) => {
    if (type === 'cluster') {
      setClusterOption(normalizedClusterOptions[field]);
    }
    if (type ==='task') {
      setTaskOption(normalizedTaskOptions[field])
    }
    dispatch(sortCluster({
      cluster: {
        order: clusterOrder,
        orderBy: (type === 'cluster' ? field : clusterOrderBy) as ClusterSortableType
      },
      task: {
        order: taskOrder,
        orderBy: (type === 'task' ? field : taskOrderBy) as TaskSortableType
      }
    }));
    handleClose(type)();
  }

  const handleSortOrderClick = (type: 'cluster'|'task') => () => {
    dispatch(sortCluster({
      cluster: {
        order: (type === 'cluster' ? (clusterOrder === 'asc' ? 'desc' : 'asc') : clusterOrder),
        orderBy: clusterOrderBy
      },
      task: {
        order: (type === 'task' ? (taskOrder === 'asc' ? 'desc' : 'asc') : taskOrder),
        orderBy: taskOrderBy
      }
    }));
  }

  const handleClose = (type: 'cluster'|'task') => () => {
    if (type === 'cluster') {
      setClusterSortEl(null);
    } else {
      setTaskSortEl(null);
    }
  };

  // const handleChipClick = () => {}
  // const
  return (
    <>
    <div className="d-inline-block mr-3 mb-3">
      <Chip
        data-testid="cluster-sort-chip"
        label={
          <>
            <span>Order by </span>
            <Tooltip title="switch order by">
              <span style={{fontWeight: 700}}>{selectedClusterOption?.label || ""}</span>
            </Tooltip>
            <span>{clusterOrder === 'asc' ? ' ascending' : ' descending'}</span>
          
          </>
        }
        color={selectedClusterOption ? "primary" : "default"}
        aria-controls="cluster-sort-menu"
        aria-haspopup="true"
        onClick={handleClick('cluster')}
        onDelete={handleSortOrderClick('cluster')}
        deleteIcon={
          <Tooltip title="switch sort order">
            {clusterOrder === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          </Tooltip>
        } />
      <Menu
        id="cluster-sort-menu"
        anchorEl={clusterSortEl}
        keepMounted
        open={Boolean(clusterSortEl)}
        onClose={handleClose('cluster')}
      >
        {clusterSortOptions.map((option, index) => 
          <MenuItem key={index}
            onClick={handleSortOptionClick('cluster')(option.field)}>
            {option.label}
          </MenuItem>
        )}
      </Menu>
    </div>
    <div className="d-inline-block mb-3">
      <Chip
        data-testid="cluster-task-sort-chip"
        label={
          <>
            <span>Order by </span>
            <Tooltip title="switch order by">
              <span style={{fontWeight: 700}}>{selectedTaskOption?.label || ""}</span>
            </Tooltip>
            <span>{taskOrder === 'asc' ? ' ascending' : ' descending'}</span>
          
          </>
        }
        color={selectedTaskOption ? "primary" : "default"}
        aria-controls="task-sort-menu"
        aria-haspopup="true"
        onClick={handleClick('task')}
        onDelete={handleSortOrderClick('task')}
        deleteIcon={
          <Tooltip title="switch sort order">
            {taskOrder === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
          </Tooltip>
        } />
      <Menu
        id="task-sort-menu"
        anchorEl={taskSortEl}
        keepMounted
        open={Boolean(taskSortEl)}
        onClose={handleClose('task')}
      >
        {taskSortOptions.map((option, index) => 
          <MenuItem key={index}
            onClick={handleSortOptionClick('task')(option.field)}>
            {option.label}
          </MenuItem>
        )}
      </Menu>
    </div>
    </>
  )
}

export default ClusterSort;
