import React, { useRef } from "react";
import { TTask } from "../../../types/app.types";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { DialogCloseReason, DialogCloseEvent } from "../../../types/common.types";
import { formatParticipantName } from "../../../utils/formatName";
import { isEmptyParticipant } from "../../../utils/participant.util";

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

export type DeleteClusterTaskDialogProp = {
  data: TTask;
  open: boolean;
  onClose: (e?: DialogCloseEvent) => void;
  onConfirm: (payload?: any) => void;
}

/**
 * Displays a confirmation dialog when user triggers a deletion of a task from a cluster
 * Requirements
 * - focus on "confirm" button when dialog opens
 *   https://github.com/arielpartners/training-image-manager/issues/79
 */
export default function DeleteClusterTaskDialog(props: DeleteClusterTaskDialogProp) {
  const { onClose, onConfirm, open, data } = props;

  const handleClose = (e: any, reason: DialogCloseReason) => {
    onClose({reason});
  };

  const handleConfirm = () => {
    onConfirm(data);
    onClose({reason: 'confirmClick'});
  }

  return (
    <Dialog
      aria-labelledby="delete-cluster-task-dialog-title"
      data-testid="delete-cluster-task-dialog"
      onClose={handleClose}
      open={open}>
      <DialogTitle data-testid="delete-cluster-task-dialog-title">
        You're about to delete instance
      </DialogTitle>

      <DialogContent>
        <DialogContentText data-testid="delete-cluster-task-dialog-description">
          <Typography variant="body1" component="span">
            You are about to delete instance {
              data.publicIPv4Address !== ""
              ? "http://" + data.publicIPv4Address + ":8080"
              : 'with no ip address'
            }
          </Typography>
          {data.participants && data.participants.length
          ? ( data.participants.length > 1
            ? // when task is shared with multiple participants
            (
              <>
                <br/>
                <Typography variant="body1" component="span">
                  This instance belongs to
                  <b> {formatParticipantName(data.participants[0])}</b>
                  {((data.participants.length - 1) > 1)
                  ? ` and other ${data.participants.length - 1} people`
                  : " and other person"}
                </Typography>
              </>
            )
            : // when task is shared with single participant
            (
              isEmptyParticipant(data.participants[0])
              ? <>
                  <br/>
                  This instance is currently unassigned
                </>
              : (
                <>
                  <br/>
                  <Typography variant="body1" component="span">
                    This instance belongs to {formatParticipantName(data.participants[0])}
                  </Typography>
                </>
              )
            )
            
          )
          : <>
              <br/>
              This instance is currently unassigned
            </>
          }
          
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ColorButton data-testid="delete-cluster-task-dialog-confirm-button" onClick={handleConfirm} autoFocus>
          Delete
        </ColorButton>
        <Button data-testid="delete-cluster-task-dialog-cancel-button" onClick={(e) => handleClose(e, 'cancelClick')}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
