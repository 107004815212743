import TextField from '@material-ui/core/TextField';
import Typography from "@material-ui/core/Typography";
import { TParticipant } from "../../../types/app.types";
import ParticipantsList from "./ParticipantsList";



function NumberInputWithButton (props: any) {
  const {id, name, value, label, onChange, onBlur} = props;
  return (
    <div>
      <TextField data-testid="participant-count-input" type="number"
        autoFocus
        label={label}
        id={id} name={name} value={value}
        fullWidth
        InputProps={{
          onBlur,
          onChange
        }}
        inputProps={{min: 1}}
       />
    </div>
  );
}

/** Step 2 of creation form */
export type ParticipantRegistrationStepProp = {
  formik: any;
  participantCount: number;
  handleTaskCount: (type: 'participant'|'extra', value: number) => void;
  setParticipantCount: React.Dispatch<React.SetStateAction<number>>;
  setParticipants: (val: TParticipant[]) => void;
}

export default function ParticipantRegistrationStep (props: ParticipantRegistrationStepProp) {
  const {formik, participantCount, handleTaskCount, setParticipantCount, setParticipants} = props;
  const handleInstanceInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleTaskCount('participant', Number(e.target.value));
  }

  return (
    <div className="row">
      <div className="col-12 mb-3">
        <div className="d-flex flex-column flex-lg-row justify-content-lg-between">
          <Typography data-testid="participant-registration-step-title" variant="h5" component="h2">Participants</Typography>
          <div>
            <NumberInputWithButton
              id="participant-count"
              label="Number of Participants"
              value={participantCount}
              onChange={handleInstanceInputChange} />
          </div>
        </div>
      </div>
      <div className="col-12 mb-3">
        <ParticipantsList
          isEdit
          formik={formik}
          count={participantCount}
          setParticipants={setParticipants}
          setParticipantCount={setParticipantCount} />
      </div>
    </div>
  );
}
