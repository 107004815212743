import { useState } from 'react';

/* istanbul ignore file */
// This is for binding form for filter conditions
// initialValue should be an object
export function useConditions<
  Condition extends Record<string, any> = any,
  FieldName extends keyof Condition = any
>(initialValue: Condition): [
  Condition, (fieldName: FieldName) => (e: any) => void, (fieldName: FieldName) => void
] {
  const [state, setState] = useState(initialValue);

  // This function can be used on form control onChange or onInput event
  const onFieldChange = (fieldName: FieldName) => (e: any) => {
    // console.log(fieldName, e.target.value)
    setState(oldVal => ({...oldVal, [fieldName]: e.target.value}));
  }

  const unsetCondition = (fieldName: FieldName) => {
    setState(oldVal => ({...oldVal, [fieldName]: null}));
  }
  return [state, onFieldChange, unsetCondition];
}
