import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TCluster, TParticipant, TTask } from '../../../types/app.types';
import { TClusterFilterCondition } from './ClustersFilter';
import store, { RootState, useAppDispatch } from '../../../store/app.store';
import { filteredClustersSelector, userRoleSelector } from '../../../store/selectors';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom'
import {
  clusterInitialState, ClustersQuickFilter, ClusterState,
  startPollingClasses,
  stopPollingClasses
} from '../clusters.slice';
import { updateCluster, removeCluster, addClusterTask, RemoveClusterArgs } from '../clusters.actions';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import CompactClusterOrganizerList from './CompactClusterOrganizer';
import CompactClusterTaskList from './CompactClusterTaskList';
import format from "date-fns/format";
import differenceInDays from 'date-fns/differenceInDays';
import isBefore from "date-fns/isBefore";
import { useMediaQuery } from 'react-responsive';
import Tooltip from '@material-ui/core/Tooltip';
import PrintablePage, { usePrintElement } from '../../printable-page/PrintablePage';
import ClusterInfo from './ClusterInfo';
import { dasherize } from '../../../utils/strcase';
import DeleteClusterDialog from '../dialog/DeleteClusterDialog';
import InlineEditInput from '../../inline-edit/InlineEditInput';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  avatar: {
    color: `${theme.palette.grey[900]} !important`,
    backgroundColor: 'transparent',
    fontSize: '12px !important',
    fontWeight: 600,
    marginLeft: '4px !important'
  },
  chip: {
    margin: theme.spacing(0.5),
    display: 'inline-flex'
  },
}));

export type CompactClusterListProp = {
  condition: TClusterFilterCondition;
  quickFilters: ClustersQuickFilter;
}


// const mapStateToProps = (state: RootState, props: ComponentProps) => ({
// 	clusterNames: filteredClustersSelector(state, props),
//   entities: state.clusters.entities.byClusterName,
//   queues: state.clusters.queues,
//   loading: state.clusters.loading,
// });
// const mapDispatchToProps = (dispatch: any) => ({});
// const connector = connect(mapStateToProps, mapDispatchToProps);

// type PropsFromRedux = ConnectedProps<typeof connector>;
// type ConnectedComponentProps = PropsFromRedux & ComponentProps;

const classDays = (startDate: string, endDate: string) => {
  if (endDate && startDate && endDate !== "" && startDate !== "")
    return differenceInDays(new Date(endDate), new Date(startDate)) + 1;
  return 0;
};

const ClusterDateViewer: React.FC<{data: TCluster}> = ({data}) => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const days = classDays(data.startDate, data.endDate);
  return (
    <Tooltip title={format(new Date(data.startDate), 'MM/dd/yyyy hh:mm aa') + " ~ " + format(new Date(data.endDate), 'MM/dd/yyyy hh:mm aa')}>
      <div className="mt-5 mt-md-3 mt-lg-0 mb-md-1 mb-lg-3 d-flex flex-row flex-sm-column">
        <div className="d-flex flex-row align-items-center flex-sm-column mr-2">
          <Typography variant={isMobile ? 'h5' : 'h2'} component="div" className="mr-2 mr-sm-0">
            {format(new Date(data.startDate), 'dd')}
          </Typography>
          <Typography variant={isMobile ? 'body1' : 'subtitle1'} component="div" className="mr-2 mr-sm-0">
            {format(new Date(data.startDate), 'MMMM')}
          </Typography>
          <Typography variant="body1" component="div">
            {format(new Date(data.startDate), 'hh:mm aa')}
          </Typography>
        </div>
        <div className="d-flex flex-row align-items-center flex-sm-column mr-2">
          <Typography variant="caption" component="div" className="d-block text-muted">
            for {days} days
          </Typography>
        </div>
        
      </div>
    </Tooltip>
    
  )
}

const ClusterStatChips: React.FC<{
  data: TCluster,
  classes: any,
  statistics: {
    total: number;
    assigned: number;
    unassigned: number;
    healthy: number;
    unhealthy: number;
    provisioningTasks: number;
    runningTasks: number;
    emptyTasks: string[];
  }
}> = ({data, classes, statistics}) => {
  return (
  <div className="mt-3 mb-3 d-flex flex-wrap">
    <Chip avatar={<Avatar className={classes.avatar}>{statistics.total}</Avatar>}
      size="small"
      label="Total Instances"
      variant="outlined"
      className={classes.chip} />
    <Chip avatar={<Avatar className={classes.avatar}>{statistics.assigned}</Avatar>}
      size="small"
      label="Assigned"
      variant="outlined"
      className={classes.chip}
    />
    <Chip avatar={<Avatar className={classes.avatar}>{statistics.unassigned}</Avatar>}
      size="small"
      label="Unassigned"
      variant="outlined"
      className={classes.chip}
    />
    <Chip avatar={<Avatar className={classes.avatar}>{statistics.healthy}</Avatar>}
      size="small"
      label="Healthy"
      variant="outlined"
      className={classes.chip}
    />
    <Chip avatar={<Avatar className={classes.avatar}>{statistics.unhealthy}</Avatar>}
      size="small"
      label="Unhealthy"
      variant="outlined"
      className={classes.chip}
    />
    {/* <Chip avatar={<Avatar className={classes.avatar}>{statistics.provisioningTasks}</Avatar>}
      size="small"
      label="Pending"
      variant="outlined"
      className={classes.chip}
    /> */}
    <Chip avatar={<Avatar className={classes.avatar}>{data.requestedTasks}</Avatar>}
      size="small"
      label="Requested"
      variant="outlined"
      className={classes.chip}
    />
  </div>
  )
}

function clusterStateSelector(state: RootState, clusterName: string): ClusterState {
  if (!state.clusters.entities.byClusterName) return {...clusterInitialState, clusterName} as ClusterState;
  return state.clusters.entities.byClusterName[clusterName];
}

// Each cluster row
const ClusterItem: React.FC<{
  id: string;
  clusterName: string;
  isPrint?: boolean;
  isAlt?: boolean;
  deleteDisabled?: boolean;
}> = ({id, clusterName, isPrint, deleteDisabled}) => {
  const classes = useStyles();
  const data = useSelector((state: RootState) => clusterStateSelector(state, clusterName).data);
  const queues = useSelector((state: RootState) => clusterStateSelector(state, clusterName).queues);
  const statistics = useSelector((state: RootState) => clusterStateSelector(state, clusterName).statistics);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const role = useSelector(userRoleSelector);

  const [editingTask, setEditingTask] = useState<string|null>(null);

  const printablePageRef = useRef<HTMLDivElement|null>(null);
  const printPdf = usePrintElement(printablePageRef, dasherize(data.className));

  const handleShareClick = () => printPdf();

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  }

  const handleDeleteDialogSubmit = (e?: RemoveClusterArgs) => {
    // console.log(e)
    if (e)
      dispatch(removeCluster(e as RemoveClusterArgs) as any);
  }

  const handleDetailClick = () => {
    history.push(`/class/${data.clusterName}`)
  }

  // adds empty task to current cluster
  const handleAddClick = () => {
    dispatch(addClusterTask(data) as any);
  }


  const handlePollingStart = (clusterName: string) => {
    if (!store.getState().clusters.pollingQueues[clusterName])
      dispatch(startPollingClasses());
      // dispatch(startPollingTasks({clusterName}));
  };
  const handlePollingStop = (clusterName: string) => {
    dispatch(stopPollingClasses());
    // dispatch(stopPollingTasks({clusterName}));
  }

  
  const handleTaskEditStart = (taskArn: string) => (participantIndex: any) => {
    // console.log('handleTaskEditStart', taskArn, participantIndex);
    setEditingTask(taskArn);
    handlePollingStop(clusterName);
  }
  const handleTaskEditClose = (taskArn: string) => (participantIndex: number) => {
    // console.log('handleTaskEditClose', taskArn, participantIndex);
    setEditingTask(null);
    handlePollingStart(clusterName);
  }

  const handleClassNameSave = (val: any) => {
    if (isPrint || !updateCluster) return;
      dispatch(updateCluster({
      ...data,
      className: val,
      trainer: {
        username: (data.trainer?.username || "")
      },
      coTrainer: {
        username: (data.coTrainer?.username || "")
      },
      support: {
        username: (data.support?.username || "")
      },
    }) as any);
  }

  // useEffect(() => {
  //   handlePollingStart(clusterName);
  //   return () => {
  //     handlePollingStop(clusterName);
  //   }
  // }, []);


  // useEffect(()=> {
  //   // when there is editing task under this cluster,
  //   // polling should be stopped to prevent form state changes while user is on input field
  //   if (editingTask) handlePollingStop(clusterName);
  //   // when there is no editing task under this cluster
  //   // polling should restart
  //   else handlePollingStart(clusterName);
    
  // },[editingTask])
  
  return (
    <Paper data-testid={id} id={id} className="d-flex flex-column p-2 p-md-3 p-lg-4 position-relative my-2" elevation={3} key={clusterName}>
      <Grid container spacing={0}>
        <Grid item xs={12} className="position-sticky position-md-relative" style={{top: 52, zIndex: 10}}>
          <Grid container spacing={0}  style={{backgroundColor: 'white'}}>
            <Grid item xs={12} sm={3} md={2} lg={2} xl={1}>
              <ClusterDateViewer data={data} />
            </Grid>
            <Grid item xs={12} sm={9} md={10} lg={10} xl={11}>
              {/* <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={9} xl={11}>
              </Grid> */}
              <div className="mt-0 mt-sm-3 mt-md-3 mt-lg-0 mb-md-1 mb-lg-3 h-100">
                <div className="d-flex flex-column flex-md-column flex-xl-row justify-content-xl-between align-items-xl-center h-100">
                  <div className="flex-grow-0 flex-lg-grow-1 mt-0 mt-sm-5 mt-xl-0 mb-3">
                    <InlineEditInput
                      id="className"
                      defaultValue={data.className}
                      onSave={handleClassNameSave}
                      multiline={true}
                      rows={3}
                      rowsMax={3}
                      placeholder="enter class name"
                      viewFieldPlaceholder="click to edit class name"
                      style={{fontSize: '1.5rem'}}
                      viewField={
                      <Typography variant="h5" component="h3">
                        {data.className}
                      </Typography>}
                      viewFieldStyle={{fontSize: '1.5rem'}} />
                  </div>
                  
                  {/* <Typography variant="h5" component="h3" className="mt-0 mt-sm-5 mb-3">
                    {data.className}
                  </Typography> */}
                  <div>
                    <Button variant="outlined" className="ml-2" size="small"
                      onClick={handleDetailClick}>
                      Detail
                    </Button>
                    <Button variant="outlined" className="ml-2" size="small"
                      disabled={role === 'user'}
                      onClick={handleAddClick}>
                      Add
                    </Button>
                    <Button variant="outlined" className="ml-2" size="small"
                      onClick={handleShareClick}>
                      Print
                    </Button>
                    <Button variant="outlined" className="ml-2" size="small"
                      disabled={role === 'user'}
                      onClick={handleDeleteClick}>
                      Delete
                    </Button>
                    <DeleteClusterDialog open={deleteDialogOpen}
                      data={data}
                      statistics={statistics}
                      onClose={handleDeleteDialogClose}
                      onConfirm={handleDeleteDialogSubmit} />
                  </div>
                  <div className="d-none"></div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ClusterStatChips data={data} classes={classes} statistics={statistics} />
          <CompactClusterOrganizerList data={data}/>  
          {data.tasks && data.tasks.length ? (
            <CompactClusterTaskList
            cluster={data}
            tasks={data.tasks}
            queues={queues}
            onTaskItemEditStart={handleTaskEditStart}
            onTaskItemEditClose={handleTaskEditClose}
            />
          ) : (
            // when launchTimestamp has passed now it is being initiated
            isBefore(new Date(data.launchTimestamp), new Date())
            ? (
              <div className="d-flex justify-content-center align-items-center">
                <CircularProgress />
                <div className="mx-2">
                  Requested task(s) are being initiated.
                </div>
              </div>
            )
            : (
              <div className="d-flex justify-content-center align-items-center">
                {data.requestedTasks} instances were initially requested, to be started 2 hours prior to class.
              </div>
            )
          )}
          
        </Grid>
      </Grid>
      <div className="position-absolute" style={{top:10, right:10, zIndex: 11}}>
        <Typography variant="caption" className="d-flex flex-column flex-md-row align-items-end ">
          <span>
            <span className="text-muted">ID: </span>
            <span style={{fontWeight: 500}}>{clusterName.split('-')[0]}</span>
          </span>
          <span className="text-muted d-none d-md-inline-block mx-2"> / </span>
          <span>
            <span className="text-muted">Task Definition: </span>
            <span style={{fontWeight: 500}}>{data.taskDefinition}</span>
          </span>
          <span className="text-muted d-none d-md-inline-block mx-2"> / </span>
          <span>
            <span className="text-muted">{data.createdAt ? "Created: " : 'Date not available'}</span>
            <span style={{fontWeight: 500}}>{data.createdAt ? format(new Date(data.createdAt), 'EEEE, MMMM d hh:mm aa') : ''}</span>
          </span>
          
          <span className="text-muted d-none d-md-inline-block mx-2"> / </span>
          <span>
            <span className="text-muted">{data.launchTimestamp ? (data.launched ? "Launched At: " : "Launch At: ") : "No Launch Timestamp"}</span>
            <span style={{fontWeight: 500}}>{data.launchTimestamp ? format(new Date(data.launchTimestamp), 'EEEE, MMMM d hh:mm aa') : ''}</span>
          </span>
        </Typography>
      </div>
      {/** This element is hidden from UI, once user clicks share button this is used to create PDF formatted DOM element */}
      <PrintablePage pageRef={printablePageRef} hide>
        <ClusterInfo cluster={data} statistics={statistics} isPrint />
          <div id="class-task-list">
            <table className="table">
              <thead>
                <tr>
                  <th>Instance</th>
                  <th>Honorific</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Attendance</th>
                </tr>
              </thead>
              <tbody>
                {data.tasks.map((item: TTask, i) => item.participants && item.participants.length
                ? item.participants.map((participant: TParticipant, i) => (
                  <tr key={i}>
                    {i === 0 ? <td style={{verticalAlign: "middle"}} rowSpan={(item.participants && item.participants.length) || 0}>
                      {item.publicIPv4Address && item.publicIPv4Address !== '-'
                      ? <a href={"http://"+item.publicIPv4Address+":8080"}
                          target="_blank"
                          rel="noreferrer">
                          http://{item.publicIPv4Address}:8080
                        </a>
                      : item.publicIPv4Address}
                    </td> : <></>}
                    <td style={{verticalAlign: "middle"}}>
                      {participant.title && participant.title !== "" ? participant.title : "-"}
                    </td>
                    <td style={{verticalAlign: "middle"}}>
                      {participant.firstName} {participant.lastName}
                    </td>
                    <td style={{verticalAlign: "middle"}}>
                      {participant.email && participant.email !== "" ? participant.email : "-"}
                    </td>
                    <td style={{verticalAlign: "middle"}}>
                      <div className="d-flex justify-content-around">
                      {(participant.attendances || []).map((attendance, attendanceI) => attendance
                        ? <span key={attendanceI} className="mx-1">Y</span>
                        : <span key={attendanceI} className="mx-1">N</span>)}
                      </div>
                    </td>
                  </tr>
                  ))
                : (
                  <tr key={i}>
                    <td style={{verticalAlign: "middle"}}>
                      {item.publicIPv4Address && item.publicIPv4Address !== '-'
                      ? <a href={"http://"+item.publicIPv4Address+":8080"}
                          target="_blank"
                          rel="noreferrer">
                          http://{item.publicIPv4Address}:8080
                        </a>
                      : item.publicIPv4Address}
                    </td>
                    <td style={{verticalAlign: "middle"}} colSpan={4}>
                      Not assigned
                    </td>
                  </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </PrintablePage>
    </Paper>
  )
}

const CompactClusterList: React.FC<CompactClusterListProp> = (props) => {
  const { condition, quickFilters } = props;

  const clusterNames = useSelector((state: RootState) =>
    filteredClustersSelector(state, { condition, quickFilters }));
  const entities = useSelector((state: RootState) => state.clusters.entities.byClusterName);
  const queues = useSelector((state: RootState) => state.clusters.queues);
  const loading = useSelector((state: RootState) => state.clusters.loading);

  const removalsHas = useCallback((clusterName) => new Set(queues.removals).has(clusterName), [queues.removals])
  const dispatch = useAppDispatch();
  // polling from the top
  useEffect(() => {
    dispatch(startPollingClasses());
    return () => {
      dispatch(stopPollingClasses());
    }
  }, [])
  if (loading && !clusterNames.length) {
    return (
      <Paper className="d-flex flex-column align-items-center justify-content-center"
        elevation={1}
        style={{minHeight: '250px'}}>
        <Typography variant="subtitle1" component="p">
          Fetching data...
        </Typography>
      </Paper>
    )
  }
  
  return (
    <div data-testid="cluster-list">
      {clusterNames && clusterNames.length ? clusterNames
        .map((clusterName, index) =>
        <ClusterItem
          data-testid={`cluster-item-${index}`}
          id={`cluster-item-${index}`}
          key={clusterName}
          clusterName={clusterName}
          deleteDisabled={removalsHas(clusterName)}
        />) : (
        
        <Paper data-testid="no-clusters-fall-back" className="d-flex flex-column align-items-center justify-content-center"
          elevation={1}
          style={{minHeight: '250px'}}>
          <Typography variant="subtitle1" component="p">
            There are no classes available.
          </Typography>
          <Button color="primary" component={Link} to="/new-class">
            Create new class
          </Button>
        </Paper>
        )}
    </div>
  )
}

export default CompactClusterList;
