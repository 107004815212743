import { DataGrid } from '@material-ui/data-grid';
import { useState, useEffect } from 'react';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store/app.store';
import { TNewUser, TUser, TUserWithPassword } from '../../types/app.types';
import { StatusVariantType } from '../../types/status.types';
import { pushNotification } from '../notification/notification.slice';
import {
  addUser as addUserAction,
  loadUsers as loadUsersAction,
  removeUser as removeUserAction,
  resetPassword as resetPasswordAction,
  resendEmail as resendEmailAction,
  updateUser as updateUserAction
} from './users.actions';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from "@material-ui/core";
import RowActions from '../table/RowActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import DeleteUserDialog from './DeleteUserDialog';
import { DialogCloseEvent } from '../../types/common.types';
import ResetPasswordDialog from './ResetPasswordDialog';
import UserFormDialog from './UserFormDialog';
import ResendEmailDialog from './ResendEmailDialog';
import {stableSort,getComparator} from '../../utils/sort';
import { userRoleSelector } from '../../store/selectors';


type Column = {
  field: string;
  label: string;
  style?: {
    width: number;
    minWidth?: number;
    maxWidth?: number;
  };
}
const columns: Column[] = [
  { field: 'firstName', label: 'First name', style: { width: 130 } },
  { field: 'lastName', label: 'Last name', style: { width: 130 } },
  { field: 'email', label: 'Email', style: { width: 230 } },
  { field: 'status', label: 'Status', style: { width: 120 } },
  { field: 'role', label: 'Role', style: { width: 80 } },
];


const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRow: {
    height: 'fit-content',
    // '&:first-child': {
    //   paddingLeft: 10,
    // }
  },
  tableCell: {
    verticalAlign: 'middle !important',
    '&:first-child': {
      paddingLeft: 15,
    }
  },
  headerCell: {
    fontSize: 12,
    textTransform: 'uppercase',
    '&:first-child': {
      paddingLeft: 15,
    }
  }
}));


// const columnTypeTest: Column<TUser> = [
//   { field: "firstName" }
// ];
// // Because material-ui data-grid requires exact object matching with columns we need this type
// type UserRowData = Pick<TUser, 'firstName'|'lastName'|'email'> & {
//   // Material-UI: The data grid component requires all rows to have a unique id property.
//   id: string; // this is username
// };
// function tableRowDataSelector(state: RootState): UserRowData[] {
//   return state.users.data
//     .map(({firstName, lastName, email, username}) => ({
//       firstName, lastName, email, id: username
//     }));
// }

// const mapStateToProps = (state: RootState, props: any) => ({
// 	// users: tableRowDataSelector(state), // state.users.data, // tableRowDataSelector(state),
//   users: state.users.data,
// 	loading: state.users.loading
// });
// const mapDispatchToProps = (dispatch: any) => ({
// 	loadUsers:() => dispatch(loadUsersAction() as any),
//   addUser: (newUser: TNewUser) =>  dispatch(addUserAction(newUser)),
//   updateUser: (value: TUser) =>  dispatch(updateUserAction(value)),
//   removeUser: (user: TUser) =>  dispatch(removeUserAction(user)),
//   resetPassword: (user: TUserWithPassword) =>  dispatch(resetPasswordAction(user)),
//   resendEmail: (user: TUser) =>  dispatch(resendEmailAction(user)),
// 	pushNotification: (status: StatusVariantType, message: string) =>
// 		dispatch(pushNotification({ status, message }))
// });
// const connector = connect(mapStateToProps, mapDispatchToProps);
// type PropsFromRedux = ConnectedProps<typeof connector>;

function EnhancedTableHead<D extends Record<string, any> = any>(props: {
  classes: any;
  onSelectAllClick?: (e: any) => void;
  order?: 'asc'|'desc';
  orderBy?: keyof D;
  numSelected?: number;
  rowCount?: number;
  onRequestSort?: (e: any, property: keyof D) => void;
  columns: Column[]
}) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof D) => (event: any) => {
    if (onRequestSort) onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
        <TableCell
          key={column.field}
          align={'left'}
          className={classes.headerCell}
          sortDirection={orderBy === column.field ? order : false}
        >
          <TableSortLabel
            active={orderBy === column.field}
            direction={orderBy === column.field ? order : 'asc'}
            onClick={createSortHandler(column.field)}
          >
            <span className="text-uppercase">{column.label}</span>
            {orderBy === column.field ? (
              <span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
            ) : null}
          </TableSortLabel>
        </TableCell>
        ))}
        {/** Last column is for action button */}
        <TableCell scope="col" style={{width: '24px'}}>&nbsp;</TableCell>
      </TableRow>
    </TableHead>
  );
}

export function UserTableRow (props: any) {
  const { classes, user, id, removeUser, resetPassword, updateUser, users, resendEmail } = props;
  const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
  const [resendEmailDialogOpen, setResendEmailDialogOpen] = useState(false);
  const role = useSelector(userRoleSelector);

  // console.log('UserTableRow', user.email);
  

  const handleActionClick = (action: 'edit'|'remove'|'reset'|'resend') =>
    (event?: any) => {
      if (action === 'edit') setEditUserDialogOpen(true);
      if (action === 'remove') setDeleteConfirmationOpen(true);
      if (action === 'reset') setResetPasswordDialogOpen(true);
      if (action === 'resend') setResendEmailDialogOpen(true);
    };

  const handleDialogClose = (action: 'edit'|'remove'|'reset'|'resend') =>
    (event?: any) => {
      if (action === 'edit') setEditUserDialogOpen(false);
      if (action === 'remove') setDeleteConfirmationOpen(false);
      if (action === 'reset') setResetPasswordDialogOpen(false);
      if (action === 'resend') setResendEmailDialogOpen(false);
    }

  const handleEditConfirm = (event: TUser) => {
    updateUser(event);
  };
  const handleRemoveConfirm = (event: TUser) => {
    removeUser(event);
  };
  const handleResetPasswordConfirm = (event: TUser) => {
    resetPassword(event);
  };
  const handleResendConfirm = (event: TUser) => {
    resendEmail(event);
  };
  const getRowActions = () => ([
    {
      id: "user-edit-action",
      label: 'Edit',
      disabled: role === 'user',
      onClick: handleActionClick('edit')
    },
    {
      id: "reset-password-action",
      label: 'Reset Password',
      disabled: role === 'user',
      onClick: handleActionClick('reset')
    },
    {
      id: "resend-password-action",
      label: 'Resend Temporary Password',
      disabled: role === 'user',
      onClick: handleActionClick('resend')
    },
    {
      id: "delete-user-action",
      label: 'Delete',
      disabled: role === 'user',
      onClick: handleActionClick('remove')
    },
  ]);

  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.tableCell}>
        <div className="d-flex justify-content-start align-items-center">
          {user.firstName || "-"}
        </div>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <div className="d-flex justify-content-start align-items-center">
          {user.lastName || "-"}
        </div>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <div className="d-flex justify-content-start align-items-center">
          {user.email || "-"}
        </div>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <div className="d-flex justify-content-start align-items-center">
          {user.status}
        </div>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <div className="d-flex justify-content-start align-items-center">
          {user.role}
        </div>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <div className="d-flex justify-content-end align-items-center">
          <RowActions id={"user-action-"+id} actions={getRowActions()}/>
        </div>
        <ResetPasswordDialog open={resetPasswordDialogOpen}
          data={user}
          onClose={handleDialogClose('reset')}
          onConfirm={handleResetPasswordConfirm} />
        <DeleteUserDialog open={deleteConfirmationOpen}
          data={user}
          onClose={handleDialogClose('remove')}
          onConfirm={handleRemoveConfirm} />
        <UserFormDialog open={editUserDialogOpen}
          isEdit={true}
          users={users}
          defaultValue={user}
          onSubmit={handleEditConfirm}
          onClose={handleDialogClose('edit')} />
        <ResendEmailDialog open={resendEmailDialogOpen}
          data={user}
          onClose={handleDialogClose('resend')}
          onConfirm={handleResendConfirm}/>
      </TableCell>
    </TableRow>
  );
}

const UserTable: React.FC<any> = () => {
  const classes = useStyles();

  const [order, setOrder] = useState<'asc'|'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof TUser>('email');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const users = useSelector((state: RootState) => state.users.data);
  const handleRequestSort = (event: any, property: keyof TUser) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const dispatch = useAppDispatch();

  const loadUsers = () => dispatch(loadUsersAction() as any);
  // const addUser =  (newUser: TNewUser) =>  dispatch(addUserAction(newUser));
  const updateUser =  (value: TUser) =>  dispatch(updateUserAction(value));
  const removeUser =  (user: TUser) =>  dispatch(removeUserAction(user));
  const resetPassword =  (user: TUserWithPassword) =>  dispatch(resetPasswordAction(user));
  const resendEmail =  (user: TUser) =>  dispatch(resendEmailAction(user));
	// const pushNotification =  (status: StatusVariantType, message: string) =>
	// 	dispatch(pushNotification({ status, message }))

  useEffect(() => {
    if (loadUsers) loadUsers();
    return () => {}
  }, [])
  return (
    <div className="table-responsive-lg">
      <Table data-testid="user-table" className="table table-striped table-sm">
        <EnhancedTableHead classes={classes}
          order={order}
          orderBy={orderBy}
          rowCount={users.length}
          onRequestSort={handleRequestSort}
          columns={columns}
          />
        <TableBody>
          {users && users.length
          ? stableSort<TUser>(users, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((user, i) =>
              <UserTableRow key={i}
                id={i}
                classes={classes}
                user={user}
                users={users}
                resendEmail={resendEmail}
                updateUser={updateUser}
                resetPassword={resetPassword}
                removeUser={removeUser} />
            )
          : <TableRow>
              <TableCell colSpan={columns.length}>
              <div className="d-flex flex-column align-items-center justify-content-center" style={{minHeight: '250px'}}>
                  <Typography variant="subtitle1" component="p">
                    Fetching data...
                  </Typography>
                </div>
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
      <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </div>
  )
}

// export default connector(UserTable);
export default UserTable;
