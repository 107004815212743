import { TCluster, TTask } from "../../types/app.types";
import { ECSTaskHealthStatus, ECSTaskLifeCycle, UserStatus } from "../../types/status.types";

const _BASE_TASK_DATA: TTask = {
  createdAt: "2021-02-19T14:34:11.633Z",
  health: ECSTaskHealthStatus.HEALTHY,
  lastStatus: ECSTaskLifeCycle.RUNNING,
  networkInterfaceId: "eni-0fffcfcfd0000034b",
  privateIPv4Address: "172.31.71.75",
  publicIPv4Address: "35.175.131.125",
  replacementFor: "",
  stoppedReason: "",
  taskArn: "arn:aws:ecs:us-east-1:653124158642:task/a60bbb48-60c3-4798-95bb-193fea590f33/fcc1128d2eb84b42b50de1d0fea88aa9",
  taskDefinitionArn: "arn:aws:ecs:us-east-1:653124158642:task-definition/jira-fundamentals-class:2",
  participants: [
    { email: "john.doe@example.com", firstName: 'John', lastName: 'Doe', title: 'Mr', attendances: [] },
  ]
}

export const MOCK_PARTICIPANTS = [
  {
    "firstName": "Winnie",
    "lastName": "Hernandez",
    "email": "Winnie.Hernandez@arielpartners.com",
    "title": "Mr"
  },
  {
    "firstName": "Simmons",
    "lastName": "Dillard",
    "email": "Simmons.Dillard@arielpartners.com",
    "title": "Col"
  },
  {
    "firstName": "Randi",
    "lastName": "Hoffman",
    "email": "Randi.Hoffman@arielpartners.com",
    "title": ""
  },
  {
    "firstName": "Fitzgerald",
    "lastName": "Callahan",
    "email": "Fitzgerald.Callahan@arielpartners.com",
    "title": ""
  },
  {
    "firstName": "Mercedes",
    "lastName": "Rogers",
    "email": "Mercedes.Rogers@arielpartners.com",
    "title": ""
  },
  {
    "firstName": "Sheena",
    "lastName": "Armstrong",
    "email": "Sheena.Armstrong@arielpartners.com",
    "title": ""
  },
  {
    "firstName": "Adele",
    "lastName": "West",
    "email": "Adele.West@arielpartners.com",
    "title": "Mrs"
  },
  {
    "firstName": "Kelly",
    "lastName": "Leonard",
    "email": "Kelly.Leonard@arielpartners.com",
    "title": ""
  },
  {
    "firstName": "Mccray",
    "lastName": "Baker",
    "email": "Mccray.Baker@arielpartners.com",
    "title": ""
  },
  {
    "firstName": "Clements",
    "lastName": "Travis",
    "email": "Clements.Travis@arielpartners.com",
    "title": ""
  }
];

export const MOCK_TASK_DATA: Record<string, TTask> = {
  HEALTHY: _BASE_TASK_DATA,
  UNHEALTHY: {
    ..._BASE_TASK_DATA,
    health: ECSTaskHealthStatus.UNHEALTHY,
    participants: [
      MOCK_PARTICIPANTS[0]
    ]
  },
  UNKNOWN: {
    ..._BASE_TASK_DATA,
    health: ECSTaskHealthStatus.UNKNOWN,
    participants: [
      MOCK_PARTICIPANTS[1]
    ]
  },
  PROVISIONING: {
    ..._BASE_TASK_DATA,
    health: ECSTaskHealthStatus.UNKNOWN,
    lastStatus: ECSTaskLifeCycle.PROVISIONING,
    participants: [
      MOCK_PARTICIPANTS[2]
    ]
  },
}


export const MOCK_CLUSTERS_DATA: TCluster[] = [
  {
    "clusterName": "mock-a60bbb48-60c3-4798-95bb-193fea590f33",
    "className": "Jira Fundamentals Class with Longer Name",
    "runningTasks": 24,
    "taskDefinition": "jira-fundamentals-class",
    "pendingTasks": 0,
    "requestedTasks": 0,
    "launchTimestamp": "2021-02-17T03:00:20Z",
    "trainer": {
      "username": "30f1b763-dc64-4b03-8dc1-668a88786659",
      "firstName": "Milo",
      "lastName": "Kang",
      "email": "milok@arielpartners.com",
      status: UserStatus.CONFIRMED
    },
    "support": {
      "username": "55a9815b-35df-471a-8fa9-2b8719b453a7",
      "firstName": "Edward",
      "lastName": "Viaene",
      "email": "ward@in4it.io",
      status: UserStatus.CONFIRMED
    },
    "coTrainer": {
      "username": "304f6685-1d97-4f21-946d-c3d018c81905",
      "email": "edward@in4it.io",
      "firstName": "Edward",
      "lastName": "Viaene",
      status: UserStatus.FORCE_CHANGE_PASSWORD
    },
    "createdAt": "2021-02-17T03:00:20Z",
    "startDate": "2021-02-19T03:00:20Z",
    "endDate": "2021-02-25T03:00:20Z",
    "tasks": [
      
      {...MOCK_TASK_DATA.HEALTHY,
        publicIPv4Address: "35.175.131.124",
        participants: [MOCK_PARTICIPANTS[0]]
      },
      {...MOCK_TASK_DATA.HEALTHY,
        publicIPv4Address: "35.175.131.125",
        participants: [MOCK_PARTICIPANTS[1]]
      },
      {...MOCK_TASK_DATA.HEALTHY,
        publicIPv4Address: "35.175.131.126",
        participants: [MOCK_PARTICIPANTS[2]]
      },
      {...MOCK_TASK_DATA.HEALTHY,
        publicIPv4Address: "35.175.131.127",
        participants: [MOCK_PARTICIPANTS[3]]
      },
      {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.128",},
      {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.129",},
      {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.130",},
      {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.131",},
      {...MOCK_TASK_DATA.UNHEALTHY, publicIPv4Address: "35.175.131.132",},
      {...MOCK_TASK_DATA.UNHEALTHY, publicIPv4Address: "35.175.131.133",},
      {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.134",},
      {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.135",},
      {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.136",},
      {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.137",},
      {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.138",},
      {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.139",},
      {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.140",},
      {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.141",},
      {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.142",},
      {...MOCK_TASK_DATA.UNKNOWN, publicIPv4Address: "35.175.131.143",},
      // Some empty tasks
      {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.121", participants: []},
      {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.122", participants: []},
      {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.123", participants: []},
    ],
    "launched": true,
  },
  // mock shared instance
  {
    "clusterName": "mock-a60bbb48-60c3-4798-95bb-193fea590f332",
    "className": "Jira Fundamentals Class Shared",
    "runningTasks": 24,
    "taskDefinition": "jira-fundamentals-class-shared",
    "pendingTasks": 0,
    "requestedTasks": 0,
    "trainer": {
      "username": "30f1b763-dc64-4b03-8dc1-668a88786659",
      "firstName": "Milo",
      "lastName": "Kang",
      "email": "milok@arielpartners.com",
      status: UserStatus.CONFIRMED
    },
    "support": {
      "username": "55a9815b-35df-471a-8fa9-2b8719b453a7",
      "firstName": "Edward",
      "lastName": "Viaene",
      "email": "ward@in4it.io",
      status: UserStatus.CONFIRMED
    },
    "coTrainer": {
      "username": "304f6685-1d97-4f21-946d-c3d018c81905",
      "email": "edward@in4it.io",
      "firstName": "Edward",
      "lastName": "Viaene",
      status: UserStatus.FORCE_CHANGE_PASSWORD
    },
    "launchTimestamp": "2021-02-17T03:00:20Z",
    "createdAt": "2021-02-17T03:00:20Z",
    "startDate": "2021-02-18T03:00:20Z",
    "endDate": "2021-02-19T10:00:20Z",
    "tasks": [
      {
        createdAt: "2021-02-19T14:34:11.633Z",
        health: ECSTaskHealthStatus.HEALTHY,
        lastStatus: ECSTaskLifeCycle.RUNNING,
        networkInterfaceId: "eni-0fffcfcfd0000034b",
        privateIPv4Address: "172.31.71.75",
        publicIPv4Address: "35.175.131.110",
        replacementFor: "",
        stoppedReason: "",
        taskArn: "arn:aws:ecs:us-east-1:653124158642:task/a60bbb48-60c3-4798-95bb-193fea590f33/fcc1128d2eb84b42b50de1d0fea88aa9",
        taskDefinitionArn: "arn:aws:ecs:us-east-1:653124158642:task-definition/jira-fundamentals-class:2",
        participants: [
          MOCK_PARTICIPANTS[0],
          MOCK_PARTICIPANTS[1],
          MOCK_PARTICIPANTS[2],
          MOCK_PARTICIPANTS[3],
          MOCK_PARTICIPANTS[4],
          MOCK_PARTICIPANTS[5],
          MOCK_PARTICIPANTS[6],
          MOCK_PARTICIPANTS[7],
          MOCK_PARTICIPANTS[8],
          MOCK_PARTICIPANTS[9],
        ]
      }
      
    ],
    "launched": true,
  },
  {
    "clusterName": "mock-a60bbb48-60c3-4798-95bb-193fea590f3312",
    "className": "Jira Advanced Class with Longer Name",
    "runningTasks": 24,
    "taskDefinition": "jira-advanced-class",
    "pendingTasks": 0,
    "requestedTasks": 0,
    "trainer": {
      "username": "30f1b763-dc64-4b03-8dc1-668a88786659",
      "firstName": "Milo",
      "lastName": "Kang",
      "email": "milok@arielpartners.com",
      status: UserStatus.CONFIRMED
    },
    "support": {
      "username": "55a9815b-35df-471a-8fa9-2b8719b453a7",
      "firstName": "Edward",
      "lastName": "Viaene",
      "email": "ward@in4it.io",
      status: UserStatus.CONFIRMED
    },
    "coTrainer": {
      "username": "304f6685-1d97-4f21-946d-c3d018c81905",
      "email": "edward@in4it.io",
      "firstName": "Edward",
      "lastName": "Viaene",
      status: UserStatus.FORCE_CHANGE_PASSWORD
    },
    "launchTimestamp": "2021-02-17T03:00:20Z",
    "createdAt": "2021-02-17T03:00:20Z",
    "startDate": "2021-02-15T03:00:20Z",
    "endDate": "2021-02-16T03:00:20Z",
    "tasks": [
      
      {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.124",},
      {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.125",},
      {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.126",},
      // {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.127",},
      // {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.128",},
      // {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.129",},
      // {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.130",},
      // {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.131",},
      // {...MOCK_TASK_DATA.UNHEALTHY, publicIPv4Address: "35.175.131.132",},
      // {...MOCK_TASK_DATA.UNHEALTHY, publicIPv4Address: "35.175.131.133",},
      // {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.134",},
      // {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.135",},
      // {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.136",},
      // {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.137",},
      // {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.138",},
      // {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.139",},
      // {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.140",},
      // {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.141",},
      // {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.142",},
      // {...MOCK_TASK_DATA.UNKNOWN, publicIPv4Address: "35.175.131.143",},
      // Some empty tasks
      {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.121", participants: []},
      {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.122", participants: []},
      {...MOCK_TASK_DATA.HEALTHY, publicIPv4Address: "35.175.131.123", participants: []},
    ],
    "launched": true,
  },
]

// .map((cluster: TCluster, i) => ({
//   ...cluster, clusterName: `mock-cluster-${i}`
// }));
