/* istanbul ignore file */
import Typography from "@material-ui/core/Typography";
// import Paper from "@material-ui/core/Paper";
import { Fab, Grid, InputLabel, NativeSelect, TextField } from "@material-ui/core";
import { useState, useEffect } from "react";
import { RootState, useAppDispatch } from "../store/app.store";
import { getAuditLog } from "../store/audit.slice";
// import format from 'date-fns/format';
import {subDays,startOfMonth, parseISO} from 'date-fns'
import  { zonedTimeToUtc, utcToZonedTime, format } from 'date-fns-tz';
import { useSelector } from "react-redux";
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
import { loadUsers } from "../components/users/users.actions";
import { Dispatch } from "redux";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DATE_RANGE_OPTIONS, formatDate } from "../utils/formatDate";
// import { getDateArrays } from "../store/audit.slice";
import startOfToday from 'date-fns/startOfToday'
import endOfToday from 'date-fns/endOfToday'
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";

const dateOptionKeys = [
  'Today',
  'Last 7 Days',
  'Last 30 Days',
  'This Month',
  'Custom'
];

const actionFilterOptions = [
  'CreateClass',
  'UpdateClass',
  'DeleteClass',
  'CreateTask',
  'UpdateTask',
  'DeleteTask',
]
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const AuditPage = () => {
  const dispatch: Dispatch<any> = useAppDispatch();
  const count = useSelector((state: RootState) => state.audit.count);
  const dates = useSelector((state: RootState) => state.audit.dates || []);
  const {usernames, byUsername} = useSelector((state: RootState) => state.users.normalized);
  const byDate = useSelector((state: RootState) => state.audit.byDate);
  const classes = useStyles();
  const [expanded, setExpanded] = useState<any>(false);

  const [ dateOption, setDateOption ] = useState("Today");
  const [ showDateRange, setShowDateRange ] = useState(false);

  const [ actionFilter, setActionFilter ] = useState<string|null>(null);

  const [from, setFrom] = useState<Date>(DATE_RANGE_OPTIONS["Today"].from);
  const [to, setTo] = useState<Date>(DATE_RANGE_OPTIONS["Today"].to);

  // const [start, setStart] = useState<string>(DATE_RANGE_OPTIONS["Today"].from);
  // const [end, setEnd] = useState<string>(DATE_RANGE_OPTIONS["Today"].to);

  const handleChange = (panel: any) => (event: any, isExpanded: boolean = false) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleActionFilterSelect = (e: any) => {
    setActionFilter(e.target.value !== "" ? e.target.value : null);
  }
  const handleDateOptionSelect = (e: any) => {
    setDateOption(e.target.value);
    const dateOptionValue: {from: Date; to: Date;} =
      e.target.value === "Custom"
      ? { from, to }
      : {
        from: DATE_RANGE_OPTIONS[e.target.value].from,
        to: DATE_RANGE_OPTIONS[e.target.value].to,
      };
    // if (e.target.value)
    // const dateArr = getDateArrays({
    //   dateOption: e.target.value,
    //   from: from,
    //   to: to
    // });

    if (e.target.value === "Custom") {
      setShowDateRange(true);
    } else {
      setShowDateRange(false);
    }
    setFrom(dateOptionValue.from);
    setTo(dateOptionValue.to);
  }

  const handleDateChange = (type: 'from'|'to') => (e: any) => {
    if (type === 'from') {
      setFrom(parseISO(e.target.value))
    }
    if (type === 'to') {
      setTo(parseISO(e.target.value))
    }
    // console.log(e.target.value, format(new Date(e.target.value+"T00:00"), "yyyy-MM-dd"))
    // dispatch(getAuditLog({
    //   day: format(new Date(e.target.value+"T00:00"), "yyyy-MM-dd"),
    //   operator: 'after'
    // }));
  }
  // const timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone || "America/New_York";

  // useEffect(() => {
  //   // console.log("useEffect", from, to);
  //   dispatch(getAuditLog({ start, end }));
  // }, [start, end])

  useEffect(() => {
    // console.log("useEffect", from, to);
    const dateObj = dateOption === "Custom" ? {
      start: startOfDay(from).toISOString(),
      end: endOfDay(to).toISOString(),
    } : {
      start: (
        DATE_RANGE_OPTIONS[dateOption].from.toISOString() ||
        startOfToday().toISOString()
      ),
      end: (
        DATE_RANGE_OPTIONS[dateOption].to.toISOString() ||
        endOfToday().toISOString()
      ),
    };
    dispatch(getAuditLog(dateObj));
  }, [from, to])

  useEffect(() => {
    dispatch(loadUsers());
    // dispatch(getAuditLog({
    //   day: format(new Date(), "yyyy-MM-dd"),
    //   operator: 'after'
    // }));
    return () => {}
  }, [])
  return (
    <div data-testid="audit-page-container" className="container pt-3 px-5">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">
        <div className="d-flex">
          <div>
            <Typography variant="h4" component="h1" className="d-inline-block mr-2">
              Audit
            </Typography>
            <Typography variant="body1" component="div" className="d-inline-block mr-2">
              {from === to ? `${from}` : `Between ${format(new Date(from), "yyyy-MM-dd HH:mm")} to ${format(new Date(to), "yyyy-MM-dd HH:mm")}`}
            </Typography>
          </div>
          
        </div>

        <div className="d-flex flex-column flex-lg-row align-items-end">
          <div className="d-flex flex-column justify-content-end mr-lg-2">
            <InputLabel htmlFor="actionFilter" shrink className="m-0">Filter Action</InputLabel>
            <NativeSelect id="actionFilter"
              fullWidth
              value={actionFilter||""}
              onChange={handleActionFilterSelect}>
              <option key="none" value="">None</option>
              {actionFilterOptions.map((key) =>
              <option key={key} value={key}>{key}</option>)
              }
            </NativeSelect>
          </div>
          <div className="d-flex flex-column justify-content-end">
            <InputLabel htmlFor="dateRange" shrink className="m-0">Date Range</InputLabel>
            <NativeSelect id="dateRange"
              fullWidth
              value={dateOption}
              onChange={handleDateOptionSelect}>
                
              {dateOptionKeys.map((key) =>
              <option key={key} value={key}>{key}</option>)
              }
            </NativeSelect>
          </div>
          
          <div>
          {showDateRange ?
            <>
              <TextField
                id="date"
                label="From"
                type="date"
                value={format(new Date(from), "yyyy-MM-dd")}
                inputProps={{
                  max: format(new Date(), "yyyy-MM-dd"),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleDateChange('from')}
              />
              <TextField
                id="date"
                label="To"
                type="date"
                value={format(new Date(to), "yyyy-MM-dd")}
                inputProps={{
                  max: format(new Date(), "yyyy-MM-dd"),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleDateChange('to')}
              />
            </>
          : <></>}
          </div>
        </div>
        
      </div>
      {/* <div className="row">
        <div className="col-12">
          logs found {from === to ? `on ${from}` : `between ${from} to ${to}`}
        </div>
      </div> */}
      <div className="row">
        <div className="col-12">
          <Grid container spacing={0} className="px-3">

            <Grid item xs={12} sm={4} md={4}>
              <Typography variant="body1" style={{fontWeight: 500}}>
                Timestamp
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography variant="body1" style={{fontWeight: 500}}>
                Action
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className="col-12">
          <div className={classes.root}>
            {dates && dates.length
            ? dates.map((date) =>
              <div key={date}>
                {byDate[date] && byDate[date].length ? byDate[date]
                .filter(item => actionFilter && item ? item.action === actionFilter : true)
                .map((item, i) => 
              <Accordion key={i} expanded={expanded === i} onChange={handleChange(i)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>
                    {formatDate(new Date(item.date +"T"+ item.time))}
                  </Typography>
                  <div className={classes.secondaryHeading + " d-flex"}>
                    <Typography variant="body1">
                      <span className="text-dark" style={{fontWeight: 500}}>{item.action}</span>
                      <span> by {byUsername && byUsername[item.username] ? (
                        <span>
                          {byUsername?.[item.username]?.firstName} {byUsername?.[item.username]?.lastName}
                        </span>
                      ) : (
                        <span>
                          Unknown
                        </span>
                      )}
                      </span>
                      <span> - {item.clusterName}</span>
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 col-lg-6">
                        <h5>
                          Class Info / Source
                        </h5>
                        <pre>
                          {item.class !== "" && JSON.stringify(JSON.parse(item.class), null, 2)}
                        </pre>
                        
                      </div>
                      <div className="col-12 col-lg-6">
                        <h5>
                          Source
                        </h5>
                        <pre>
                          {item.source !== "" && JSON.stringify(JSON.parse(item.source), null, 2)}
                        </pre>
                        <h5>
                          Previous State
                        </h5>
                        <pre>
                          {item.prevState !== "" && JSON.stringify(JSON.parse(item.prevState), null, 2)}
                        </pre>
                      </div>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              ) : <></> }
              </div>
            )
            : <></>}

            {count === 0 ?
            <div className="w-100 d-flex justify-content-center align-items-center" style={{minHeight: '30vh'}}>
              There are no logs found {from === to ? `on ${from}` : `between ${from} to ${to}`}
            </div> : ""}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuditPage;
