import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/* istanbul ignore next */
export const amplifyAuthUserToState = (authData: any) => ({
  username: authData && authData.username,
  attributes: authData && authData.attributes,
  signInUserSession: {
    accessToken: authData && {
      jwtToken: authData.signInUserSession.accessToken.jwtToken,
      payload: authData.signInUserSession.accessToken.payload
    },
    clockDrift: authData && authData.signInUserSession.clockDrift,
    idToken: authData && {
      jwtToken: authData.signInUserSession.idToken.jwtToken,
      payload: authData.signInUserSession.idToken.payload
    },
    refreshToken: authData && {
      token: authData.signInUserSession.refreshToken.token,
    }
  }
})

export interface CognitoUserAttributeInterface {
  email: string|null;
  email_verified: boolean;
  sub: string|null;
  'custom:role': 'admin'|'user'|null;
}

export type AuthenticatedUserState = {
  attributes: CognitoUserAttributeInterface;
  signInUserSession: {
    accessToken: { jwtToken: string|null; payload: any; };
    idToken: { jwtToken: string|null; payload: any; };
    clockDrift: number;
    refreshToken: { token: string|null; }
  };
  username: string|null;
}

export const authenticatedUserInitialState: AuthenticatedUserState = {
  attributes: {
    email: null,
    email_verified: false,
    sub: null,
    'custom:role': 'user'
  },
  signInUserSession: {
    accessToken: { jwtToken: null, payload: null },
    clockDrift: 0,
    idToken: { jwtToken: null, payload: null },
    refreshToken: { token: null }
  },
  username: null,
}

const authenticatedUserSlice = createSlice({
  name: 'auth',
  initialState: authenticatedUserInitialState,
  reducers: {
    setUser(state, action: PayloadAction<Partial<AuthenticatedUserState>>) {
      const { attributes, signInUserSession, username } = action.payload;
      state.attributes = attributes ? {...attributes} : authenticatedUserInitialState.attributes;
      state.signInUserSession = signInUserSession ? {...signInUserSession} : authenticatedUserInitialState.signInUserSession;
      state.username = username || null;
    }
  }
})

export const {setUser} = authenticatedUserSlice.actions;

export default authenticatedUserSlice.reducer;
