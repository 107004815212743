import React, { useEffect } from 'react';
import { TCluster, TParticipant, TTask } from '../../../types/app.types';
import Grid from '@material-ui/core/Grid';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import InstanceStatus from "./InstanceStatusIcon";

// import MoreVert from '@material-ui/icons/MoreVert';
import DeleteForever from '@material-ui/icons/DeleteForever';
import SyncIcon from '@material-ui/icons/Sync';
import EditIcon from '@material-ui/icons/Edit';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import MergeTypeIcon from '@material-ui/icons/MergeType';


import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
// import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden';
// import { makeStyles } from '@material-ui/core/styles';
import { RootState, useAppDispatch } from '../../../store/app.store';
import { ClusterState, clusterInitialState, ClusterQueues } from '../clusters.slice';
import { updateClusterTask, stopClusterTask, restartClusterTask } from '../clusters.actions';
import { useSelector } from 'react-redux';
import { EMPTY_PARTICIPANT } from '../form/ParticipantsList';
import DeleteClusterTaskDialog from '../dialog/DeleteClusterTaskDialog';
import ParticipantInlineEditV2 from '../../inline-edit/ParticipantInlineEdit';
import { isMyClassSelector, userRoleSelector } from '../../../store/selectors';
import AddParticipantDialog from '../dialog/AddParticipantDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteParticipantDialog from '../dialog/DeleteParticipantDialog';
import { isEmptyParticipant } from '../../../utils/participant.util';

// function displayName(participant: TParticipant, excludeTitle: boolean = false) {
//   const {title, firstName, lastName} = participant;
//   const fullName = `${firstName} ${lastName}`;

//   if (!excludeTitle && title && title !== "")
//     return `${title}. ${fullName}`;
//   else
//     return fullName;
// }

// const ParticipantItem: React.FC<{participant: TParticipant;}> = ({participant}) => {
//   return (
//     <Grid container spacing={0}>
//       <Hidden lgUp>
//         <Grid item xs={12} sm={12} md={12} lg={5} xl={4}>
//           <Typography variant="subtitle1" className="mr-2">
//             {displayName(participant)}
//           </Typography>
//         </Grid>
//         <Grid item xs={12} sm={12} md={12} lg={7} xl={8}>
//           <Typography variant="body1">
//             {participant.email}
//           </Typography>
//         </Grid>
//       </Hidden>
//       <Hidden mdDown>
//         <Grid item lg={2} className="pl-3">
//           <Typography variant="subtitle1">
//             {participant.title}
//           </Typography>
//         </Grid>
//         <Grid item lg={4}>
//           <Typography variant="subtitle1">
//             {displayName(participant, true)}
//           </Typography>
//         </Grid>
//         <Grid item lg={6}>
//           <Typography variant="body1">
//             {participant.email}
//           </Typography>
//         </Grid>
//       </Hidden>
//     </Grid>
//   )
// }

/* istanbul ignore next */
function clusterTaskStatusBackgroundColor(lastStatus: string, health: string) {
  if (lastStatus === "RUNNING") {
    switch(health) {
      case "HEALTHY":
        return 'rgba(25, 118, 210, 0.06)';
      case "UNHEALTHY":
        return 'rgba(244, 67, 54, 0.06)';
      default:
        return 'rgba(0,0,0, 0.02)';
    }
  }
  return 'rgba(0,0,0,0.02)';
}

/* istanbul ignore next */
function clusterStateSelector(state: RootState, clusterName: string): ClusterState {
  if (!state.clusters.entities.byClusterName) return {...clusterInitialState, clusterName} as ClusterState;
  return state.clusters.entities.byClusterName[clusterName];
}

export type CompactClusterTaskItemProp = {
  cluster: TCluster;
  task: TTask;
  queues: ClusterQueues; // from cluster state
  onTaskItemEditStart: (taskArn: string) => (participantIndex: number) => void;
  onTaskItemEditClose: (taskArn: string) => (participantIndex: number) => void;
}

const CompactClusterTaskCard: React.FC<CompactClusterTaskItemProp> = ({cluster, task, queues, onTaskItemEditStart, onTaskItemEditClose}) => {

  const {clusterName, className, taskDefinition} = cluster;
  const {stoppedReason, taskArn, lastStatus, publicIPv4Address, health, createdAt, participants} = task;
  const stopping = new Set(queues.stopping);
  const starting = new Set(queues.starting);
  const restarting = new Set(queues.restarting);
  const editDisabled: boolean = (
    // during PROVISIONING or PENDING instance address hasn't assigned and printed as "-"
    // when user saves this instance during this stage it seems that "-" is
    // saved on database and actual ip address is never updated
    lastStatus === "PROVISIONING" ||
    lastStatus === "PENDING" ||
    lastStatus === "STOPPING" ||
    lastStatus === "STOPPED" ||
    // !Important even when task removal is invoked status of instance could still be at "RUNNING"
    stoppedReason === "invoked by delete-ecs-task" ||
     // when initializing task we shouldn't let user edit the form
     // why? current update API overwrites field that shouldn't be updated
     // such as "publicIPv4Address" field
    (lastStatus === "RUNNING" && health !== 'HEALTHY')
  );

  // console.log("CompactClusterTaskCard",participants);

  const [isHover, setHoverStatus] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const emptyTasks = useSelector((state: RootState) => 
    clusterStateSelector(state, cluster.clusterName).statistics.emptyTasks || []
  );
  const emptyTaskSet = new Set(emptyTasks);
  
  const isMyClass = useSelector((state: RootState) => isMyClassSelector(state, cluster));
  const [editingParticipant, setEditingParticipant]= React.useState<number|null>(null);

  const [addParticipantDialogOpen, setAddParticipantDialogOpen]= React.useState(false);
  const [deleteParticipantDialogOpen, setDeleteParticipantDialogOpen]= React.useState<number|null>(null);

  const handleMouseEnter = () => {
    setHoverStatus(true);
  }
  const handleMouseExit = () => {
    setHoverStatus(false);
  }
  const bgColor = clusterTaskStatusBackgroundColor(lastStatus, health);
  const dispatch = useAppDispatch();
  const role = useSelector(userRoleSelector);

  const handleMoveToEmptyInstanceClick = () => {
    const targetTask = cluster.tasks.find(task => task.taskArn === emptyTasks[0]);
    if (targetTask) {
      dispatch(updateClusterTask({ clusterName, className, task: {...targetTask, participants} }) as any);
      dispatch(updateClusterTask({ clusterName, className, task: {...task, participants: [EMPTY_PARTICIPANT]} }) as any);
    }
  }

  const handleAddParticipantClick = () => {
    // alert('This feature is under development, along with support of multiple participant on single instance');
    setAddParticipantDialogOpen(true);
  }

  const handleAddParticipantDialogClose = () => {
    setAddParticipantDialogOpen(false);
  }

  const handleDeleteParticipantDialogClose = () => {
    setDeleteParticipantDialogOpen(null);
  }

  const handleDeleteParticipantDialogSubmit = (e: TParticipant[]) => {
    // console.log("handleDeleteParticipantDialogSubmit", e)
    dispatch(updateClusterTask({
      clusterName,
      className,
      task: { ...task,
        participants: e
      }
    }) as any);
  }

  // Because websocket is overrides, updated value flickers...
  // Perhaps we can work this out in websocket lister where cached values doesn't change state
  const handleAddParticipantDialogSubmit = (e: TParticipant[]) => {

    dispatch(updateClusterTask({ clusterName, className, task: {
      ...task, participants: [...(task.participants || []), ...(e || [])]
    } }) as any);

  }

  const handleUpdateTask = () =>
    dispatch(updateClusterTask({ clusterName, className, task }) as any);

  const handleDeleteClick        = () => { setDeleteDialogOpen(true) }
  const handleDeleteDialogClose  = () => { setDeleteDialogOpen(false) }
  const handleDeleteDialogSubmit = () => {
    dispatch(stopClusterTask({ clusterName, className, task }) as any);
  }

  const handleRestartClick = () => {
    dispatch(restartClusterTask({
      clusterName,
      className,
      task,
      taskDefinition: taskDefinition as any
    }) as any)
  }

  const handleInlineEditOpen = (index: number) => () => {
    // console.log('TaskRow.handleInlineEditOpen',index);
    setEditingParticipant(index);
    if (onTaskItemEditStart(task.taskArn)) onTaskItemEditStart(task.taskArn)(index);
  };

  const handleInlineEditClose = (index: number) => () => {
    // console.log('TaskRow.handleInlineEditClose',index);
    setEditingParticipant(null);
    if (onTaskItemEditClose(task.taskArn)) onTaskItemEditClose(task.taskArn)(index);
  };

  const handleInlineEditSave = (index: number) => (e: TParticipant) => {
    const updatedParticipants = [...(participants || [])];
    updatedParticipants[index] = {...updatedParticipants[index], ...e};
    dispatch(updateClusterTask({
      clusterName, className,
      task: {...task, participants: updatedParticipants}
    }) as any);
    
    // updateTask(cluster, {...task, participants: updatedParticipants})
  }

  const restartLabel = restarting.has(taskArn) ? 'Restarting instance' : 'Restart instance';
  const deleteLabel = stopping.has(taskArn) ? 'Deleting instance' : 'Delete instance';
  return (
    <Card data-testid={`task-card-${taskArn}`} className="w-100 mb-2" elevation={isHover ? 1 : 0}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseExit}
      style={{border: '1px solid rgba(0,0,0,0.14)'}}>
      <CardContent className="p-0">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={5} lg={4} xl={3}
            className="p-2"
            style={{
              backgroundColor: 'rgba(0,0,0,0.02)', // bgColor,
              borderRight: '1px solid rgba(0,0,0,0.04)'
            }}>
            <div className="d-flex justify-content-between align-items-center h-100">
              <Grid container spacing={0}>
                <Grid item xs={12} sm={4} className="d-flex align-items-center">
                  <InstanceStatus showLabel
                    stoppedReason={stoppedReason}
                    lastStatus={stopping.has(taskArn) ? "DEACTIVATING" : lastStatus}
                    health={health}
                    createdAt={createdAt} />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography variant="body1">
                    {publicIPv4Address && publicIPv4Address !== '-'
                      ? <a href={"http://"+publicIPv4Address+":8080"}
                          target="_blank"
                          rel="noreferrer">
                          http://{publicIPv4Address}:8080
                        </a>
                      : publicIPv4Address}
                  </Typography>
                </Grid>
              </Grid>

              {/** Instance Actions */}
              <div className="d-flex justify-content-end" style={{minWidth:80}}>

                {/** Restart Button */}
                <IconButton size="small"
                  data-testid={`${taskArn}-restart-btn`}
                  aria-label={restartLabel}
                  disabled={starting.has(taskArn) || stopping.has(taskArn) || (role === 'user' && !isMyClass)}
                  onClick={handleRestartClick}>
                  <Tooltip title={restartLabel}>
                    <SyncIcon />
                  </Tooltip>
                </IconButton>

                {/** Delete Button */}
                <IconButton size="small"
                  data-testid={`${taskArn}-delete-btn`}
                  aria-label={deleteLabel}
                  disabled={stopping.has(taskArn) || (role === 'user' && !isMyClass)}
                  onClick={handleDeleteClick}>
                  <Tooltip title={deleteLabel}>
                    <DeleteForever />
                  </Tooltip>
                </IconButton>

                <DeleteClusterTaskDialog open={deleteDialogOpen}
                  data={task}
                  onClose={handleDeleteDialogClose}
                  onConfirm={(p) => handleDeleteDialogSubmit()} />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={8} xl={9}
            className="p-2">
            <Grid container spacing={3}>
              {(participants && participants.length > 0 ? participants : [EMPTY_PARTICIPANT]).map((participant, pIndex) => (
                <Grid item xs={12} key={pIndex}>
                  <div className="d-flex justify-content-between">
                    <ParticipantInlineEditV2 id={pIndex}
                      taskArn={taskArn}
                      defaultValue={participant}
                      disabled={editDisabled || (role === 'user' && !isMyClass)}
                      isEditing={editingParticipant === pIndex}
                      onOpen={handleInlineEditOpen(pIndex)}
                      onClose={handleInlineEditClose(pIndex)}
                      onSave={handleInlineEditSave(pIndex)} />
                    
                    {editingParticipant !== pIndex &&
                    (
                    <div className="d-flex justify-content-end align-items-center" style={{minWidth: 100}}>
                      
                      <IconButton size="small" aria-label="Edit participant"
                        disabled={editDisabled || (role === 'user' && !isMyClass)}
                        onClick={handleInlineEditOpen(pIndex)}>
                        <Tooltip title="Edit participant">
                          <EditIcon />
                        </Tooltip>
                      </IconButton>
                    
                      <IconButton size="small" aria-label="Move to empty instance"
                        disabled={(emptyTaskSet.has(taskArn) || !emptyTasks.length) || (role === 'user' && !isMyClass)}
                        onClick={handleMoveToEmptyInstanceClick}>
                        <Tooltip title="Move to empty instance">
                          <MergeTypeIcon />
                        </Tooltip>
                      </IconButton>
                    
                      <IconButton size="small" aria-label="Add participant"
                        disabled={(role === 'user' && !isMyClass)}
                        onClick={handleAddParticipantClick}>
                        <Tooltip title="Add participant">
                          <PersonAddIcon />
                        </Tooltip>
                      </IconButton>

                      {
                      /**
                       * This icon should be disabled
                       * while instance is provisioning or deleting or
                       * when signed in user's role is not admin or
                       * when participant is empty user
                       */
                      }
                      <IconButton size="small" aria-label="Remove participant"
                        disabled={editDisabled || (role === 'user' && !isMyClass) || isEmptyParticipant(participant)}
                        onClick={() => setDeleteParticipantDialogOpen(pIndex)}>
                        <Tooltip title="Remove participant">
                          <DeleteIcon />
                        </Tooltip>
                      </IconButton>

                      <AddParticipantDialog
                        open={addParticipantDialogOpen}
                        data={task}
                        onClose={(e) => handleAddParticipantDialogClose()}
                        onConfirm={(e) => handleAddParticipantDialogSubmit(e)} />

                      <DeleteParticipantDialog
                        open={deleteParticipantDialogOpen === pIndex}
                        data={task}
                        participant={participant}
                        onClose={(e) => handleDeleteParticipantDialogClose()}
                        onConfirm={(e) => handleDeleteParticipantDialogSubmit(e)}
                      />
                    
                    </div>
                    )}
                  </div>
                  
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export type CompactClusterTaskListProp = {
  cluster: TCluster;
  tasks: TTask[];
  queues: ClusterQueues; // from cluster state
  onTaskItemEditStart: (taskArn: string) => (participantIndex: number) => void;
  onTaskItemEditClose: (taskArn: string) => (participantIndex: number) => void;
}

const CompactClusterTaskList: React.FC<CompactClusterTaskListProp> = ({cluster, tasks, queues, onTaskItemEditStart, onTaskItemEditClose}) => {
  // const role = useSelector(userRoleSelector);
  return (
    // <div className="container-fluid">
      <Grid data-testid="cluster-task-list-container" container spacing={0} className="mt-3">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={5} lg={4} xl={3}>
              <Hidden mdUp>
                <Typography variant="body2"
                  className="text-uppercase d-inline-block px-2"
                  component="div"
                  style={{fontWeight: 500}}>
                  Instance&nbsp;&amp;&nbsp;Trainee
                </Typography>
                </Hidden>
                <Hidden smDown>
                  <div className="d-flex flex-row">
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="body2"
                          className="text-uppercase d-inline-block px-2"
                          component="div"
                          style={{fontWeight: 500}}>
                          Status
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Typography variant="body2"
                          className="text-uppercase d-inline-block px-2"
                          component="div"
                          style={{fontWeight: 500}}>
                          Instance
                        </Typography>
                      </Grid>
                    </Grid>
                    <div style={{minWidth: 80}} aria-disabled="true" />
                  </div>
                </Hidden>
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={8} xl={9}>
              <div className="d-flex flex-row">
                <Grid container spacing={0}>
                  <Hidden smDown lgUp>
                    <Grid item md={12}>
                      {/* <div className="px-2"> */}
                      <Typography variant="body2"
                        className="text-uppercase d-inline-block px-2"
                        component="div"
                        style={{fontWeight: 500}}>
                        Trainee
                      </Typography>
                    </Grid>
                  </Hidden>
                  <Hidden mdDown>
                    <Grid item lg={2} className="pl-3" style={{maxWidth: 80}}>
                      <Typography variant="body2"
                        className="text-uppercase d-inline-block px-2"
                        component="div"
                        style={{fontWeight: 500}}>
                        Title
                      </Typography>
                    </Grid>
                    <Grid item lg={5}>
                      <Typography variant="body2"
                        className="text-uppercase d-inline-block px-2"
                        component="div"
                        style={{fontWeight: 500}}>
                        Name
                      </Typography>
                    </Grid>
                    <Grid item lg={5}>
                      <Typography variant="body2"
                        className="text-uppercase d-inline-block px-2"
                        component="div"
                        style={{fontWeight: 500}}>
                        Email
                      </Typography>
                    </Grid>
                  </Hidden>
                </Grid>
                <div style={{minWidth: 100}} aria-disabled="true" />
              </div>
            </Grid>
          </Grid>
        </Grid>
        
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {tasks.map((task, tIndex) => <CompactClusterTaskCard
            cluster={cluster}
            task={task}
            queues={queues}
            key={tIndex+task.taskArn}
            onTaskItemEditStart={onTaskItemEditStart}
            onTaskItemEditClose={onTaskItemEditClose}
            />)}
        </Grid>
        
      </Grid>
    // </div>

  )
}

export default CompactClusterTaskList;
