import React from 'react';
import { Play } from "react-feather";
import Presence from "../../presence/Presence";

const StatusWrapper: React.FC<any> = ({lastStatus, health, children}) => (
  <div className={`status-${lastStatus.toLowerCase()} ${lastStatus === "RUNNING" ? health : ""}`}>
    <div className="d-flex justify-content-start">
      {children}
    </div>
  </div>
)

const StatusLabel: React.FC<any> = ({showLabel, children}) => (
  <span className="text-uppercase ml-2" style={{
    fontSize: '12px',
    display: showLabel ? 'inline-block' : 'none'
  }}>
    {children}
  </span>
)

const InstanceStatus: React.FC<{
  lastStatus: string;
  health: string;
  createdAt: string;
  stoppedReason: string;
  showLabel: boolean;
}> = ({lastStatus, health, createdAt, stoppedReason, showLabel}) => {
  // https://docs.aws.amazon.com/AmazonECS/latest/developerguide/task-lifecycle.html
  if(lastStatus === "RUNNING") {
    switch(health) {
      case "HEALTHY":
        // Even when client triggers delete task API it would take a while for lastStatus updates to Deactivating
        // However once stop has called from client stoppedReason === "invoked by delete-ecs-task"
        // actually means that Deactivation Invoked we will display as Deactivating
        if (stoppedReason === "invoked by delete-ecs-task") {
          return (
            <StatusWrapper lastStatus={lastStatus} health={health}>
              <div style={{width: '16px'}}>
                <Presence presence="focus" color="#FF5630" />
              </div>
              <StatusLabel showLabel={showLabel}>
                Deactivating
              </StatusLabel>
            </StatusWrapper>
          );
        }
        return (
          <StatusWrapper lastStatus={lastStatus} health={health}>
            <div style={{width: '16px'}}>
              <Presence presence="online" />
            </div>
            <StatusLabel showLabel={showLabel}>Active</StatusLabel>
          </StatusWrapper>
        );

      case "UNHEALTHY": {
        const initializing = (Date.now() - Date.parse(createdAt)) < 1200000;
        return (
          <StatusWrapper lastStatus={lastStatus} health={health}>
            <div style={{width: '16px'}}>
              <Presence presence={initializing ? 'focus' : 'busy'} />
            </div>
            <StatusLabel showLabel={showLabel}>
              {initializing ? 'Initializing' : 'Unhealthy'}
            </StatusLabel>
          </StatusWrapper>
        )
      }
      case "UNKNOWN":
        return (
          <StatusWrapper lastStatus={lastStatus} health={health}>
            <div style={{width: '16px'}}>
              <Presence presence='focus' />
            </div>
            <StatusLabel showLabel={showLabel}>
              Initializing
            </StatusLabel>
          </StatusWrapper>
        )
      default:
        return (
          <StatusWrapper lastStatus={lastStatus} health={health}>
            <div style={{width: '16px'}}>
              <Presence presence='busy' />
            </div>
            <StatusLabel showLabel={showLabel}>
              {health}
            </StatusLabel>
          </StatusWrapper>
        )
    }
  } else if(lastStatus === "PROVISIONING") {
    return (
      <StatusWrapper lastStatus={lastStatus} health={health}>
        <Play size={16} />
        <StatusLabel showLabel={showLabel}>
        Starting
        </StatusLabel>
      </StatusWrapper>
    )
  } else if(lastStatus === "DEACTIVATING") {
    return (
      <StatusWrapper lastStatus={lastStatus} health={health}>
        <div style={{width: '16px'}}>
          <Presence presence="focus" color="#FF5630" />
        </div>
        <StatusLabel showLabel={showLabel}>
          Deactivating
        </StatusLabel>
      </StatusWrapper>
    )
  } else if(lastStatus === "STOPPED") {
    return (
      <StatusWrapper lastStatus={lastStatus} health={health}>
        <div style={{width: '16px'}}>
          <Presence presence="offline" />
        </div>
        <StatusLabel showLabel={showLabel}>
          Inactive
        </StatusLabel>
      </StatusWrapper>
    )
  }



  return (
    <StatusWrapper lastStatus={lastStatus} health={health}>
      <div style={{width: '16px'}}>
        <Presence presence="offline" />
      </div>
      <StatusLabel showLabel={showLabel}>
        {lastStatus}
      </StatusLabel>
    </StatusWrapper>
  )
}

export default InstanceStatus;
