import { useAppDispatch } from '../../../store/app.store';
import { debounce } from 'lodash';
import { setSearchInput } from '../clusters.slice';
import SearchInput from '../../search-input/SearchInput';

const ClusterSearch = () => {
  const dispatch = useAppDispatch();
  const handleOnChange = (value: any) => {
    dispatch(setSearchInput(value))
  }
  return (
    <SearchInput data-testid="cluster-search-input" id="cluster-search"
      className="mb-3 w-100"
      placeholder="Search"
      onCancelSearch={() => handleOnChange("")}
      onChange={debounce(handleOnChange, 500)} />
  )
}

export default ClusterSearch;
