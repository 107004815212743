import React, { useState, useEffect } from "react";
import { Paper, Snackbar, Typography } from "@material-ui/core"
import { Alert } from "react-bootstrap"
import { useSelector } from "react-redux";
import {
  NOTIFICATION_DURATION,
  STATUS_VARIANT,
  STATUS_VARIANT_META,
  STATUS_ICON_SIZE
} from "../../constants/app.constants";
import { RootState, useAppDispatch } from "../../store/app.store";
import { clearNotifications, removeNotification, TNotification } from "./notification.slice";
import { CheckCircle, AlertTriangle, AlertOctagon, Info, HelpCircle } from 'react-feather';
import { StatusVariant } from "../../types/status.types";

/* istanbul ignore file */

const StatusIcon: React.FC<{variant: string}> = ({ variant }) => {
  const size = STATUS_ICON_SIZE.NOTIFICATION;
  const iconColor = STATUS_VARIANT_META[variant] && STATUS_VARIANT_META[variant].iconColor;
  // console.log('StatusIcon', variant)
  switch(variant) {
    case STATUS_VARIANT.CONFIRMATION:
      return <CheckCircle size={size} color={iconColor} />;
    case STATUS_VARIANT.ERROR:
    case StatusVariant.Errors:
      return <AlertOctagon size={size} color={iconColor} />;
    case STATUS_VARIANT.WARNING:
    case StatusVariant.Warnings:
      return <AlertTriangle size={size} color={iconColor} />;
    case STATUS_VARIANT.CHANGE:
      return <HelpCircle size={size} color={iconColor} />
    case STATUS_VARIANT.INFO:
    case StatusVariant.Info:
    default:
      return <Info size={size} color={iconColor} />;
  }
}

const Notification: React.FC<{
  messages: TNotification[];
}> = ({messages}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [meta, setMeta] = useState<TNotification|undefined>(undefined);

  const dispatch = useAppDispatch();
  const handleClose = (event: any, reason: string) => {
    // if (reason === 'clickaway') {
    //   return;
    // }
    setOpen(false);
    dispatch(clearNotifications());
  };
  const handleExited = () => {
    setMeta(undefined);
  };

  useEffect(() => {
    if (messages.length && !meta) {
      // Set a new snack when we don't have an active one
      setMeta(messages[0]);
      dispatch(removeNotification())
      setOpen(true);
    } else if (messages.length && meta && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [messages, meta, open]);

  // console.log('Notification');
  return (
    <Snackbar open={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={NOTIFICATION_DURATION}
      onClose={handleClose}
      TransitionProps={{
        onExited: handleExited
      }}>
      <Paper elevation={5} className="mt-5">
        <Alert onClose={handleClose}
          variant={meta?.status}
          className="m-0 d-flex justify-content-start align-items-start">
          <StatusIcon variant={meta?.status as string} />
          <div className="pl-2 d-flex flex-column align-items-start">
            <div dangerouslySetInnerHTML={{__html: meta?.message || ""}} />
            {meta?.timestamp ? <Typography variant="caption">
              logged at: {new Date(meta.timestamp).toLocaleString("en-US", {timeZoneName: "short"})}
            </Typography> : ''}
          </div>
          
        </Alert>
      </Paper>
    </Snackbar>
  )
}

const GlobalNotification = () => {
  const { messages } = useSelector(
    (state: RootState) => state.notification
  );

  return (
    <>
      <Notification messages={messages} />
      {/* <Notification messages={errors} variant="errors" />
      <Notification messages={warnings} variant="warning" />
      <Notification messages={info} variant="info" /> */}
    </>
  )
}

export default GlobalNotification;
