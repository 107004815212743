import React, { useEffect, useRef } from 'react';
import Paper from '@material-ui/core/Paper';

type TPageSize = { width: number; height: number; };

const PAGE_SIZES: Record<string, TPageSize> = {
  letter: { width: 216, height: 279 },
};

function getPageSize(type: string|undefined) {
  return type ? (PAGE_SIZES[type] || PAGE_SIZES['letter']) : PAGE_SIZES['letter'];
}

/**
 * This hook will allow us to print PDF of and element, use it along with the PrintablePage component below
 */
/* istanbul ignore next */
export function usePrintElement(
  printRef: React.MutableRefObject<HTMLDivElement|null>,
  pageTitle: string,
  printOnMount?: boolean
) {
  const timeoutFn = useRef<NodeJS.Timeout|null>(null);
  function printElement (){
    if (window) {
      const a = window.open('', '_blank', `height=${window.innerHeight}, width=${window.innerWidth}`);
      if (a && printRef.current) {
        a.document.write(`<html><head>
        <title>${pageTitle}.pdf</title>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css" integrity="sha384-TX8t27EcRE3e/ihU7zmQxVncDAy5uIKz4rEkgIXeMed4M0jlfIDPvg6uqKI2xXr2" crossorigin="anonymous" /></head><body>`);
        a.document.write(printRef.current.innerHTML);
        a.document.write('</body></html>');
        a.document.close();
        // afterprint Event doesn't work with used to not work in Safari
        // https://caniuse.com/beforeafterprint
        // a.addEventListener('afterprint', onAfterPrint);
        timeoutFn.current = setTimeout(() => {a.print()}, 500);
        a.onfocus = () => {
          setTimeout(() => { a.close(); }, 500);
        }
      }
    }
  }
  useEffect(() => {
    if (printOnMount) printElement();
    return () => {
      if (timeoutFn.current) clearTimeout(timeoutFn.current);
    }
  }, []);

  return printElement;
}

export type PrintablePageProp = {
  children: React.ReactNode;
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
  singleMode?: boolean;
  id?: string;
  type?: string;
  printOnMount?: boolean;
  hide?: boolean;
  pageTitle?: string;
}

// https://www.geeksforgeeks.org/print-the-contents-of-a-div-element-using-javascript/
const PrintablePage: React.FC<PrintablePageProp> = ({
  children, pageRef, singleMode, id, type, printOnMount, hide, pageTitle = "print pdf"
}) => {
  const printRef = useRef<HTMLDivElement|null>(null);
  const mmSize = getPageSize(type); // millimeters
  usePrintElement(pageRef||printRef, pageTitle, printOnMount)

  return (
    <div data-testid="printable-page-wrapper" style={{
      display: hide ? 'none' : 'flex',
      background: '#F7F7F7',
      justifyContent: 'center',
      padding: '2rem',
      minHeight: 'calc(100vh - 49px)'
    }}>
      <Paper elevation={3} square>
        <div id={id}
          ref={pageRef||printRef}
          style={{
            width: `${mmSize.width}mm`,
            height: singleMode ? `${mmSize.height}mm` : "",
            minHeight: `${mmSize.height}mm`,
          }}
        >
          <div className="my-2 mx-4 py-2 px-2">
            {children}
          </div>
        </div>
      </Paper>
    </div>
  )
};

export default PrintablePage;
