import API from "@aws-amplify/api";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { APIError } from "../types/common.types";
import API_MODULE from '../api';

export const getVersion = createAsyncThunk<any, void, { rejectValue: APIError }>(
  'version/get',
  async (arg, thunkAPI) => {
    let version: string|null = null;
    try {
      version = await API_MODULE.getVersion();
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'getVersion',
        message: "Failed to obtain version."
      })
    }
    return version;
  }
)

export type VersionState = {
  loading: boolean;
  value: string|null;
}

export const versionInitialState: VersionState = {
  loading: false,
  value: null
};

const versionSlice = createSlice({
  name: 'version',
  initialState: versionInitialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getVersion.pending, (state, action) => {
      state.loading = true;
    })
    builder.addCase(getVersion.fulfilled, (state, action) => {
      state.loading = false;
      state.value = action.payload;
    })
  }
})

export default versionSlice.reducer;
