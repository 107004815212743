import React, { useEffect, useRef, useState } from "react";
import { TParticipant, TTask, TTaskMetric } from "../../../types/app.types";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from "@material-ui/core";
import InstanceStatus from "../list/InstanceStatusIcon";
import {stableSort,getComparator} from '../../../utils/sort';
import { useSelector } from "react-redux";
import { RootState } from "../../../store/app.store";
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { parseTaskId } from "../clusters.actions";

export const EMPTY_PARTICIPANT: TParticipant = { firstName: "", lastName: "", email: "", title: "" };
const labelStyle = {
  fontSize: '12px',
  fontWeight: 600,
};

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRow: {
    height: 'fit-content',
    // '&:first-child': {
    //   paddingLeft: 10,
    // }
  },
  tableCell: {
    verticalAlign: 'middle !important',
    '&:first-child': {
      paddingLeft: 15,
    }
  },
  headerCell: {
    fontSize: 12,
    textTransform: 'uppercase',
    '&:first-child': {
      paddingLeft: 15,
    }
  }
}));

function formatSizeUnits(bytes: number){
  if (bytes >= 1073741824)
    return (bytes / 1073741824).toFixed(2) + " GB";
  else if (bytes >= 1048576)
    return (bytes / 1048576).toFixed(2) + " MB";
  else if (bytes >= 1024)
    return (bytes / 1024).toFixed(2) + " KB";
  else if (bytes > 1)
    return bytes + " bytes";
  else if (bytes === 1)
    return bytes + " byte";
  else
    return "0 bytes";
}

function LinearProgressWithLabel(props: any) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}


type Column = {
  field: string;
  label: string;
  style?: {
    width?: number|string;
    minWidth?: number;
    maxWidth?: number;
    [key: string]: any;
  };
}
const columns: Column[] = [
  { field: 'lastStatus', label: 'Status', style: {...labelStyle} },
  { field: 'publicIPv4Address', label: 'Address', style: {...labelStyle} },
  { field: 'cpu', label: 'CPU (%)', style: {...labelStyle} },
  { field: 'memoryUsage', label: 'Memory (%)', style: { ...labelStyle } },
  { field: 'storageReadBytes', label: 'Disk Read', style: { ...labelStyle } },
  { field: 'storageWriteBytes', label: 'Disk Write', style: { ...labelStyle } },
];


function getTaskMetric(taskArn: string, metrics: Record<string, TTaskMetric>): TTaskMetric {
  const taskID = parseTaskId(taskArn);
  return metrics[taskID] || {
    taskID: null,
    timestamp: null,
    cpu: 0,
    memoryUsage: 0,
    storageReadBytes: 0,
    storageWriteBytes: 0,
  }
}

export type TaskListProp = {
  formik: any;
  taskMetric: Record<string, TTaskMetric>;
}

/** This table focus only on handling task metric in list on detail page */
export const TaskList: React.FC<any> = ({formik, taskMetric}) => {
  const [order, setOrder] = useState<'asc'|'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof TTask>('publicIPv4Address');
  const tbodyRef = useRef<HTMLTableSectionElement>(null);
  const triggerRef = useRef<string|null>(null);
  const classes = useStyles();

  const taskIds = useSelector((state: RootState) => {
    return state.clusters.entities.byClusterName?.[formik.values.clusterName]?.taskIds || {
      ids: [],
      byIds: {},
      byArn: {}
    }
  });

  const handleRequestSort = (event: any, property: keyof TTask) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: keyof TTask) => (event: any) => {
    if (handleRequestSort) handleRequestSort(event, property);
  };

  return (
    <Table data-testid="task-metric-list" className="table" size="small" >
      <TableHead>
        <TableRow>
          {columns.map((column,i) => (
          <TableCell
            data-testid={`task-metric-list-header-cell-${i}`}
            key={column.field}
            align={'left'}
            className={'p-1 '+ classes.headerCell}
            sortDirection={orderBy === column.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === column.field}
              direction={orderBy === column.field ? order : 'asc'}
              onClick={createSortHandler(column.field as keyof TTask)}
            >
              <span className="text-uppercase">{column.label}</span>
              {orderBy === column.field ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody ref={tbodyRef}>
        {formik.values.tasks && formik.values.tasks.length ? stableSort<TTask>(formik.values.tasks, getComparator(order, orderBy))
        .map(({
          publicIPv4Address, taskArn, stoppedReason, lastStatus, health, createdAt,
        }, taskIndex: number) =>
        <TableRow key={taskArn}>
          <TableCell className="p-1">
            <div className="d-flex justify-content-start">
              <InstanceStatus showLabel
                stoppedReason={stoppedReason}
                lastStatus={lastStatus}
                health={health}
                createdAt={createdAt} />
            </div>
          </TableCell>
          <TableCell className="p-1">
            {publicIPv4Address && publicIPv4Address !== '-'
            ? <a href={"http://"+publicIPv4Address+":8080"}
                target="_blank"
                rel="noreferrer">
                http://{publicIPv4Address}:8080
              </a>
            : publicIPv4Address}
          </TableCell>
          
          <TableCell className="p-1">
            <div className="w-100">
              <LinearProgressWithLabel id={taskArn+"-cpu"}
                value={(getTaskMetric(taskArn, taskMetric).cpu || 0) * 100}
                color={(getTaskMetric(taskArn, taskMetric).cpu || 0) * 100 > 80 ? 'secondary' : 'primary'} />
            </div>
          </TableCell>
          <TableCell className="p-1">
            <div className="w-100">
            <LinearProgressWithLabel id={taskArn+"-memory"}
              value={(getTaskMetric(taskArn, taskMetric).memoryUsage || 0) * 100}
              color={(getTaskMetric(taskArn, taskMetric).memoryUsage || 0) * 100 > 70 ? 'secondary' : 'primary'} />
            </div>
          </TableCell>
          <TableCell className="p-1">
            <div className="w-100">
              {formatSizeUnits(getTaskMetric(taskArn, taskMetric).storageReadBytes || 0)}
            </div>
          </TableCell>
          <TableCell className="p-1">
            <div className="w-100">
              {formatSizeUnits(getTaskMetric(taskArn, taskMetric).storageWriteBytes || 0)}
            </div>
          </TableCell>
        </TableRow>
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <div className="d-flex justify-content-center align-items-center" style={{minHeight: '100px'}}>
                There are no instances launched yet.
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}
export default TaskList;
