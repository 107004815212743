import { combineEpics, Epic, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { pushNotification, clearNotifications } from '../components/notification/notification.slice';
import { StatusVariant } from '../types/status.types';

/**
 * Global notification handler
 */
export const pushNotificationEpic: Epic = combineEpics(
  // Handles Success Notifications
  action$ => action$.pipe(
    ofType(
      'clusters/loadClusters/fulfilled',
    ),
    map(({payload, meta}) => {
      return clearNotifications();
    })
  ),
  // Handles Success Notifications
  action$ => action$.pipe(
    ofType(
      /** Clusters */
      'clusters/addCluster/fulfilled',
      'clusters/addClusterTask/fulfilled',
      'clusters/stopClusterTask/fulfilled',
      'clusters/removeCluster/fulfilled',
      'clusters/updateClusterTask/fulfilled',
      'clusters/updateCluster/fulfilled',
      'clusters/restartClusterTask/fulfilled',

      /** Users */
      'users/addUser/fulfilled',
      'users/updateUser/fulfilled',
      'users/removeUser/fulfilled',
      'users/resetPassword/fulfilled',
      'users/resendEmail/fulfilled',
    ),
    map(({payload, meta}) => {
      const {silent} = meta.arg;
      if (silent) return { type: 'noop' };
      return pushNotification({
        status: StatusVariant.Success,
        message: payload.message
      })
    })
  ),
  // Handles Failed Notifications
  action$ => action$.pipe(
    ofType(
      /** Clusters */
      'clusters/addClusterTask/rejected',
      'clusters/stopClusterTask/rejected',
      'clusters/removeCluster/rejected',
      'clusters/updateClusterTask/rejected',
      'clusters/updateCluster/rejected',
      'clusters/restartClusterTask/rejected',

      /** Users */
      'users/addUser/rejected',
      'users/updateUser/rejected',
      'users/removeUser/rejected',
      'users/resetPassword/rejected',
      'users/resendEmail/rejected',
    ),
    map(({payload, meta, error}) => {
      return pushNotification({
        status: StatusVariant.Errors,
        message: error.message
      })
    })
  )
)
