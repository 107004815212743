/* istanbul ignore file */
import { useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import {
  Link,
  useHistory,
  // BrowserRouter as Router,
  // Switch,
  // Route,
  // Link,
  useParams
} from "react-router-dom";
import { useFormik } from 'formik';
import { MOCK_CLUSTERS_DATA } from "../../components/clusters/clusters.mock";
import Typography from '@material-ui/core/Typography';
import store, { useAppDispatch, RootState } from "../../store/app.store";
import format from "date-fns/format";
import formatDistance from 'date-fns/formatDistance'
import { TUser, TClusterTaskDefinition, TParticipant, TTask, TParticipantAttendanceRow, TCluster, TClusterForm } from "../../types/app.types";
import { Breadcrumbs, Button, IconButton, List, ListItem, makeStyles, Paper } from "@material-ui/core";

import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
// import IconButton from '@material-ui/core/IconButton';
// import Accordion from '@material-ui/core/Accordion';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ParticipantAttendanceList from "../../components/clusters/detail/ParticipantAttendanceList";
// import { MoreHoriz, HowToReg } from "@material-ui/icons";
import differenceInDays from 'date-fns/differenceInDays';
// import { AttendanceStatus, AttendanceStatusType } from "../../types/status.types";
import TaskList from "../../components/clusters/detail/TaskList";
import { getSharedTaskDefinition, getParticipants } from "../../components/clusters/useClusterShare";
import { addCluster, updateCluster } from "../../components/clusters/clusters.actions";
import { clusterInitialState, startPollingTasks, stopPollingTasks, startPollingMetric, stopPollingMetric } from '../../components/clusters/clusters.slice';
import { useEffect, useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import EditClassDateDialog, {EditClassDateDialogPayload} from "../../components/clusters/dialog/EditClassDateDialog";
import { groupedTasksListOptionSelector, userRoleSelector } from "../../store/selectors";


type ClassDetailPageRouteParameter = {
  clusterName: string;
}


const MOCK_DETAIL_DATA = {
  [MOCK_CLUSTERS_DATA[0].clusterName]: {
    data: MOCK_CLUSTERS_DATA[0],
    statistics: {
      total: 10,
      assigned: 5,
      unassigned: 5,
      healthy: 6,
      unhealthy: 4,
      provisioningTasks: 2,
      runningTasks: 8
    }
  }
};

function DisplayStats(props: any) {
  const { statistics, classes } = props;
  const { total, assigned, unassigned, healthy, unhealthy, provisioningTasks, runningTasks } = statistics;
  // console.log(statistics)
  return (
  <div className="mt-3">
    <Chip avatar={<Avatar className={classes.avatar}>{total}</Avatar>}
      size="small"
      label="Total Instances"
      variant="outlined"
      className={classes.chip} />
    <Chip avatar={<Avatar className={classes.avatar}>{assigned}</Avatar>}
      size="small"
      label="Assigned"
      variant="outlined"
      className={classes.chip}
    />
    <Chip avatar={<Avatar className={classes.avatar}>{unassigned}</Avatar>}
      size="small"
      label="Unassigned"
      variant="outlined"
      className={classes.chip}
    />


    <Chip avatar={<Avatar className={classes.avatar}>{healthy}</Avatar>}
      size="small"
      label="Healthy"
      variant="outlined"
      className={classes.chip}
    />
    <Chip avatar={<Avatar className={classes.avatar}>{unhealthy}</Avatar>}
      size="small"
      label="Unhealthy"
      variant="outlined"
      className={classes.chip}
    />
    <Chip avatar={<Avatar className={classes.avatar}>{provisioningTasks}</Avatar>}
      size="small"
      label="Pending"
      variant="outlined"
      className={classes.chip}
    />
  </div>
  )
}

function TrainerViewer(props: { label: string, user: TUser }) {
  const { user, label } = props;
  return (
    <div className="d-flex flex-column flex-md-row flex-lg-column mb-3">
      <Typography variant="caption" component="div" className="mb-1"
        style={{fontWeight: 500, minWidth: '100px'}}>
        {label}
      </Typography>
      <div className="d-flex flex-column mb-3">
        {(user?.firstName || user?.lastName) &&
        <Typography variant="body1" component="div">
          {user?.firstName} {user?.lastName}
        </Typography>
        }
        <Typography variant="body2" component="div">
          {user?.email}
        </Typography>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    color: `${theme.palette.grey[900]} !important`,
    backgroundColor: 'transparent',
    fontSize: '12px !important',
    fontWeight: 600,
    marginLeft: '4px !important'
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 500,
  },
  header: {
    position: 'relative',
    backgroundColor: 'white',
  },
  headerShadow: {
    opacity: 0.6,
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '10px',
    background: "linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0) 50%), linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0) 30%)"
  }
}));


const classDetailFormDefaultValue = {
  startDate: "",
  endDate: "",
  clusterName: "",
  className: "",
  trainer: null,
  coTrainer: null,
  support: null,
  createdAt: null,
  taskDefinition: null,
  tasks: [],
  participants: []
};

const classDays = (startDate: string, endDate: string) => {
  if (endDate && startDate && endDate !== "" && startDate !== "")
    return differenceInDays(new Date(endDate), new Date(startDate)) + 1;
  return 0;
};

const getAttendances = (participant: TParticipant, days: number) => (
  participant.attendances && participant.attendances.length
  ? participant.attendances
  : days ? new Array(days).fill(false) : []
);

// these are assigned participants
const getParticipantsTableRows = (tasks: TTask[], days: number) => tasks.reduce((o: TParticipant[], task: TTask) => ([
  ...o,
  ...(task.participants || []).map<any>(participant => ({
    ...participant,
    taskArn: task.taskArn,
    attendances: getAttendances(participant, days),
  }))
]), [] as TParticipantAttendanceRow[]).map((d,index)=>({...d, index}))

/**
 * Available Features
 * - View detail class info
 * - Check attendance
 * -
 * https://aws.amazon.com/blogs/mt/introducing-container-insights-for-amazon-ecs/
 */
const ClassDetailPage = () => {
  const { clusterName } = useParams<ClassDetailPageRouteParameter>();
  const classes = useStyles();
  const dispatch:  Dispatch<any> = useAppDispatch();
  const history = useHistory();
  const taskDefinitions = useSelector(groupedTasksListOptionSelector);
  const { data, statistics, taskMetric, canEditDate } = useSelector((state: RootState) => state.clusters.entities.byClusterName[clusterName] || clusterInitialState);
  const [disableShared, setDisableShared] = useState(
    data && data.taskDefinition && new Set(taskDefinitions.sharedDisabled).has(data.taskDefinition)
  );

  const startPolling = () => {
    // console.log('!!!!!!!!!!!!!!!!!!!startPolling', store.getState(),store.getState().clusters.pollingQueues[clusterName])
    if (!store.getState().clusters.pollingQueues[clusterName]) {
      dispatch(startPollingTasks({clusterName}));
      dispatch(startPollingMetric({clusterName, tasks: data.tasks || []}))
    }

    
  }
  const stopPolling = () => {
    dispatch(stopPollingTasks({clusterName}));
    dispatch(stopPollingMetric());
  }

  const [openDateDialog, setOpenDateDialog] = useState(false);
  
  const role = useSelector(userRoleSelector);
  
  // console.log(classDays())
  const formik = useFormik<any>({
    initialValues: data ? {
      ...data,
      participants: getParticipantsTableRows(
        data.tasks,
        classDays(data.startDate, data.endDate)
      ),
      launchTimestamp: data.launchTimestamp || ""
    } : classDetailFormDefaultValue,
    // validate,
    // validateOnChange
    onSubmit: (values: any) => {
      // if (props.onSubmit) props.onSubmit(values);
      // console.log(values);
    },
  });

  const handleEditDateClick = () => {
    setOpenDateDialog(true);
  }

  const handleEditDateClose = () => {
    setOpenDateDialog(false);
  }

  const handleEditDateConfirm = (val: EditClassDateDialogPayload) => {
    // console.log('handleEditDateConfirm', val);
    const { startDate, endDate, launchOnStart, launchTimestamp, scheduleTermination } = val;
    const { trainer, coTrainer, support } = data;
    dispatch(updateCluster({
      ...data,
      trainer: {
        username: (trainer?.username || "")
      },
      coTrainer: {
        username: (coTrainer?.username || "")
      },
      support: {
        username: (support?.username || "")
      },
      startDate, endDate,
      scheduleTermination,
      launchTimestamp: (
        launchOnStart
          ? format(new Date(), "yyyy-MM-dd'T'HH:mm")
          : launchTimestamp
        )
    }))
  }

  const handleSharedClick = () => {
    const {className, trainer, coTrainer, support, tasks, taskDefinition, startDate, endDate} = data;
    dispatch(addCluster({
      className: `Copy of ${className}`,
      trainer: trainer?.username || "",
      coTrainer: coTrainer?.username || "",
      support: support?.username || "",
      taskCount: 1,
      taskDefinition: getSharedTaskDefinition(taskDefinition as TClusterTaskDefinition),
      participants: getParticipants(tasks),
      startDate,
      endDate,
      initiatingUser: trainer?.username || "",
      // just launch it now
      launchTimestamp: format(new Date(), "yyyy-MM-dd'T'HH:mm") //startDate
    }));
    setTimeout(()=> {
      history.push('/list-tasks')
    }, 3000)
  }

  useEffect(() => {
    // console.log('data change', data)
    formik.setFieldValue('tasks', data.tasks);
    formik.setFieldValue('participants', getParticipantsTableRows(
      data.tasks,
      classDays(data.startDate, data.endDate)
    ));
  }, [data.tasks])

  useEffect(() => {
    // console.log('data change', data)
    formik.setFieldValue('startDate', data.startDate);
    formik.setFieldValue('endDate', data.endDate);
  }, [data.startDate, data.endDate])

  useEffect(() => {

    startPolling();
    // startPollingMetric, startPollingMetric, stopPollingMetric();
    return () => {
      stopPolling();
    }
  }, []);
  return (
    <>
      <div className={classes.header}>
        <div className="container">
          {/* <div className="row">
            
          </div> */}
          <div className="row pt-3 pb-5">
            <div className="col-12">
              <Breadcrumbs aria-label="breadcrumb">
                {/* <Link color="inherit" href="/">
                  Home
                </Link> */}
                {/* <Link color="inherit" href="/#/list-tasks">
                  Classes
                </Link> */}
                <Link to="/list-tasks" className="text-decoration-none text-muted">
                  Classes
                </Link>
                <Typography color="textPrimary">Detail</Typography>
              </Breadcrumbs>
            </div>
            <div className="col-12 col-md-10 col-lg-8 pt-3">
              <div className="mb-md-1 mb-lg-3">
                <div className="d-block mr-2">
                  <Typography variant="subtitle1" component="div" className="d-inline-block">
                    {formik.values.startDate && format(new Date(formik.values.startDate), 'EEEE, MMMM d, yyyy hh:mm aa') || 'Start date not available'}
                  </Typography>
                  <Typography variant="subtitle1" component="div" className="d-inline-block mx-2">
                    -
                  </Typography>
                  <Typography variant="subtitle1" component="div" className="d-inline-block mr-2">
                    {formik.values.endDate && format(new Date(formik.values.endDate), 'EEEE, MMMM d, yyyy hh:mm aa') || 'End date not available'}
                  </Typography>
                  <IconButton size="small" onClick={handleEditDateClick} disabled={!canEditDate || role === 'user'}>
                    <EditIcon />
                  </IconButton>
                  {data.clusterName !== ""
                  ? <EditClassDateDialog open={openDateDialog}
                    data={data}
                    canEditDate={canEditDate}
                    onClose={handleEditDateClose}
                    onConfirm={handleEditDateConfirm}/>
                  : <></>}
                  
                </div>

                <Typography variant="caption" component="div">
                  <span style={{fontWeight: 500}}>{data.launched ? 'Launched at: ' : 'Launch at: '}</span>
                  <span>
                  {formik.values.launchTimestamp && formik.values.launchTimestamp !== "" ? format(new Date(formik.values.launchTimestamp), 'MM/dd/yyyy hh:mm aa') : formik.values.launchTimestamp}
                  </span>
                  
                </Typography>
                
                <Typography variant="h4" component="h1" className="d-block mr-2">
                  {formik.values.className}
                </Typography>
                <Typography variant="caption" component="div" className="d-block mr-2">
                  for {classDays(formik.values.startDate, formik.values.endDate)} day(s)
                </Typography>
              </div>

              <div className="row mt-3">
                <div className="col-12 col-lg-4">
                  {formik.values.trainer && <TrainerViewer label="Trainer" user={formik.values.trainer} />}
                </div>
                <div className="col-12 col-lg-4">
                  {formik.values.coTrainer && <TrainerViewer label="Co Trainer" user={formik.values.coTrainer} />}
                </div>
                <div className="col-12 col-lg-4">
                  {formik.values.support && <TrainerViewer label="Support" user={formik.values.support} />}
                </div>
              </div>
              

            </div>
            <div className="col-12 col-md-2 col-lg-4">
              <div className="w-100 mt-md-2 mt-lg-3 d-flex justify-content-end">
                {!disableShared && !(formik.values.taskDefinition || "").endsWith('-shared') && 
                  <Button variant="text" onClick={handleSharedClick} aria-label={"Create shared instance"} disabled={role === 'user'}>
                    Create shared instance
                  </Button>
                }
              </div>
              <DisplayStats classes={classes} statistics={statistics}/>
              
            </div>
          </div>
        </div>
        <div aria-disabled className={classes.headerShadow} />
      </div>

      <Paper className="container" elevation={3} style={{transform: 'translateY(-40px)'}}>
        
        <div className="row pt-3">
          <div className="col-12">
            <div className="d-flex justify-content-between">
              <div>
              <Typography variant="h5" component="h2" className="mb-2">
                Attendees
                <Typography variant="subtitle1" component="span" className="ml-2">
                  ({formik.values.participants.length})
                </Typography>
              </Typography>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="table-responsive-lg">
              {<ParticipantAttendanceList formik={formik} days={classDays(data.startDate, data.endDate)} />}
            </div>
            {/* <div>
              <pre className="w-50 d-inline-block">
                {formik.values.participants ? JSON.stringify(formik.values.participants, null, "\t") : ""}
              </pre>
              <pre className="w-50 d-inline-block">
                {formik.values.tasks ? JSON.stringify(formik.values.tasks, null, "\t") : ""}
              </pre>
            </div> */}
          </div>
        </div>
      </Paper>
      
      <Paper className="container" elevation={3}>
        <div className="row pt-3">
          <div className="col-12">
            <div className="d-flex justify-content-between">
              <div>
              <Typography variant="h5" component="h2" className="mb-2">
                Instances
                <Typography variant="subtitle1" component="span" className="ml-2">
                  ({formik.values.tasks.length})
                </Typography>
              </Typography>
              <Typography variant="caption" component="div">
                Task Definition: {formik.values.taskDefinition}
              </Typography>
              </div>
            </div>
          </div>
          <div className="col-12">
            <TaskList formik={formik} taskMetric={taskMetric} />
          </div>
        </div>
        
      </Paper>
      {/* <div className="container">
        
      </div> */}
    </>
  )
}

export default ClassDetailPage;
