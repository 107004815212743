import { combineEpics, Epic, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { connect, send } from '@giantmachines/redux-websocket';
import { startPollingClasses } from '../components/clusters/clusters.slice';

export const websocketEpic: Epic = combineEpics(
  // On Connection Open handler
  // action$ => action$.pipe(
  //   ofType(
  //     "REDUX_WEBSOCKET::OPEN"
  //   ),
  //   map(({payload, meta}) => {
  //       // return send({action: 'refreshClasses'});
  //     // return startPollingClasses()
  //   })
  // ),
  action$ => action$.pipe(
    ofType(
      "REDUX_WEBSOCKET::MESSAGE"
    ),
    map(({payload, meta}) => {
      const message: string = payload.message;
      const messageObj = JSON.parse(message);
      if (messageObj.type === "refreshClasses") {
        return {
          type: 'clusters/loadClusters/fulfilled',
          payload: messageObj.clusters
        }
      } else if (messageObj.type === "refreshClass") {
        return {
          type: 'clusters/loadClusterTasks/fulfilled',
          payload: {
            clusterName: messageObj.clusters[0].clusterName,
            tasks: messageObj.clusters[0].tasks || []
          },
          meta: {
            arg: messageObj.clusters[0],
            requestId: "",
            requestStatus: 'fulfilled',
          }
        }
      }  else if (messageObj.type === "getMetrics") {
        return {
          type: 'clusters/setTaskMetric',
          payload: {
            clusterName: messageObj.metrics.clusterName,
            metric: messageObj.metrics.taskTelemetry
          },
          meta: {
            arg: messageObj.metrics.clusterName,
            requestId: "",
            requestStatus: 'fulfilled',
          }
        }
      } else {
        return { type: 'noop' }
      }
      // return send({action: 'refreshClasses'});
    })
  ),
)
