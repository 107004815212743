import React from "react";
import Chip from '@material-ui/core/Chip';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import TaskDefinitionsAutoComplete from "./TaskDefinitionsAutoComplete";

// type TClusterFilterOption = {
// 	label: string;
// 	value: string|null
// }

// const FILTER_OPTIONS: TClusterFilterOption[] = [
// 	{ label: 'Regular', value: 'regular'},
// 	{ label: 'Shared', value: 'shared'}
// ];

// const OPTION_VALUES = FILTER_OPTIONS.map(option => option.value) as string[];
// const OPTIONS_BY_VALUE = FILTER_OPTIONS.reduce((o, d) => {
// 	if (d.value)
// 		o[d.value] = d.label;
// 	return o;
// }, {} as Record<string, string>)

export type TClusterFilterCondition = {
	taskDefinition: string|null;
	// taskDefinitionType: string|null;
	className: string;
}

export type ClusterFilterConditionValuesProp = {
	condition: TClusterFilterCondition; unsetCondition: any
}

export const ClusterFilterConditionValues: React.FC<ClusterFilterConditionValuesProp> = ({condition, unsetCondition}) => {
  const { taskDefinition, className } = condition;
  return (
		// <>
    <div data-testid="task-definition-condition-value" className="d-inline-block mb-3">
			{taskDefinition && taskDefinition !== "" &&
			<Chip label={`Task Definition: ${taskDefinition}`}
        className="mr-2"
        color={taskDefinition ? "primary" : "default"}
				onDelete={() => unsetCondition('taskDefinition')}
				/>
			}
    </div>
		/* <div className="d-inline-block mb-3">
			{taskDefinitionType && taskDefinitionType !== "" &&
			<Chip label={`Task Type: ${OPTIONS_BY_VALUE[taskDefinitionType]}`}
				className="mr-2"
				color={taskDefinitionType ? "primary" : "default"}
				onDelete={() => unsetCondition('taskDefinitionType')}
				/>
			}
		</div> */
		/* </> */
  );
}

// function TaskDefinitionsTypeAutoComplete(props: {
// 	value: string|null;
// 	onChange: (event:any) => void;
// }) {
// 	return (
//     <Autocomplete
//       id="task-definitions-type-autocomplete"
// 			openOnFocus
//       options={OPTION_VALUES}
//       getOptionLabel={(option: string) => OPTIONS_BY_VALUE[option]}
//       value={props.value}
// 			onChange={(event, newValue) => {
// 				// console.log('TaskDefinitionsAutoComplete', newValue)
// 				props.onChange({target: {value: newValue || "" || null}});
// 			}}
//       renderInput={(params: any) =>
// 				<TextField {...params} label="Task Type" size="small" />
// 			}
//     />
//   );
// }

export type ClustersFilterProp = {
	// showForm?: boolean,
	condition: TClusterFilterCondition,
	onFieldChange: (fieldName: string) => (e: any) => void,
	unsetCondition?: any
}
// import style from './cluster-filter.css';
// Commented email search and instance address search for now
const ClustersFilter: React.FC<ClustersFilterProp> = ({
	condition, onFieldChange
}) => {

  return (
		<form className="row">
			{/* <div className="form-group col-12 col-md-6 col-lg-4">
				<TaskDefinitionsTypeAutoComplete
					value={condition.taskDefinitionType}
					onChange={onFieldChange('taskDefinitionType')} />
			</div> */}

			<div className="form-group col-12 col-md-6 col-lg-4">
				<TaskDefinitionsAutoComplete
					value={condition.taskDefinition}
					condition={condition}
					onChange={onFieldChange('taskDefinition')} />
			</div>
			{/** Placeholder for class startdate and enddate */}
		</form>
	)
}

export default ClustersFilter;
