import React, { useEffect, useRef } from "react";
import { TParticipant, TTask } from "../../../types/app.types";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { DialogCloseReason, DialogCloseEvent } from "../../../types/common.types";
import { formatParticipantName } from "../../../utils/formatName";

export type DeleteParticipantDialogProp = {
  data: TTask;
  participant: TParticipant;
  open: boolean;
  onClose: (e?: DialogCloseEvent) => void;
  onConfirm: (payload?: any) => void;
}
/**
 * Displays a dialog which allows user to pass in multiple emails for adding bulk users
 */
export default function DeleteParticipantDialog(props: DeleteParticipantDialogProp) {
  const { onClose, onConfirm, open, data, participant } = props;
  const handleClose = (e: any, reason: DialogCloseReason) => {
    onClose({reason});
  };

  const handleConfirm = () => {
    const participants: TParticipant[] =
      [...(data.participants || [])].filter(p =>
        p.email !== participant.email
      );

    onConfirm(participants);
    onClose({reason: 'confirmClick'});
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="delete-participant-dialog-title" open={open}>
      <DialogTitle data-testid="delete-participant-dialog-title">
        Delete participant
      </DialogTitle>

      <DialogContent>
        <DialogContentText data-testid="delete-participant-dialog-description">
          {`Remove ${formatParticipantName(participant)} from `}<br/>
          Task address: {data.publicIPv4Address}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button data-testid="delete-participant-dialog-cancel-button" onClick={() => onClose({reason: 'cancelClick'})}>
          Cancel
        </Button>
        <Button data-testid="delete-participant-dialog-confirm-button" color="primary" onClick={handleConfirm} autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
