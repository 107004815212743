// listTaskDefinitions
import API from "@aws-amplify/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { API_NAME } from "../../api";
import { TTaskDefinition } from "../../types/app.types";
import { sort } from "../../utils/sort";

export const getTaskDefinitions = createAsyncThunk<any, {includeResourceDetails?: boolean}, any>(
  'tasks/getTaskDefinitions',
  async(arg, thunkAPI) => {
    const path = `/Prod/step-function?listTaskDefinitions=true&includeResourceDetails=${arg.includeResourceDetails ? "true" : "false"}`;
    let res: TTaskDefinition[];
    try {
      res = await API.get(API_NAME, path, null);
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'listTaskDefinitions',
        message: `Failed to listTaskDefinitions`
      })
    }
    return res;
  }
)

export const addTaskDefinition = createAsyncThunk<any, TTaskDefinition, any>(
  'tasks/addTaskDefinition',
  async(arg, thunkAPI) => {
    const path = `/Prod/step-function?taskDefinition=true`;
    let res: TTaskDefinition;
    try {
      res = await API.post(API_NAME, path, {
        body: {
          cpu: arg.cpu,
          memory: arg.memory,
          name: arg.name,
          image: arg.image,
          tags: arg.tags
        },
        headers: {},
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'addTaskDefinition',
        message: `Failed to addTaskDefinition`
      })
    }
    return res;
  }
)

export const updateTaskDefinition = createAsyncThunk<any, TTaskDefinition, any>(
  'tasks/updateTaskDefinition',
  async(arg, thunkAPI) => {
    const path = `/Prod/step-function?taskDefinition=true`;
    let res: TTaskDefinition;
    try {
      res = await API.put(API_NAME, path, {
        body: arg,
        headers: {},
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'updateTaskDefinition',
        message: `Failed to updateTaskDefinition`
      })
    }
    return res;
  }
)

export const deleteTaskDefinition = createAsyncThunk<any, TTaskDefinition, any>(
  'tasks/deleteTaskDefinition',
  async(arg, thunkAPI) => {
    const path = `/Prod/step-function?taskDefinition=true`;
    let res: any;
    try {
      res = await API.del(API_NAME, path, {
        body: arg,
        headers: {},
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'deleteTaskDefinition',
        message: `Failed to deleteTaskDefinition`
      })
    }
    return res;
  }
)

export type TaskListState = {
  data: TTaskDefinition[];
  loading: boolean;
  byName: Record<string, TTaskDefinition>;
}

export const taskListInitialState: TaskListState = {
  loading: false,
  data: [],
  byName: {}
}

export const taskListSlice = createSlice({
  name: 'tasks',
  initialState: taskListInitialState,
  reducers: {},
  extraReducers: builder => { builder
    .addCase(getTaskDefinitions.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getTaskDefinitions.fulfilled, (state, action) => {
      // sort it by name;
      const filteredData = action.payload
        .filter((item: TTaskDefinition) => item.name !== "tim-template");
      const sortedData = sort(filteredData)
        .by((item: TTaskDefinition) => item.name).asc();

      state.data = sortedData;
      state.byName = sortedData.reduce((
        obj: Record<string, TTaskDefinition>,
        item: TTaskDefinition) => {
          if (!obj[item.name]) {
            obj[item.name] = item;
          }

          return obj;
      }, {} as Record<string, TTaskDefinition>)
    })
  }
})

export default taskListSlice.reducer;
