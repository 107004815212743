import { connect, ConnectedProps, useSelector } from "react-redux";
import { pushNotification } from "../../components/notification/notification.slice";
import { loadUsers, addUser, updateUser, removeUser, resetPassword } from "../../components/users/users.actions";
import { RootState } from "../../store/app.store";
import { StatusVariantType } from "../../types/status.types";
import Button from '@material-ui/core/Button';
// import AddIcon from '@material/icons/Add'
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import UserTable from "../../components/users/UserTable";
import { TNewUser, TUser } from "../../types/app.types";
import AddIcon from "@material-ui/icons/Add";
import { Fab, makeStyles } from "@material-ui/core";
import { useState } from "react";
import UserFormDialog from "../../components/users/UserFormDialog";
import { useMediaQuery } from 'react-responsive'
import { userRoleSelector } from "../../store/selectors";

/* istanbul ignore file */

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  fabButton: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  }
}));

const mapStateToProps = (state: RootState, props: any) => ({
	// users: state.users.data,
	loading: state.users.loading
});
const mapDispatchToProps = (dispatch: any) => ({
	// loadUsers:() => dispatch(loadUsers()),
  addUser: (newUser: TNewUser) =>  {
    // console.log('addUser', newUser);
    dispatch(addUser(newUser))
  },
  // updateUser: (value: TUser) =>  dispatch(updateUser(value)),
  // removeUser: (user: TUser) =>  dispatch(removeUser(user)),
  // resetPassword: (user: TUser) =>  dispatch(resetPassword(user)),
	pushNotification: (status: StatusVariantType, message: string) =>
		dispatch(pushNotification({ status, message }))
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
const UserManagementPage: React.FC<PropsFromRedux> = ({
  loading,
  addUser
}) => {
  const classes = useStyles();
  // const isPortrait = useMediaQuery({ orientation: 'portrait' })
  const role = useSelector(userRoleSelector);
  const isMobile = useMediaQuery({ maxWidth: 864 });
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const handleNewUserClick = () => {setCreateDialogOpen(true)}
  const handleNewUserDialogSubmit = (e: TNewUser) => {
    addUser(e);
  }
  return (
    <div className="container-fluid pt-3 px-5">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">
        <div className="d-flex">
          <Typography variant="h4" component="h1" className="d-inline-block mr-2">
            Users
          </Typography>
          {isMobile ? (
            <Fab color="primary"
              disabled={role === 'user'}
              className={classes.fabButton}
              aria-label="Add new user"
              onClick={handleNewUserClick}>
              <AddIcon />
            </Fab>
          ) : (
            <Button className="ml-3"
              disabled={role === 'user'}
              color="primary"
              aria-label="Add new user"
              onClick={handleNewUserClick}>
              Add New
            </Button>
          )}
          <UserFormDialog open={createDialogOpen}
            onSubmit={handleNewUserDialogSubmit}
            onClose={(e) => setCreateDialogOpen(false)}
             />
        </div>
        <div></div>
      </div>
      <div className="row">
        <div className="col-12">
          <Paper className="d-flex flex-column p-2 p-md-3 p-lg-4" elevation={1}>
            <div className="d-flex justify-content-between">
              <div>

              </div>
              {/* <Button className={classes.button}
                variant="contained"
                color="primary"
                aria-label="Add new user"
                onClick={handleNewUserClick}
                startIcon={<AddIcon />}>
                Add
              </Button> */}
            </div>
            <div>
              <UserTable />
            </div>
          </Paper>
          
        </div>
      </div>
    </div>
  )
}

export default connector(UserManagementPage);
