// import { DataGrid } from '@material-ui/data-grid';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store/app.store';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import { TTaskDefinition } from '../../types/app.types';
import { stableSort, getComparator } from '../../utils/sort';
import Chip from '@material-ui/core/Chip';
import { userRoleSelector } from '../../store/selectors';
import RowActions from '../table/RowActions';
import DeleteTaskDefinitionDialog from './DeleteTaskDefinitionDialog';
import TaskDefinitionFormDialog, { TTaskDefinitionFormValues } from './TaskDefinitionFormDialog';
import { mbToGb } from '../../utils/task-definition.utils';
import { deleteTaskDefinition, updateTaskDefinition } from './taskList.slice';

type Column = {
  field: string;
  label: string;
  style?: {
    width: number;
    minWidth?: number;
    maxWidth?: number;
  };
}
const columns: Column[] = [
  { field: 'name', label: 'Name', style: { width: 130 } },
  { field: 'image', label: 'Image', style: { width: 180 } },
  { field: 'version', label: 'Version', style: { width: 80 } },
  { field: 'cpu', label: 'CPU(gb)', style: { width: 120 } },
  { field: 'memory', label: 'Memory(gb)', style: { width: 120 } },
  { field: 'tags', label: 'Tags', style: { width: 180 } },
];


const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRow: {
    height: 'fit-content',
    padding: "0.3rem 0.5rem"
    // '&:first-child': {
    //   paddingLeft: 10,
    // }
  },
  tableCell: {
    verticalAlign: 'middle !important',
    '&:first-child': {
      paddingLeft: 15,
    }
  },
  headerCell: {
    fontSize: 12,
    textTransform: 'uppercase',
    '&:first-child': {
      paddingLeft: 15,
    }
  }
}));



function EnhancedTableHead<D extends Record<string, any> = any>(props: {
  classes: any;
  onSelectAllClick?: (e: any) => void;
  order?: 'asc'|'desc';
  orderBy?: keyof D;
  numSelected?: number;
  rowCount?: number;
  onRequestSort?: (e: any, property: keyof D) => void;
  columns: Column[]
}) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property: keyof D) => (event: any) => {
    if (onRequestSort) onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
        <TableCell
          key={column.field}
          align={'left'}
          className={classes.headerCell}
          sortDirection={orderBy === column.field ? order : false}
        >
          <TableSortLabel
            active={orderBy === column.field}
            direction={orderBy === column.field ? order : 'asc'}
            onClick={createSortHandler(column.field)}
          >
            <span className="text-uppercase">{column.label}</span>
            {orderBy === column.field ? (
              <span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
            ) : null}
          </TableSortLabel>
        </TableCell>
        ))}
        {/** Last column is for action button */}
        <TableCell scope="col" style={{width: '24px'}}>&nbsp;</TableCell>
      </TableRow>
    </TableHead>
  );
}

export function TaskDefinitionTableRow (props: any) {
  const { task, classes } = props;
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const role = useSelector(userRoleSelector);
  const byImage = useSelector((state: RootState) => state.images.byImage);
  const byName = useSelector((state: RootState) => state.tasks.byName);
  
  const dispatch = useAppDispatch();

  const getRowActions = () => ([
    {
      id: "task-definition-edit-action",
      label: 'Edit',
      disabled: role === 'user',
      onClick: () => {
        setEditDialogOpen(true);
      }
    },
    {
      id: "delete-task-definition-action",
      label: 'Delete',
      disabled: role === 'user',
      onClick: () => {
        setDeleteConfirmationOpen(true);
      }
    },
  ]);
  

  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.tableCell}>
        <div className="d-flex justify-content-start align-items-center">
          {task.name || "-"}
        </div>
      </TableCell>
      
      <TableCell className={classes.tableCell}>
        <div className="d-flex justify-content-start align-items-center">
          {(byImage[task.image] && byImage[task.image].name) || "-"}
        </div>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <div className="d-flex justify-content-start align-items-center">
          {task.version || "-"}
        </div>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <div className="d-flex justify-content-start align-items-center">
          {mbToGb(Number.parseInt(task.cpu))}
        </div>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <div className="d-flex justify-content-start align-items-center">
          {mbToGb(Number.parseInt(task.memory))}
        </div>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <div className="d-flex justify-content-start align-items-center">
          {((
            byName[task.name] &&
            byName[task.name].tags &&
            Object.keys(byName[task.name].tags as Record<string, string>)
          ) || []).map((tagKey, i) => (
            <Chip
              key={task.name + "-" + tagKey}
              size="small"
              label={tagKey + " : " +(byName[task.name].tags as Record<string, string>)[tagKey]}
              color="default"
              variant="default" style={{margin: '0 0.3rem'}} />
          ))}
        </div>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <div className="d-flex justify-content-end align-items-center">
          <RowActions id={"task-definition-action-"+task.name} actions={getRowActions()}/>
        </div>
        <TaskDefinitionFormDialog open={editDialogOpen}
          isEdit={true}
          defaultValue={task}
          onClose={() => {
            setEditDialogOpen(false);
          }}
          onSubmit={(value: TTaskDefinitionFormValues) => {
            // console.log("TaskDefinitionFormDialog.edit submit", value)
            dispatch(updateTaskDefinition({
              ...task,
              cpu: value.cpu,
              memory: value.memory,
              name: value.name,
              tags: {
                ...value.tags
              }
            }))
            setEditDialogOpen(false);
          }}/>
        <DeleteTaskDefinitionDialog open={deleteConfirmationOpen}
          data={task}
          onClose={() => {
            setDeleteConfirmationOpen(false);
          }}
          onConfirm={() => {
            setDeleteConfirmationOpen(false);
            dispatch(deleteTaskDefinition(task));
          }}/>
      </TableCell>
    </TableRow>
  );
}

const TaskTable: React.FC<any> = () => {
  const classes = useStyles();

  const [order, setOrder] = useState<'asc'|'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof TTaskDefinition>('name');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const tasks = useSelector((state: RootState) => state.tasks.data || []);
  const handleRequestSort = (event: any, property: keyof TTaskDefinition) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const dispatch = useAppDispatch();
  


  return (
    <div className="table-responsive-lg">
      {/* <pre>
        {JSON.stringify(tasks, null, 2)}
        </pre> */}
      <Table data-testid="user-table" className="table table-striped table-sm">
        <EnhancedTableHead classes={classes}
          order={order}
          orderBy={orderBy}
          rowCount={tasks.length}
          onRequestSort={handleRequestSort}
          columns={columns}
          />
        <TableBody>
          {tasks && tasks.length
          ? stableSort<TTaskDefinition>(tasks, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((task, i) =>
              <TaskDefinitionTableRow key={i}
                classes={classes}
                task={task}
                tasks={tasks} />
            )
          : <TableRow>
              <TableCell colSpan={columns.length}>
              <div className="d-flex flex-column align-items-center justify-content-center" style={{minHeight: '250px'}}>
                  <Typography variant="subtitle1" component="p">
                    Fetching data...
                  </Typography>
                </div>
              </TableCell>
              
            </TableRow>
          }
        </TableBody>
      </Table>
      <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={tasks.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </div>
  )
}

// export default connector(UserTable);
export default TaskTable;
