import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogCloseReason, DialogCloseEvent } from "../../types/common.types";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core";
import { TTaskDefinition } from "../../types/app.types";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/app.store';
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    marginBottom: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
    width: "100%",
    marginBottom: '1rem'
  },
}));

// https://docs.aws.amazon.com/AmazonECS/latest/developerguide/task_definition_parameters.html
export const TASK_CPU_SIZES: {
  value: string;
  label: string;
  meta: {
    memoryRangeMin: number;
    memoryRangeMax: number;
    memoryOptions?: {value: string, label: string, meta: { gb: number }}[]
  }
}[] = [
  {
    value: "1024",
    label: "1024 (1vCPU)",
    meta: {
      memoryRangeMin: 2,
      memoryRangeMax: 8
    }
  },
  {
    value: "2048",
    label: "2048 (2vCPU)",
    meta: {
      memoryRangeMin: 4,
      memoryRangeMax: 16
    }
  },
  {
    value: "4096",
    label: "4096 (4vCPU)",
    meta: {
      memoryRangeMin: 8,
      memoryRangeMax: 30
    }
  },
  {
      value: "8192",
      label: "8192 (8vCPU)",
      meta: {
        memoryRangeMin: 16,
        memoryRangeMax: 60
      }
  },
  {
      value: "16384",
      label: "16384 (16vCPU)",
      meta: {
        memoryRangeMin: 32,
        memoryRangeMax: 120
    }
  }
];

export const TASK_MEMORY_SIZES = [
  { value: "2048", label: "2 GB", meta: { gb: 2 } },
  { value: "3072", label: "3 GB", meta: { gb: 3 } },
  { value: "4096", label: "4 GB", meta: { gb: 4 } },
  { value: "5120", label: "5 GB", meta: { gb: 5 } },
  { value: "6144", label: "6 GB", meta: { gb: 6 } },
  { value: "7168", label: "7 GB", meta: { gb: 7 } },
  { value: "8192", label: "8 GB", meta: { gb: 8 } },
  { value: "9216", label: "9 GB", meta: { gb: 9 } },
  { value: "10240", label: "10 GB", meta: { gb: 10 } },
  { value: "11264", label: "11 GB", meta: { gb: 11 } },
  { value: "12288", label: "12 GB", meta: { gb: 12 } },
  { value: "13312", label: "13 GB", meta: { gb: 13 } },
  { value: "14336", label: "14 GB", meta: { gb: 14 } },
  { value: "15360", label: "15 GB", meta: { gb: 15 } },
  { value: "20480", label: "20 GB", meta: { gb: 20 } },
  { value: "24576", label: "24 GB", meta: { gb: 24 } },
  { value: "28672", label: "28 GB", meta: { gb: 28 } },
  { value: "32768", label: "32 GB", meta: { gb: 32 } },
  { value: "40960", label: "40 GB", meta: { gb: 40 } },
  { value: "61440", label: "60 GB", meta: { gb: 60 } },
  { value: "65536", label: "64 GB", meta: { gb: 64 } },
  { value: "122880", label: "120 GB", meta: { gb: 120 } },
]


type TCPUOption = {
  value: string;
  label: string;
  meta: {
    memoryRangeMin: number;
    memoryRangeMax: number;
    memoryOptions?: {value: string, label: string, meta: { gb: number }}[]
  }
};
export const TASK_SIZE_OPTION_MAP = {
  cpu: TASK_CPU_SIZES.reduce((obj, option: TCPUOption) => {
    obj[option.value as string] = option;
    obj[option.value as string].meta.memoryOptions = TASK_MEMORY_SIZES
      .filter(size =>
          size.meta.gb >= option.meta.memoryRangeMin &&
          size.meta.gb <= option.meta.memoryRangeMax
        )
    return obj
  }, {} as Record<string, TCPUOption>)
};

export const EMPTY_TASK_DEFINITION: TTaskDefinition = {
  cpu: "",
  image: "",
  memory: "",
  name: "",
  path: "",
  taskDefinitionArn: "",
  version: ""
}

export type TTaskDefinitionFormValues = {
  cpu: string;
  image: string;
  memory: string;
  name: string;
  tags: {property: string, value: string}[]
}

const NEW_TASK_DEFINITION_INITIAL_VALUE: TTaskDefinitionFormValues = {
  cpu: "2048",
  memory: "4096",
  name: "",
  image: "",
  tags: []
}

const validate = (values: any) => {
  const errors: any = {};

  if (!values.name) {
    errors.name = 'Required';
  } else if (values.name.includes(" ")) {
    errors.name = "Name can't have any space (use - or _).";
  }

  if (!values.image) {
    errors.image = 'Required';
  }

  //...

  return errors;
};


const initialValues = NEW_TASK_DEFINITION_INITIAL_VALUE;

export type TaskDefinitionFormProps<IsEdit extends boolean> = {
  open: boolean;
  onClose: (e: DialogCloseEvent) => void;
  onSubmit: (payload: any) => void;
  isEdit?: IsEdit;
  defaultValue?: IsEdit extends true ? TTaskDefinition : undefined;
  // users?: IsEdit extends true ? TUser[] : undefined;
}

const DISABLED_TAG_SET = new Set(['Created-By']);

export default function TaskDefinitionFormDialog<IsEdit extends boolean = any>(
  props: TaskDefinitionFormProps<IsEdit>
) {
  const { onClose, onSubmit, open, defaultValue, isEdit } = props;
  const images = useSelector((state: RootState) => state.images.data);
  const classes = useStyles();

  const handleSubmit = (values: TTaskDefinitionFormValues) => {
    // console.log("TaskDefinitionFormDialog.onSubmit", values);
    const tags: Record<string, string> = values.tags.reduce((
      obj: Record<string, string>,
      item: {property: string, value: string
    }) => {
      if (item.property && item.property !== "") {
        obj[item.property] = item.value;
      }
      return obj;
    }, {} as Record<string, string>)

    onSubmit({...values, tags});
    onClose({reason: 'confirmClick'});
  }

  const formik = useFormik<TTaskDefinitionFormValues>({
    initialValues,
    validate,
    onSubmit: handleSubmit
  })

  

  const addTag = () => {
    formik.setFieldValue('tags', [
      ...(formik.values.tags||[]),
      { property: "", value: "" }
    ])
  }

  const handleClose = (e: any, reason: DialogCloseReason) => {
    onClose({reason});
    formik.resetForm((isEdit && defaultValue
      ? {...initialValues, ...defaultValue}
      : initialValues
    ) as any);
  };

  useEffect(() => {
    formik.setValues((isEdit && defaultValue
      ? {
        ...initialValues,
        ...defaultValue,
        tags: defaultValue && defaultValue.tags
          ? Object.keys(defaultValue.tags)
            // .filter((key) => !DISABLED_TAG_SET.has(key))
            .map((key) => ({
              property: key,
              value: defaultValue && defaultValue.tags && defaultValue.tags[key]
            }))
          : []
      }
      : initialValues
    ) as any);
    return () => {}
  }, [defaultValue]);

  useEffect(() => {
    if (!open && !isEdit) {
      formik.resetForm(initialValues as any);
    }
  }, [open]);
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {isEdit ? "Edit task definition" : "Add new task definition"}
          <Button color="primary" onClick={addTag}>
              Add tag
            </Button>
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12">
              <TextField fullWidth autoFocus id="name"
                disabled={isEdit}
                required
                name="name"
                label="Name"
                className={classes.input}
                value={formik.values.name}
                type="text"
                onBlur={formik.handleBlur}
                helperText={
                  isEdit ? "Name can't be changed, once task definition is already registered" :
                  !!formik.errors.name ? formik.errors.name : null
                }
                error={!!formik.errors.name}
                onChange={formik.handleChange} />
            </div>

            <div className="col-12">
              <FormControl className={classes.formControl} error={!!formik.errors.image} required>
                <InputLabel id="image-select-label">Image</InputLabel>
                <Select
                  labelId="image-select-label"
                  id="image-select"
                  name="image"
                  required
                  value={formik.values.image}
                  onChange={formik.handleChange}
                >
                  {images.map((option, i) => (
                    <MenuItem key={"image-option-"+i} value={option.image}>{option.name}/{option.version}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="col-12 col-md-6">
              <FormControl className={classes.formControl}>
                <InputLabel id="cpu-select-label">CPU (gb)</InputLabel>
                <Select
                  labelId="cpu-select-label"
                  id="cpu-select"
                  name="cpu"
                  value={formik.values.cpu}
                  onChange={formik.handleChange}
                >
                  {TASK_CPU_SIZES.map((option, i) => (
                    <MenuItem key={"cpu-option-"+i} value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-6">
              <FormControl className={classes.formControl}>
                <InputLabel id="memory-select-label">Memory (gb)</InputLabel>
                <Select
                  labelId="memory-select-label"
                  id="memory-select"
                  name="memory"
                  value={formik.values.memory}
                  onChange={formik.handleChange}
                >
                  {(
                    (TASK_SIZE_OPTION_MAP.cpu[formik.values.cpu] && TASK_SIZE_OPTION_MAP.cpu[formik.values.cpu].meta.memoryOptions) ||
                    []
                  ).map((option, i) => (
                    <MenuItem key={"memory-option-"+i} value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <Divider />
          <div className="row">
            <div className="col-12">
              <Typography variant="subtitle1" component="div" align="center">
                Task Definition Tags
              </Typography>
            </div>
            <div className="col-12">
            {(formik.values.tags || []).map((tag, i) => (
              <div className="row" key={"tag-"+i}>
                <div className="col-12 col-md-6">
                  <TextField fullWidth autoFocus id={`tags[${i}].property`}
                    disabled={DISABLED_TAG_SET.has(formik.values.tags[i].property)}
                    name={`tags[${i}].property`}
                    label="tag name"
                    className={classes.input}
                    value={formik.values.tags[i].property}
                    type="text"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange} />
                </div>
                <div className="col-12 col-md-6">
                  <TextField fullWidth autoFocus id={`tags[${i}].value`}
                    disabled={DISABLED_TAG_SET.has(formik.values.tags[i].property)}
                    name={`tags[${i}].value`}
                    label="Value"
                    className={classes.input}
                    value={formik.values.tags[i].value}
                    type="text"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange} />
                </div>
              </div>
            ))}
            </div>
          </div>
          {/* <pre>
            {JSON.stringify(formik.values, null, 2)}
          </pre> */}
        </DialogContent>
        <DialogActions>
          <Button
            aria-label="submit new user"
            className={classes.button}
            onClick={() => {
              // onSubmit(formik.values);
              handleSubmit(formik.values);
              // formik.handleSubmit();
              
            }}
            disabled={!formik.isValid}
            color="primary">
            Submit
          </Button>
          <Button aria-label="cancel creating user"
            className={classes.button}
            onClick={() => onClose({reason: 'cancelClick'})}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
