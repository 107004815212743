import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogCloseReason, DialogCloseEvent } from "../../types/common.types";
import { TNewUser, TUser } from "../../types/app.types";
import { useFormik } from 'formik';
import * as EmailValidator from 'email-validator';
import { makeStyles } from "@material-ui/core";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    marginBottom: theme.spacing(1),
  },
}));


function emailValidator(values: TNewUser|TUser): {email?: string} {
  const errors: {email?: string} = {};

  if (!values.email || values.email.trim() === "") {
    errors.email = 'Email address is required';
  }
  if (values.email && values.email.trim() !== "" && !EmailValidator.validate(values.email)) {
   errors.email = 'Please enter valid email address.';
  }
  return errors;
}

function duplicatedEmailValidator(values: TNewUser|TUser, users: TUser[], self: string): {email?: string} {
  const errors: {email?: string} = {};

  if (values.email && values.email.trim() !== "" && !errors.email) {

    const emailExists = users.find(user => self
        ? (user.email !== self && user.email === values.email)
        : (user.email === values.email)
      );
    if (emailExists)
      errors.email = 'This email address is already taken.';

  }
  return errors;
}

const initialValues: TNewUser = { firstName: "", lastName: "", email: "" };

export type UserFormDialogProps<IsEdit extends boolean> = {
  open: boolean;
  onClose: (e: DialogCloseEvent) => void;
  onSubmit: IsEdit extends true ? (payload: TUser) => void : (payload: TNewUser) => void;
  isEdit?: IsEdit;
  defaultValue?: IsEdit extends true ? TUser : undefined;
  users?: IsEdit extends true ? TUser[] : undefined;
}
/**
 * This Dialog handles both create new user and edit user
 */
export default function UserFormDialog<IsEdit extends boolean = any>(props: UserFormDialogProps<IsEdit>) {
  const { onClose, onSubmit, open, defaultValue, isEdit, users } = props;
  const classes = useStyles();

  // console.log('UserFormDialog', defaultValue?.email)

  const validate = (values: TNewUser|TUser) => {
    return {
      ...emailValidator(values),
      ...((isEdit && defaultValue)
        ? duplicatedEmailValidator(values, (users as any[] || []), defaultValue?.email || "")
        : {}
      )
    };
  }
  const formik = useFormik({
    initialValues: (isEdit && defaultValue
      ? {...initialValues, ...defaultValue}
      : initialValues
    ) as any,
    validate,
    onSubmit
  });
  
  const handleClose = (e: any, reason: DialogCloseReason) => {
    onClose({reason});
    formik.resetForm((isEdit && defaultValue
      ? {...initialValues, ...defaultValue}
      : initialValues
    ) as any);
  };

  useEffect(() => {
    formik.setValues((isEdit && defaultValue
      ? {...initialValues, ...defaultValue}
      : initialValues
    ) as any);
    return () => {}
  }, [defaultValue]);


  useEffect(() => {
    if (!open && !isEdit) {
      formik.resetForm(initialValues as any);
    }
    // console.log(formik.values)
  }, [open]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {isEdit ? "Edit user" : "Add new user"}
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-12 col-md-6">
              <TextField fullWidth autoFocus id="firstName"
                name="firstName"
                label="First Name"
                className={classes.input}
                value={formik.values.firstName}
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange} />
            </div>
            <div className="col-12 col-md-6">
              <TextField fullWidth id="lastName"
                name="lastName"
                label="Last Name"
                className={classes.input}
                value={formik.values.lastName}
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange} />
            </div>
            <div className="col-12">
              <FormControl className="w-100 mb-2">
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role-label"
                  id="role-select"
                  name="role"
                  value={formik.values.role}
                  onChange={(e: any) => formik.setFieldValue("role", e.target.value)}
                >
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="user">User</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-12">
              <TextField fullWidth required id="email"
                name="email"
                label="Email Address"
                disabled={isEdit}
                className={classes.input}
                value={formik.values.email}
                type="email"
                error={formik.touched.email && !!formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange} />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            aria-label="submit new user"
            className={classes.button}
            onClick={() => {
              onSubmit(formik.values);
              onClose({reason: 'confirmClick'});
            }}
            disabled={!formik.isValid}
            color="primary">
            Submit
          </Button>
          <Button aria-label="cancel creating user"
            className={classes.button}
            onClick={() => onClose({reason: 'cancelClick'})}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
