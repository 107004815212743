import React, { useState, useRef, useEffect } from "react";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Button from '@material-ui/core/Button';
import { FormHelperText, makeStyles, withStyles } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { DialogCloseReason, DialogCloseEvent } from "../../types/common.types";
import { TUser, TUserWithPassword } from "../../types/app.types";

export type ResetPasswordDialogProp = {
  data: TUser;
  open: boolean;
  onClose: (e: DialogCloseEvent) => void;
  onConfirm: (payload: TUserWithPassword) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '30vw'
  }
}))
/**
 * A user shouldn't get accidentally removed
 * default focus element should be cancel button
 */
const ResetPasswordDialog = (props: ResetPasswordDialogProp) => {
  const { onClose, onConfirm, open, data } = props;

  const classes = useStyles();
  const [value, setValue] = useState<string>("");
  const [error, setError] = useState<string|null>(null);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [showValue, setShowValue] = useState<boolean>(false);

  const confirmationInputRef = useRef<any>(null);
  const handleClose = (e: any, reason: DialogCloseReason) => {
    onClose({reason});
  };
  const handleConfirm = () => {
    onConfirm({...data, password: value, permanent: true});
    onClose({reason: 'confirmClick'});
  }

  const handleConfirmChange = (e: any) => {
    if (confirmationInputRef.current.value === value) {
      setError(null);
      setConfirmed(true);
    } else {
      setError('Password does not match.');
      setConfirmed(false);
    }
  }
  const handleChange = (e: any) => {
    setValue(e.target.value);
  }
  const handleClickShowPassword = () => {setShowValue(!showValue)};
  const handleMouseDownPassword = (e: any) => {e.preventDefault()};

  useEffect(() => {
    if (confirmationInputRef.current) {
      confirmationInputRef.current.value = "";
    }
    setValue("");
  }, [open])
  return (
    <Dialog onClose={handleClose} aria-labelledby="reset-password-dialog-title" open={open}>
      <DialogTitle data-testid="reset-password-dialog-title">
        Reset password for a user
      </DialogTitle>

      <DialogContent className={classes.root} >
        <DialogContentText data-testid="reset-password-dialog-description"
          className="mb-2 d-flex justify-content-center">
          {data.email}
        </DialogContentText>
        <FormControl className="w-100">
          <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
          <Input
            autoFocus
            id="standard-adornment-password"
            type={showValue ? 'text' : 'password'}
            value={value}
            placeholder="Enter password"
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showValue ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl className="w-100 mt-3">
          <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
          <Input
            autoFocus
            inputRef={confirmationInputRef}
            id="standard-adornment-password-confirm"
            type={showValue ? 'text' : 'password'}
            placeholder="Re-enter password"
            onChange={handleConfirmChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showValue ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {error && <FormHelperText error={!!error}>
            {error}
          </FormHelperText>}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button data-testid="reset-password-dialog-confirm-button" color="primary" disabled={!confirmed} onClick={handleConfirm}>
          Reset
        </Button>
        <Button data-testid="reset-password-dialog-cancel-button" onClick={() => onClose({reason: 'cancelClick'})}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ResetPasswordDialog;
