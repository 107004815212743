import React from "react";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { DialogCloseReason, DialogCloseEvent } from "../../types/common.types";
import { TUser } from "../../types/app.types";
import { Send } from "@material-ui/icons";
import { Typography } from "@material-ui/core";

export type ResendEmailDialogProp = {
  data: TUser;
  open: boolean;
  onClose: (e: DialogCloseEvent) => void;
  onConfirm: (payload: TUser) => void;
}

const ResendEmailDialog = (props: ResendEmailDialogProp) => {
  const { onClose, onConfirm, open, data } = props;
  const handleClose = (e: any, reason: DialogCloseReason) => {
    onClose({reason});
  };
  const handleConfirm = () => {
    onConfirm(data);
    onClose({reason: 'confirmClick'});
  }
  return (
    <Dialog onClose={handleClose} aria-labelledby="resend-email" open={open}>
      <DialogTitle data-testid="resend-email-dialog-title">
        Resend temporary password
      </DialogTitle>

      <DialogContent>
        <DialogContentText data-testid="resend-email-dialog-description" className="mb-2">
          to: {data.email}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button data-testid="resend-email-dialog-confirm-button" color="primary" onClick={handleConfirm} autoFocus>
          <Typography className="mr-2">Send</Typography>
          <Send />
        </Button>
        <Button data-testid="resend-email-dialog-cancel-button" onClick={() => onClose({reason: 'cancelClick'})}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ResendEmailDialog;
