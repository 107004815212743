import React, { useCallback } from 'react';
import { TCluster } from '../../../types/app.types';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Menu, {MenuProps} from '@material-ui/core/Menu';
import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store/app.store';
import { withStyles } from '@material-ui/core';
import { updateCluster } from '../clusters.actions';

export type ClusterOrganizerType = 'trainer' | 'coTrainer' | 'support';

const StyledMenu = withStyles({
  paper: { border: 'unset', width: '90%'},
})((props: MenuProps) => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));


export const CompactClusterOrganizerListItem: React.FC<{
  label: string;
  type: ClusterOrganizerType;
  data: TCluster;
}> = ({ label, type, data }) => {
  if (!label)
    throw Error('missing label for CompactClusterOrganizerListItem');

  if (!type)
    throw Error(`missing type for CompactClusterOrganizerListItem\n\toptions: 'trainer' | 'coTrainer' | 'support'`);

  

  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState<string|null>(data[type]?.username || null);

  const {usernames, byUsername} = useSelector((state: RootState) => state.users.normalized);
  const dispatch = useAppDispatch();

  if (!data && data === null) {
    return <></>
  }

  const organizer = data[type];

  const handleSelect = (type:'trainer'|'coTrainer'|'support', username: string) => (e: any) => {
    // if (isPrint || !updateCluster) return;
    // setAnchorEl(e.currentTarget);
    setValue(username);

    dispatch(updateCluster({...data,
        trainer: {
          username: (data.trainer?.username || "")
        },
        coTrainer: {
          username: (data.coTrainer?.username || "")
        },
        support: {
          username: (data.support?.username || "")
        },
        // override object value with current typed value
        [type]: { username }
    }) as any)
    handleClose();
  }

  // const handleClick = (event: any) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleMenuTriggerClick = (event: any) => {
    // console.log(event.currentTarget)
    setAnchorEl(event.currentTarget);
  };


  

  return (
    <div className="col-12 col-md-4 mb-3 px-3">
      <Typography variant="caption" className="text-muted" style={{fontWeight: 500}}>
        {label}
      </Typography>
      <div className="d-flex justify-content-between justify-content-lg-start" onClick={handleMenuTriggerClick}>
        
        {organizer && organizer.username && organizer.username.trim() !== "" ?
        (
          <div className="d-flex
            flex-column align-items-start
            flex-sm-row align-items-sm-center
            flex-md-column align-items-md-start
            flex-lg-row align-items-lg-center">
            <Typography variant="body1" component="span" className="d-block mr-2 text-dark">
              {(data[type]?.firstName && data[type]?.lastName) ? (data[type]?.firstName + " " + data[type]?.lastName) : ""}
            </Typography>
            <Typography variant="body1" component="span" className="text-muted d-none d-xl-block mr-2">
              {data[type]?.email || "None"}
            </Typography>
          </div>
        ) : (
          <Typography variant="body1" component="span" className="text-muted" style={{minWidth: 150}}>
            None
          </Typography>
        )}
          
        
        <div className="">
          <IconButton aria-controls="organizer-selector" size="small" aria-label={"Select " + label}>
            {anchorEl ? <ArrowDropUpIcon/> : <ArrowDropDownIcon />}
          </IconButton>
        </div>
      </div>
      {/* <pre>
        {JSON.stringify(usernames, null, 2)}
      </pre> */}
      <StyledMenu
        id="organizer-selector"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSelect(type, "")}
          selected={value === ""}>
          -
        </MenuItem>
        {usernames.map((username, i) =>
          <MenuItem onClick={handleSelect(type, username)} key={i}
            selected={value === username}>
            {byUsername[username].firstName} {byUsername[username].lastName}
          </MenuItem>
          )}
      </StyledMenu>
    </div>
  )
}

const CLASS_ORGANIZERS_LIST_REF: Array<{label: string, type: ClusterOrganizerType}> = [
  { label: 'Trainer', type: 'trainer' },
  { label: 'Co-Trainer', type: 'coTrainer' },
  { label: 'Support', type: 'support' }
];
export type CompactClusterOrganizerListProp = {data: TCluster};
export const CompactClusterOrganizerList: React.FC<{data: TCluster}> = ({data}) => {
  return (
    <div data-testid="cluster-organizers-container" className="container-fluid">
      <div className="row">
      {CLASS_ORGANIZERS_LIST_REF.map(({label, type}) => (
        <CompactClusterOrganizerListItem
          data={data}
          label={label}
          type={type}
          key={data.clusterName+"-"+type}/>
      ))}
      </div>
    </div>
  )
}

export default CompactClusterOrganizerList;
