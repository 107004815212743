// import { FormHelperText, Input, InputAdornment, InputLabel, NativeSelect, Select } from "@material-ui/core";
import { useEffect, useState } from 'react';
// import InputLabel from "@material-ui/core/InputLabel";
// import Select from "@material-ui/core/Select";
// import NativeSelect from "@material-ui/core/NativeSelect";
import { useSelector } from "react-redux";
import { RootState } from "../../store/app.store";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TUser } from '../../types/app.types';
import { FocusEventHandler } from 'react';

export type UserAutoCompleteProp = {
  id: string;
  label: string;
  value: any;
  getOptionValue: (user: TUser) => string,
  onChange: (e: any, value: any) => void;
  onBlur?: FocusEventHandler<HTMLDivElement>;
  required?: boolean;
  helperText?: any;
  error?: boolean;
}

export default function UserAutoComplete(props: UserAutoCompleteProp) {
  const { id, label, onChange, onBlur, required, helperText, error } = props;
  const byUsername = useSelector((state: RootState) => state.users.normalized.byUsername);
  // const usernames = useSelector((state: RootState) => state.users.normalized.usernames);
  const users = useSelector((state: RootState) => state.users.data);
  const [ value, setValue ] = useState(props.value && byUsername ? byUsername[props.value] : null);

  useEffect(() => {
    setValue(byUsername[props.value])
    // return () => {
    //   cleanup
    // }
  }, [props.value, byUsername])
  return (
    <Autocomplete
      data-testid={!id || id === "" ? "user-autocomplete" : id}
      id={id}
      openOnFocus
      options={users}
      getOptionLabel={(option: TUser) => `${option.firstName} ${option.lastName}`}
      value={value || null}
      onBlur={onBlur}
      onChange={(event, newValue) => {
        // setValue(newValue?.username);
        if (onChange) onChange(event, newValue);
      }}
      renderInput={(params: any) =>
        <TextField {...params} error={error} label={label}
          required={required}
          helperText={helperText}
          InputLabelProps={{style: { fontWeight: 500 }}}
           />
      }
      />
  )
}
