import React from "react";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { DialogCloseReason, DialogCloseEvent } from "../../types/common.types";
import { TUser } from "../../types/app.types";


const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

export type DeleteUserDialogProp = {
  data: TUser;
  open: boolean;
  onClose: (e: DialogCloseEvent) => void;
  onConfirm: (payload: TUser) => void;
}

/**
 * A user shouldn't get accidentally removed
 * default focus element should be cancel button
 */
const DeleteUserDialog = (props: DeleteUserDialogProp) => {
  const { onClose, onConfirm, open, data } = props;
  const handleClose = (e: any, reason: DialogCloseReason) => {
    onClose({reason});
  };
  const handleConfirm = () => {
    onConfirm(data);
    onClose({reason: 'confirmClick'});
  }
  return (
    <Dialog onClose={handleClose} data-testid="delete-user-dialog" aria-labelledby="delete-confirmation-dialog" open={open}>
      <DialogTitle id="delete-user-dialog-title"
        data-testid="delete-user-dialog-title">
        You're about to delete this user
      </DialogTitle>

      <DialogContent>
        <DialogContentText data-testid="delete-user-dialog-description" className="mb-2">
          {data.email}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ColorButton data-testid="delete-user-dialog-confirm-button" onClick={handleConfirm}>
          Delete
        </ColorButton>
        <Button data-testid="delete-user-dialog-cancel-button" onClick={() => onClose({reason: 'cancelClick'})} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteUserDialog;
