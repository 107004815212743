/* istanbul ignore file */
import API from '@aws-amplify/api';
import { ActionsObservable, combineEpics, Epic, ofType, StateObservable } from 'redux-observable';
import { forkJoin, from, of, timer } from 'rxjs';
import { distinctUntilChanged, exhaustMap, filter, map, mergeMap, switchMap, take, takeUntil, catchError } from 'rxjs/operators';
import { TCluster, TParticipantAttendanceRow, TTask } from '../../types/app.types';
import { addCluster, loadClusters, loadClusterTasks, parseTaskId, removeCluster, stopClusterTask, updateCluster } from './clusters.actions';
import { stopPollingNewClass, setClusterTasks, clusterInitialState, startPollingNewClass } from './clusters.slice';
import { isEqual } from 'lodash';
import { REFRESH_INTERVAL, METRIC_REFRESH_INTERVAL, AUTO_DESTROY_ADD_HOURS, MAXIMUM_EXPIRATION_DAYS } from '../../constants/app.constants';
import { RootState } from '../../store/app.store';
import { pushNotification } from '../notification/notification.slice';
import { StatusVariant } from '../../types/status.types';
// import { createRef } from 'react';
import { send } from '@giantmachines/redux-websocket';
import { addDays, formatISO } from 'date-fns';
import addHours from 'date-fns/addHours';
// import format from 'date-fns/format';

const lastResponseRef: Record<string, TTask[]> = {};

/**
 * Termination schedule epic
 *  termination is automatically scheduled whether user
 *  choose to terminate a class automatically/manually.
 *
 *  case 1:
 *   When new/existing class is set to terminate automatically
 *   Then schedule to terminate new/existing class
 *     terminate timestamp is set to (cluster.endDate + AUTO_DESTROY_ADD_HOURS)
 *  case 2:
 *   When new/existing class is set to terminate automatically
 *   Then schedule to terminate new/existing class
 *     terminate timestamp is set to (cluster.endDate + MAXIMUM_EXPIRATION_DAYS)
 *
 *   Motivation is to override existing schedule to new termination timestamp
 *   While we can destroy existing schedule with current version of API,
 *   we can override to reschedule.
 */
function scheduleTerminationForNew(): Epic<any, any, any, any> {
  return (action$: ActionsObservable<any>, state$: StateObservable<RootState>) => action$.pipe(
    ofType(
      'clusters/addCluster/fulfilled'
    ),
    filter(action => action.scheduleTermination),
    map(action => action),
    switchMap(({payload, meta}) => state$.pipe(
      take(1),
      map(state => ({state, payload, meta}))
    )),
    map(({state, payload, meta}) => {
      const terminateAt = meta.arg.scheduleTermination
        ? addHours(new Date(meta.arg.endDate), AUTO_DESTROY_ADD_HOURS)
        : addDays(new Date(meta.arg.endDate), MAXIMUM_EXPIRATION_DAYS);
      return removeCluster({
        cluster: {
          clusterName: payload.clusterName,
          className: meta.arg.className,
          tasks: [],
        } as any,
        terminateImmediately: false,
        terminateAt: formatISO(terminateAt),
        silent: true
      })
    })
  )
}

function scheduleTerminationForExisting(): Epic<any, any, any, any> {
  return (action$: ActionsObservable<any>, state$: StateObservable<RootState>) => action$.pipe(
    ofType(
      'clusters/updateCluster/fulfilled',
      'clusters/addCluster/fulfilled'
    ),
    map(action => action),
    switchMap(({payload, meta}) => state$.pipe(
      take(1),
      map(state => ({state, payload, meta}))
    )),
    map(({state, payload, meta}) => {
      const terminateAt = meta.arg.scheduleTermination
        ? addHours(new Date(meta.arg.endDate), AUTO_DESTROY_ADD_HOURS)
        : addDays(new Date(meta.arg.endDate), MAXIMUM_EXPIRATION_DAYS);
      return removeCluster({
        cluster: {
          clusterName: payload.clusterName,
          className: meta.arg.className,
          tasks: [],
        } as any,
        terminateImmediately: false,
        terminateAt: formatISO(terminateAt),
        silent: true
      })
    })
  )
}
/**
 * Clusters Redux Observables
 */
export const clustersEpic: Epic = combineEpics(
  scheduleTerminationForNew(),
  scheduleTerminationForExisting(),
  // When Restarting starts, trigger stopping current running task
  action$ => action$.pipe(
    ofType(
      'clusters/restartClusterTask/pending',
    ),
    map(({payload, meta}) => {
      // console.log('clustersEpic restart', meta.arg)
      const {clusterName, className, task} = meta.arg;
      return stopClusterTask({clusterName, className, task, isRestart: true});
    })
  ),

  (action$, state$: StateObservable<RootState>) => action$.pipe(
    ofType('clusters/startPollingMetric'),
    exhaustMap(({payload, meta}) => {
      const {clusterName, tasks} = payload;
      return timer(0, METRIC_REFRESH_INTERVAL).pipe(
        // Fetch data until stopPollingTasks action is triggered from React component.
        takeUntil(action$.ofType('clusters/stopPollingMetric')),
        map(() => 
          // forkJoin([
          //   ...tasks
          //     .filter((task: TTask) => task.lastStatus === "RUNNING")
          //     .map((task: TTask) => pollingTaskMetric(clusterName, task.taskArn))
          // ])
          send({action: "getMetrics", clusterName})
          // from(pollingTaskMetric(clusterName))
        ),
        // map(res => res)
      )
    })
  ),
  (action$, state$: StateObservable<RootState>) => action$.pipe(
    ofType('clusters/updateAllParticipantsAttendance'),
    map(action => action),
    
    switchMap(({payload, meta}) => state$.pipe(
      take(1),
      map(state => ({state, payload, meta}))
    )),
    switchMap(({payload, meta, state}) => {
      const {clusterName, participants, attendanceIndex, value} = payload;
      // console.log(payload, clusterName, state.clusters.entities, state.clusters.entities.byClusterName[clusterName])
      const cluster = (state.clusters.entities.byClusterName[clusterName] || clusterInitialState);
      const participantsToTaskArnMap = (participants || []).reduce((o: any, d: TParticipantAttendanceRow) => {
        if (!o[d.taskArn as string]) o[d.taskArn as string] = [];
        o[d.taskArn as string].push({
          email: d.email,
          firstName: d.firstName,
          lastName: d.lastName,
          title: d.title,
          attendances: [...(d.attendances||[] as boolean[])].map<boolean>((v, i) => i === attendanceIndex ? value : v)
        });
        return o;
      }, {});
      return forkJoin(cluster.data.tasks.map((task: TTask, i: number) => {
          // const participant = participantsToTaskArnMap[task.taskArn] && participantsToTaskArnMap[task.taskArn][0];
          // const isLastTask = i === formik.values.tasks.length - 1;
          return API.put("step-function-rest", "/Prod/step-function", {
            body: {
              clusterName,
              task: {
                ...task,
                participants: participantsToTaskArnMap[task.taskArn] || task.participants
              }
            },
            headers: {},
          })
        })).pipe(
          map(resp => resp),
          mergeMap(resp => {
          // console.log(resp.map((res) => res.task));
          return [
            {
              type: 'clusters/updateCluster/fulfilled',
              payload: {
                ...cluster.data,
                tasks: resp.map((res) => res.task)
              },
              meta: { arg: { clusterName }}
            },
            pushNotification({
              status: StatusVariant.Success,
              message: `Attendance for all ${(participants || []).length} participants for Day ${attendanceIndex+1} has been saved`
            })
          ]
        }))
      // return 
    })
  ),

  (action$, state$: StateObservable<RootState>) => action$.pipe(
    ofType('clusters/startPollingClasses'),
    exhaustMap((action) => {
      return timer(0, REFRESH_INTERVAL).pipe(
        takeUntil(action$.ofType('clusters/stopPollingClasses')),
        map(() => send({action: 'refreshClasses'}))
      )
    })
  ),
  /**
   * Polling Epic: Fetch tasks repetitively until stopPollingTasks action is triggered from React component
   *
   * When do we want to stop polling
   *   1. While user is editing fields on the table UI
   *     State update could break form state.
   *
   * While startPollingTasks action starts with when listed actions are fulfilled.
   * We still want to be able to manually trigger polling to start again from any given point
   * and startPollingTasks action can do that :)
   * @param action$
   */
  (action$, state$: StateObservable<RootState>) => action$.pipe(
    ofType('clusters/startPollingTasks'),
    mergeMap(({payload, meta}) => {
      const {clusterName} = payload;
      return timer(0, REFRESH_INTERVAL).pipe(
        // Fetch data until stopPollingTasks action is triggered from React component.
        takeUntil(action$.ofType('clusters/stopPollingTasks').pipe(
          filter(action => action.payload.clusterName === clusterName)
        )),
        switchMap(() => state$.pipe(
          take(1),
          map(state => {
            if (state.clusters.entities.byClusterName[clusterName as string]) {
              // return loadClusterTasks(state.clusters.entities.byClusterName[clusterName as string].data);
              return send({action: "refreshClass", clusterName })
            } else {
              return { type: "noop" };
            }
          })
        ))
        // below code seem much more performant solution but doesn't work with refreshing icon hmmm...
        // exhaustMap(() => from(API
        //   .get("step-function-rest", "/Prod/step-function?cluster=" + clusterName, null))
        //   .pipe(
        //     map((tasks: TTask[]) => tasks)
        //   )
        // ),
        // // optimizes frequent state changes caused by dispatching async thunk action using interval
        // distinctUntilChanged(isEqual),
        //  // hijack asyncThunk of ./clusters.actions.ts loadClusterTasks()
        // map(tasks => ({type: 'clusters/loadClusterTasks/fulfilled', payload: {clusterName, tasks}}))
      )
    })
  ),

  // // Automatically remove empty class
  // (action$, state$: StateObservable<RootState>) => action$.pipe(
  //   ofType('clusters/loadClusters/fulfilled'),
  //   // filter(({payload, meta}) => !isEqual(lastResponseRef[meta.arg.clusterName], payload.tasks)),
  //   exhaustMap(({payload, meta}) => {
  //     return (payload || []).filter((cluster: TCluster) => cluster.tasks && !cluster.tasks.length)
  //       .map((cluster: TCluster) => ({
  //         type: 'clusters/removeCluster/pending',
  //         meta: { arg: cluster, requestId: "", requestStatus: "pending" }
  //       }));
  //   })
  // ),

  // with this epic we will only update tasks when response is different from last call.
  (action$,state$: StateObservable<RootState>) => action$.pipe(
    ofType('clusters/loadClusterTasks/fulfilled'),
    filter(({payload, meta}) => !isEqual(lastResponseRef[meta.arg.clusterName], payload.tasks)),
    map(({payload, meta}) => {
      const {clusterName, tasks} = payload;
      lastResponseRef[clusterName] = tasks;
      return setClusterTasks({...meta.arg, tasks});
    })
  ),

  // setClusterTasks


  // This seems unnecessary
  // /**
  //  * Adding new cluster has different polling
  //  */
  // action$ => action$.pipe(
  //   ofType(
  //     'clusters/addCluster/fulfilled',
  //   ),
  //   map(({payload, meta}) => {
  //     return {type: 'clusters/startPollingNewClass', payload, meta};
  //   })
  // ),
  
  /** this epic should list state machine */
  // action$ => action$.pipe(
  //   ofType(addCluster.fulfilled),
  //   map(({payload, meta}) => {
  //     return {
  //       type: 'clusters/startPollingNewClass',
  //       payload: {executionId: payload.executionId},
  //       meta
  //     };
  //   })
  // ),

  action$ => action$.pipe(
    ofType(
      'clusters/startPollingNewClass',
    ),
    mergeMap(({payload, meta}) => {
      const { executionId } = payload;
      const { className } = meta.arg;
      return timer(0, 1000).pipe(
        takeUntil(action$.ofType('clusters/stopPollingNewClass')),
        exhaustMap(() => from(API
          .get("step-function-rest", "/Prod/step-function?execution="+executionId, null))
          .pipe(
            mergeMap((response: {status: string; error: string;}) => {
              const {status, error} = response;
              console.log(response);
              // console.log('clusters/startPollingNewClass', response);
              switch (status) {
                case "SUCCEEDED":
                  // history.push('/list-tasks');
                  return of(
                    pushNotification({
                      status: StatusVariant.Success,
                      message: `New class "${className}" successfully created`
                    }),
                    stopPollingNewClass({ executionId }),
                  );
                case "FAILED":
                  return of(
                    pushNotification({
                      status: StatusVariant.Errors,
                      message: (
                        response.error && response.error !== ""
                          ? response.error
                          : "Creation of new class failed, but no error was returned"
                      )
                    }),
                    stopPollingNewClass({ executionId })
                  );
                case "TIMED_OUT":
                  return of(
                    pushNotification({
                      status: StatusVariant.Errors,
                      message: "New class creation failed. Creation operation timed out"
                    }),
                    stopPollingNewClass({ executionId })
                  );
                case "ABORTED":
                  return of(
                    pushNotification({
                      status: StatusVariant.Errors,
                      message: "New class creation failed. Creation operation aborted"
                    }),
                    stopPollingNewClass({ executionId })
                  );
                default:
                  return of({type:'noop'})
              }
            }),
          )
        )
      )
    })
  )
)
