// import { ComponentProps } from 'react';
import { clusterInitialState, ClustersQuickFilter } from '../components/clusters/clusters.slice';
import { TClusterFilterCondition } from '../components/clusters/list/ClustersFilter';
import { TCluster, TTaskDefinition } from '../types/app.types';
import { RootState } from './app.store';
import { CognitoUserAttributeInterface } from './authenticatedUser.slice';

export const versionSelector = (state: RootState) => state.version.value;

export const userRoleSelector = (state: RootState) => (state.auth.attributes as CognitoUserAttributeInterface)['custom:role'] as "user"|"admin" || "user"

export const userEmailSelector = (state: RootState) => (state.auth.attributes as CognitoUserAttributeInterface).email;

export const isMyClassSelector = (state: RootState, cluster: TCluster) => {
  const email = userEmailSelector(state);
  const trainer = cluster.trainer?.email,
        coTrainer = cluster.coTrainer?.email,
        support = cluster.support?.email;
  return trainer === email || coTrainer === email || support === email;
}

// used for refresh icon and other
export const loadingSelector = (state: RootState) => {
	return state.clusters.loading || !!state.clusters.entities.clusterNames
		.filter(clusterName => (state.clusters.entities.byClusterName[clusterName] || clusterInitialState).loading).length
}



// returns statistics of a class
export const statisticsSelector: (state: RootState) => {
  classesCount: number;
  launched: number;
	instancesCount: number;
	assigned: number;
	unassigned: number;
	regular: number;
	shared: number;
	healthy: number;
	unhealthy: number;
	running: number;
	pending: number;
  requested: number;
} = (state: RootState) => {
  const { byClusterName, clusterNames } = state.clusters.entities;
  const byName: Record<string, TTaskDefinition> = state.tasks.byName;

  function _taskDefinitionTypeIs(taskDefinition: string|null, value: string): boolean {
    const tags: Record<string, string> = (
      byName && 
      taskDefinition && 
      byName[taskDefinition] && 
      byName[taskDefinition].tags
    ) || {}
    return !!(tags["type"] && tags["type"] === value);
  }

	return clusterNames.reduce((o, clusterName) => {
    const cluster = byClusterName[clusterName] || clusterInitialState;
		const { assigned, unassigned, healthy, unhealthy, runningTasks, provisioningTasks, total } = cluster.statistics;
		const { taskDefinition, tasks, launched, requestedTasks } = cluster.data;

    const sharedTaskDefinition = _taskDefinitionTypeIs(taskDefinition, "shared");

		o.instancesCount += total;
    o.launched += launched ? 1 : 0;
		o.regular += !sharedTaskDefinition ? 1 : 0;
		o.shared += sharedTaskDefinition ? 1 : 0;
		o.assigned += assigned;
		o.unassigned += unassigned;
		o.healthy += healthy;
		o.unhealthy += unhealthy;
		o.running += runningTasks;
		o.pending += provisioningTasks;
    o.requested += requestedTasks;
		return o;
	}, {
		classesCount: clusterNames.length,
    launched: 0,
		regular: 0,
		shared: 0,
		instancesCount: 0,
		assigned: 0,
		unassigned: 0,
		healthy: 0,
		unhealthy: 0,
		running: 0,
		pending: 0,
    requested: 0,
	})
}


// This is very heavy operation
// perhaps it is best to handle in reducer
export function filteredClustersSelector(state: RootState, props: {
  condition: TClusterFilterCondition;
  quickFilters: ClustersQuickFilter;
}): string[] {
  const {clusterNames, byClusterName} = {...state.clusters.entities};
  const {condition} = props;
  const {onlyMine, inactive, launched, taskDefinitionType} = props.quickFilters;
  const taskDefinitions = groupedTasksListOptionSelector(state);

  let filtered = [...clusterNames];
  const searchText = state.clusters.searchInput.toLowerCase();


  if (!byClusterName || !Object.keys(byClusterName).length) return [];

  const taskDefinitionNameMatch = (item: TCluster) => {
    if (condition.taskDefinition) {
      return item.taskDefinition === condition.taskDefinition
    } else {
      return true;
    }
  }

  const taskDefinitionTypeMatch = (item: TCluster) => {
    if (taskDefinitionType === "all")
      return true;
    else
      return new Set(
        taskDefinitionType === 'regular'
          ? taskDefinitions.default
          : taskDefinitions.shared
      ).has(item.taskDefinition||"");
    // if (taskDefinitionType === 'regular')
    //   return new Set(taskDefinitions.default).has(item.taskDefinition||"");
    // else if (taskDefinitionType === 'shared')
    //   return new Set(taskDefinitions.shared).has(item.taskDefinition||"");
    // else
    //   return true;
  }
  const classNameMatch = (item: TCluster) => {
    if (!condition.className || condition.className === '') return true;
    return item.className.toLowerCase().includes(condition.className.toLowerCase());
  }

  const isMine = (item: TCluster) => {
    // if (!onlyMine) return true;
    const email = state.auth.attributes.email;
    const { trainer, coTrainer, support } = item;
    return (trainer && trainer.email === email)
      || (coTrainer && coTrainer.email === email)
      || (support && support.email === email);
  }

  // this is expensive operation
  const isArchived = (item: TCluster) => {
    if (!inactive) return true;
    return item.tasks.filter(task => task.lastStatus === "STOPPED").length === item.tasks.length;
  }

  const doesFieldIncludesSearchText = (fields: string[]) =>
      !!fields.filter(field => field && field.toLowerCase().includes(searchText)).length;

  // First filter down with quick filter
  if (onlyMine) {
    // console.log('onlyMine');
    filtered = clusterNames.filter(clusterName => {
      const {data} = byClusterName[clusterName];
      // console.log(state.auth.attributes.email, data.trainer?.email, data.coTrainer?.email, data.support?.email, isMine(data))
      return data && isMine(data);
    });
  }

  filtered = filtered.filter(clusterName => {
    const {data} = byClusterName[clusterName];
    if (inactive) {
      return !data.tasks.length || (data && isArchived(data));
    } else {
      return true;
    }
  })

  filtered = filtered.filter(clusterName => {
    return (byClusterName[clusterName] && byClusterName[clusterName].data.launched) === launched;
  })

  if (condition.taskDefinition) {
    filtered = filtered.filter(clusterName => {
      const {data} = byClusterName[clusterName];
      return taskDefinitionNameMatch(data);
    });
  }

  if (taskDefinitionType) {
    // console.log('hasCondition', condition.taskDefinition);
    filtered = filtered.filter(clusterName => {
      const {data} = byClusterName[clusterName];
      // console.log(clusterName, data.className, taskDefinitionMatch(data), classNameMatch(data))
      return data && taskDefinitionTypeMatch(data) && classNameMatch(data);
    });
  }

  // If searchText is empty return what has filtered
  if (!searchText || searchText.trim() === "") return filtered;

  // match searchText
  // console.log('match searchText', searchText);
  const clusterInfoMatch = (item: TCluster) => {
    return doesFieldIncludesSearchText([
      item.className,
      ...(item.trainer ? [item.trainer.email, item.trainer.firstName||"", item.trainer.lastName||""] : []),
      ...(item.coTrainer ? [item.coTrainer.email, item.coTrainer.firstName||"", item.coTrainer.lastName||""] : []),
      ...(item.support ? [item.support.email, item.support.firstName||"", item.support.lastName||""] : []),
    ])
  };

  const hasMatchingTask = (item: TCluster) => !!item.tasks.filter((task) => {
    return doesFieldIncludesSearchText([
      task.publicIPv4Address || "",
      ...(task.participants && task.participants.length
        ? [
          task.participants[0].email || "",
          // TODO: support multiple participants filtering
          task.participants[0].firstName || "",
          task.participants[0].lastName || "",
        ] : [])
      
    ])
  }).length;

  return filtered.filter(clusterName => {
    const {data} = byClusterName[clusterName];
    return clusterInfoMatch(data) || hasMatchingTask(data);
  })
}


export const groupedTasksListOptionSelector = (state: RootState) => {

  // const byName = state.tasks.byName;

  function _taskDefinitionTypeIs(taskDefinition: TTaskDefinition, value: string): boolean {
    const {tags} = taskDefinition;
    return !!(tags && tags["type"] && tags["type"].toLowerCase() === value);
  }

  const data = (state.tasks.data || []).reduce((obj, item) => {
    obj.all = [...obj.all, item.name];
    if (_taskDefinitionTypeIs(item, "shared")) {
      
      obj.shared = [...obj.shared, item.name];
      // save none shared name
      obj.sharedMap[item.name] = item;
    } else {
      obj.default = [...obj.default, item.name];
      obj.nonSharedMap[item.name] = item;
    }
    return obj;
  }, {
    all: [] as string[],
    default: [] as string[],
    shared: [] as string[],
    nonSharedMap: {} as Record<string, TTaskDefinition>,
    sharedMap: {} as Record<string, TTaskDefinition>,
  });
  const sharedDisabled = data.default.filter(name => !data.nonSharedMap[name]);
  return {
    ...data,
    sharedDisabled
  }
}
  
