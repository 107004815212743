import { Dispatch } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useAppDispatch, RootState } from "../../store/app.store";
import { TCluster, TClusterTaskDefinition, TNewCluster, TParticipant, TTask } from "../../types/app.types";
import { addCluster } from "./clusters.actions";
/* istanbul ignore file */
export function getSharedTaskDefinition(taskDefinition: TClusterTaskDefinition): TClusterTaskDefinition {
  if (taskDefinition === 'jira-fundamentals-class')
    return 'jira-fundamentals-class-shared'
  if (taskDefinition === 'jira-advanced-class')
    return 'jira-advanced-class-shared'
  if (taskDefinition === 'pmi-jira-fundamentals-class')
    return 'pmi-jira-fundamentals-class-shared'

  return 'jira-fundamentals-class-shared';
}

// this function collects participants the tasks
export function getParticipants(tasks: TTask[]) {
  return tasks.reduce((o: TParticipant[], task: TTask) => ([
    ...o,
    ...(task.participants || []).map<any>(participant => ({
      ...participant,
    }))
  ]), [] as TParticipant[]);
}


// /**
//  * React hook which handles creating shared instance of existing class
//  */
// export function useShareExistingClass(clusterName: string) {
//   const dispatch:  Dispatch<any> = useAppDispatch();
//   // const {
//   //   className, trainer, coTrainer, support, taskDefinition, startDate, endDate, tasks
//   // } = useSelector((state: RootState) => state.clusters.entities.byClusterName[clusterName].data);

//   const createSharedInstance = (options: TCluster) => {
//     const {className, trainer, coTrainer, support, taskDefinition, startDate, endDate, tasks} = options;
//     dispatch(addCluster({
//       className: `${className} Shared`,
//       trainer: trainer?.username || "",
//       coTrainer: coTrainer?.username || "",
//       support: support?.username || "",
//       taskCount: 1,
//       taskDefinition: getSharedTaskDefinition(taskDefinition as TClusterTaskDefinition),
//       participants: getParticipants(tasks),
//       startDate,
//       endDate,
//       initiatingUser: trainer?.username || "",
//       launchTimestamp: startDate
//     }));
//   }
//   return createSharedInstance;
// }

// /**
//  * React hook which handles creating shared instance of new class
//  */
// export function useShareNewClass(newCluster: TNewCluster) {
//   const dispatch:  Dispatch<any> = useAppDispatch();
//   const createSharedInstance = () => {
//     dispatch(addCluster({
//       ...newCluster,
//       className: `${newCluster.className} Shared`,
//       taskCount: 1,
//       taskDefinition: getSharedTaskDefinition(newCluster.taskDefinition as TClusterTaskDefinition),
//     }));
//   }
//   return createSharedInstance;
// }
