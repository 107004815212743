import React, { useEffect, useRef, useState } from "react";
import { Minus, Plus } from 'react-feather';
import { TParticipant } from "../../../types/app.types";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { TextField, makeStyles } from "@material-ui/core";
import { AttendanceStatus, AttendanceStatusType } from "../../../types/status.types";
import {stableSort,getComparator} from '../../../utils/sort';

export const EMPTY_PARTICIPANT: TParticipant = { firstName: "", lastName: "", email: "", title: "" };
const labelStyle = {
  fontSize: '12px',
  fontWeight: 600,
};

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRow: {
    height: 'fit-content',
    // '&:first-child': {
    //   paddingLeft: 10,
    // }
  },
  tableCell: {
    verticalAlign: 'middle !important',
    '&:first-child': {
      paddingLeft: 15,
    }
  },
  headerCell: {
    fontSize: 12,
    textTransform: 'uppercase',
    '&:first-child': {
      paddingLeft: 15,
    }
  }
}));


type Column = {
  field: string;
  label: string;
  style?: {
    width?: number|string;
    minWidth?: number;
    maxWidth?: number;
    [key: string]: any;
  };
}
const columns: Column[] = [
  { field: 'title', label: 'Title', style: {...labelStyle} },
  { field: 'firstName', label: 'First name', style: {...labelStyle} },
  { field: 'lastName', label: 'Last name', style: {...labelStyle} },
  { field: 'email', label: 'Email', style: { ...labelStyle, width: '40%' } },
];

function EnhancedTableHead<D extends Record<string, any> = any>(props: {
  classes: any;
  order?: 'asc'|'desc';
  orderBy?: keyof D;
  onRequestSort?: (e: any, property: keyof D) => void;
  columns: Column[];
  isEdit?: boolean;
}) {
  const { classes, order, orderBy, onRequestSort, isEdit } = props;
  const createSortHandler = (property: keyof D) => (event: any) => {
    if (onRequestSort) onRequestSort(event, property);
  };

  // useEffect(() => {
  //   console.log('EnhancedTableHead', columns);
  // }, [columns])

  

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
        <TableCell
          key={column.field}
          align={'left'}
          className={classes.headerCell}
          sortDirection={!isEdit && orderBy === column.field ? order : false}
        >
          {!isEdit ?
          <TableSortLabel
            active={orderBy === column.field}
            direction={orderBy === column.field ? order : 'asc'}
            onClick={createSortHandler(column.field)}
          >
            <span className="text-uppercase">{column.label}</span>
            {orderBy === column.field ? (
              <span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
            ) : null}
          </TableSortLabel>
          : <span className="text-uppercase">{column.label}</span>
          }
        </TableCell>
        ))}
        {/** Last column is for action button */}
        {isEdit && <TableCell scope="col" style={{width: '24px'}}>&nbsp;</TableCell>}
      </TableRow>
    </TableHead>
  );
}


// export interface TParticipantAttendanceRow {
//   firstName: string;
//   lastName: string;
//   email: string;
//   title?: string;
//   attendances?: AttendanceStatusType[]; // api doesn't support this yet
//   day1?: AttendanceStatusType;
//   day2?: AttendanceStatusType;
//   day3?: AttendanceStatusType;
//   day4?: AttendanceStatusType;
//   day5?: AttendanceStatusType;
//   day6?: AttendanceStatusType;
//   day7?: AttendanceStatusType;
// }

// const testType1: TParticipantAttendanceRow = {
//   firstName: '', lastName: '', email: '', day1: AttendanceStatus.UNATTENDED
// };

// /** This table focus only on handling participants attendance */
// export const ParticipantAttendanceList: React.FC<any> = ({participants, days}) => {
//   const [order, setOrder] = useState<'asc'|'desc'>('asc');
//   const [orderBy, setOrderBy] = useState<keyof TParticipantAttendanceRow>('email');
//   const tbodyRef = useRef<HTMLTableSectionElement>(null);
//   const triggerRef = useRef<string|null>(null);
//   const classes = useStyles();

//   const handleRequestSort = (event: any, property: keyof TParticipantAttendanceRow) => {
//     const isAsc = orderBy === property && order === 'asc';
//     setOrder(isAsc ? 'desc' : 'asc');
//     setOrderBy(property);
//   };

//   const attendanceColumns: (numberOfDays: number) => Column[] = (numberOfDays) =>
//     numberOfDays ? new Array(numberOfDays).fill(null).map((d,i)=> ({
//       field: `day${i+1}`, label: `Day ${i+1}`, style: {...labelStyle}
//     })) : [];

  
//   const tableColumns = [...columns, ...attendanceColumns(days)];
//   console.log(tableColumns, tableColumns.length);


//   // con
//   // const tableRows: ParticipantAttendanceRow[] = participants.map((participant: TParticipant) => ({
//   //   ...participant,
//   //   ...(participant.attendances || []).reduce((
//   //     o: Record<string, AttendanceStatusType>,
//   //     d: AttendanceStatusType,
//   //     i: number
//   //   ) => {
//   //     o[`day-${i+1}`] = d;
//   //     return o;
//   //   }, {} as Record<string, AttendanceStatusType>)
//   // }))
//   const tableHeader = () => <EnhancedTableHead classes={classes}
//     order={order}
//     orderBy={orderBy}
//     onRequestSort={handleRequestSort}
//     columns={tableColumns}
//     />
//   return (
//     <Table className="table" size="small" >
//       {tableHeader()}
//       <TableBody ref={tbodyRef}>
//         {participants && participants.length && stableSort<TParticipantAttendanceRow>(participants, getComparator(order, orderBy))
//         .map((participant: TParticipantAttendanceRow, i: number) =>
//         <TableRow key={i}>
//           <TableCell className="p-1">
//             <div className="w-100">{participant.title}</div>
//           </TableCell>
//           <TableCell className="p-1">
//             <div className="w-100">{participant.firstName}</div>
//           </TableCell>
//           <TableCell className="p-1">
//             <div className="w-100">{participant.lastName}</div>
//           </TableCell>
//           <TableCell className="p-1">
//             <div className="w-100">{participant.email}</div>
//           </TableCell>
//           {participant.attendances?.map((attendance, i) =>
//             <TableCell className="p-1" key={i}>
//               <div className="w-100">{attendance}</div>
//             </TableCell>
//           )}
//         </TableRow>
//         )}
//       </TableBody>
//     </Table>
//   )
// }

export type ParticipantsListProp = {
  formik: any;
  count?: number;
  isEdit?: boolean;
  setParticipantCount?: any;
  setParticipants?: (val: TParticipant[]) => void;
}

/**
 * This component lists participants as editable table
 * It was extracted from
 * https://github.com/arielpartners/training-image-manager/blob/d7c11b4d6ac34a39f6c4db9f2202a5a8cc1803b7/app/webapp/src/routes/startTasks/startTask.js
 */
const ParticipantsList: React.FC<ParticipantsListProp> = ({formik, isEdit, count, setParticipants, setParticipantCount}) => {

  const [order, setOrder] = useState<'asc'|'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof TParticipant>('email');
  const tbodyRef = useRef<HTMLTableSectionElement>(null);

  const triggerRef = useRef<string|null>(null);

  const classes = useStyles();

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {

    // Limit this behavior to event triggered by INPUT field
    if ((e.target as any).tagName === 'INPUT' && e.key === 'Enter') {
      // prevent from "Enter" key submitting form
      e.preventDefault();
      if (setParticipants) setParticipants([...formik.values.participants, EMPTY_PARTICIPANT]);
      triggerRef.current = 'participant-control'
      if (setParticipantCount) setParticipantCount((val: number) => val+1);

      // console.log(tbodyRef.current)
    }
  }

  const handleRequestSort = (event: any, property: keyof TParticipant) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const hasError = (i: number) => {
    return !!getError(i);
  }

  const getError = (i: number) => {
    return formik.touched.participants && formik.touched.participants[i] && formik.errors.participants && formik.errors.participants[i] && formik.errors.participants[i].email;
  }

  useEffect(() => {
    const lastInput: HTMLInputElement = (tbodyRef.current as any).lastChild.firstChild.firstChild.querySelector('input');

    // console.log(lastInput)

    // prevent last input focus when number input updates instance fields
    if (triggerRef.current === 'participant-control')
      lastInput.focus();

  }, [count])

  return (
    <Table data-testid="participants-list-table" className="table" size="small" >
      <EnhancedTableHead classes={classes}
          order={order}
          orderBy={orderBy}
          isEdit={isEdit}
          onRequestSort={handleRequestSort}
          columns={columns}
          />
      <TableBody ref={tbodyRef}>
        {formik.values && formik.values.participants.length &&
        (isEdit
          ? formik.values.participants
          : stableSort<TParticipant>(formik.values.participants, getComparator(order, orderBy))
        )
        .map((participant: TParticipant, i: number) =>
        <TableRow key={i}>
          <TableCell className="p-1">
            {isEdit ? <TextField name={`participants[${i}].title`}
              variant="outlined"
              type="text"
              fullWidth
              onKeyDown={handleKeyDown}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={participant.title}
              inputProps={{style: { padding: '0.6rem 1rem' }}}
            /> : participant.title !== ""
            ? <div className="w-100">{participant.title}</div>
            : <div className="w-100 text-center">-</div>
            }
          </TableCell>
          <TableCell className="p-1">
            {isEdit ? <TextField name={`participants[${i}].firstName`}
              type="text"
              variant="outlined"
              fullWidth
              onKeyDown={handleKeyDown}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={participant.firstName}
              inputProps={{style: { padding: '0.6rem 1rem' }}}
            /> : participant.firstName !== ""
            ? <div className="w-100">{participant.firstName}</div>
            : <div className="w-100 text-center">-</div>
            }
          </TableCell>
          <TableCell className="p-1">
            {isEdit ? <TextField name={`participants[${i}].lastName`}
              type="text"
              variant="outlined"
              fullWidth
              onKeyDown={handleKeyDown}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={participant.lastName}
              inputProps={{style: { padding: '0.6rem 1rem' }}}
            /> : participant.lastName !== ""
            ? <div className="w-100">{participant.lastName}</div>
            : <div className="w-100 text-center">-</div>
            }
          </TableCell>
          <TableCell className="p-1">
            {isEdit ? <TextField name={`participants[${i}].email`}
              type="text"
              variant="outlined"
              fullWidth
              onKeyDown={handleKeyDown}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={participant.email}
              error={hasError(i)}
              inputProps={{style: { padding: '0.6rem 1rem' }}}
              helperText={getError(i)}
            /> : participant.email !== ""
            ? <div className="w-100">{participant.email}</div>
            : <div className="w-100 text-center">-</div>
            }
          </TableCell>
        </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
export default ParticipantsList;
