/* istanbul ignore file */
// https://github.com/arielpartners/training-image-manager/issues/87

/** hours defined by this constant is used for auto start option set on class */
export const AUTO_START_HOURS_BEFORE_CLASS = 2;

/** hours defined by this constant is used for auto-destroy option set on class */
export const AUTO_DESTROY_ADD_HOURS = 2;
/** days defined by this constant is used for manual-destroy option set on class */
export const MAXIMUM_EXPIRATION_DAYS = 7;

export const END_DATE_HELPER_TEXT_ON_MANUAL_TERMINATION =
  `Trainer, co-trainer, support are responsible to delete this class manually after class ends.`
export const END_DATE_HELPER_TEXT_ON_AUTO_TERMINATION =
  `This class will be automatically destroyed ${AUTO_DESTROY_ADD_HOURS} hours after class ends.`

export const REFRESH_INTERVAL = 5000;
export const METRIC_REFRESH_INTERVAL = 30000;
export const NOTIFICATION_DURATION = 6000;
export const STATUS_ICON_SIZE = {
  NOTIFICATION: 20,
  SECTION_MESSAGE: 24
}
export const STATUS_VARIANT = {
  /**
   * Confirmation section messages let the user know that an action or event has happened successfully.
   * They are not a common use case for section messages.
   */
  CONFIRMATION: 'success',
  /**
   * Warning section messages are the most common. They help users:
   * - avoid errors
   * - manage authentication issues
   * - take the actions needed to avoid potentially dangerous actions
   * - feel certain they're making the decision, for example, in confirmation modals.
   */
  WARNING: 'warning',
  /**
   * Error section messages let the user know when:
   * - something destructive or critical has happened
   * - access has been denied
   * - there are connectivity issues.
   * They are not a common use case for section messages.
   */
  ERROR: 'danger',
  INFO: 'info',
  /**
   * Change section messages let the user know about new features or for onboarding.
   * They are not a common use case for section messages.
   */
  CHANGE: 'help'
}

export const STATUS_VARIANT_META = {
  [STATUS_VARIANT.CONFIRMATION]: {
    iconColor: 'rgb(0, 102, 68)',
  },
  [STATUS_VARIANT.WARNING]: {
    iconColor: 'rgb(255, 139, 0)',
  },
  warnings: {
    iconColor: 'rgb(255, 139, 0)',
  },
  [STATUS_VARIANT.ERROR]: {
    iconColor: 'rgb(0, 102, 68)',
  },
  [STATUS_VARIANT.INFO]: {
    iconColor: 'rgb(7, 71, 166)',
  },
  [STATUS_VARIANT.CHANGE]: {
    iconColor: 'rgb(64, 50, 148)',
  }
}
