import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '@aws-amplify/api';
import { TCluster, TClusterForm, TClusterTaskDefinition, TNewCluster, TTask } from '../../types/app.types';
import { APIError } from '../../types/common.types';
import store from '../../store/app.store';
import formatISO from 'date-fns/formatISO'
import { addDays, addHours, format } from 'date-fns';
import { AUTO_DESTROY_ADD_HOURS, MAXIMUM_EXPIRATION_DAYS } from '../../constants/app.constants';

// INPUT
// arn:aws:ecs:us-west-1:653124158642:task/fd8c4224-8059-417d-9860-94fc1dd9b839/026dd241a9584918bbecbf7994a134a0
// OUTPUT
// 026dd241a9584918bbecbf7994a134a0
export function parseTaskId (taskArn: string) {
  const parsed = taskArn.split('/');
  return parsed[parsed.length - 1];
}

export const loadClusters = createAsyncThunk<TCluster[], void, { rejectValue: APIError }>(
  'clusters/loadClusters',
  async (arg, thunkAPI) => {
    let clusters: TCluster[];
    try {
      clusters = await API.get("step-function-rest", "/Prod/step-function?withTasks=true", null)
        .then((clusters: TCluster[]) => clusters)
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'loadClusters',
        message: "Failed to load clusters."
      })
    }
    return clusters;
  }
);


export const listStateMachine = createAsyncThunk<any, {executionId: string}, { rejectValue: APIError }>(
  'clusters/listStateMachine',
  async (arg, thunkAPI) => {
    const {executionId} = arg;
    let res: any;
    try {
      res = await  API.get("step-function-rest", `/Prod/step-function?execution=${executionId}`, null)
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'listStateMachine',
        message: `Failed to list StateMachine of ${executionId}`
      })
    }
  }
)

export const addCluster = createAsyncThunk<{ executionId: string, message: string }, TNewCluster, { rejectValue: APIError }>(
  'clusters/addCluster',
  async (arg, thunkAPI) => {
    let res: { error: string; ret: string; clusterName: string };

    const terminateAt = formatISO(addHours(new Date(arg.endDate), AUTO_DESTROY_ADD_HOURS));
    const launchTimestamp = formatISO(new Date(arg.launchTimestamp));

    try {
      res = await API.post("step-function-rest", "/Prod/step-function", {
        body: {...arg,
          startDate: formatISO(new Date(arg.startDate)),
          endDate: formatISO(new Date(arg.endDate)),
          launchTimestamp,
        },
        headers: {}
      })
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'addCluster',
        message: "Failed to add new cluster."
      });
    }
    // console.log(res)
    const responseSplit = res.ret.split(":");
    const message = [
      `${arg.taskCount} instances were initially requested for "${arg.className}".<br/>`,
      `Launch scheduled at ${format(new Date(launchTimestamp), 'EEEE, MMMM d, yyyy hh:mm aa')}<br/>`,
      // we need to display following log when termination is scheduled.
      (arg.scheduleTermination
        ? `and terminate at ${format(new Date(terminateAt), 'EEEE, MMMM d, yyyy hh:mm aa')}`
        : "")
    ].join("");
    // console.log(message,
    //   `Create class initiated for "${arg.className}".<br/>`,
    //   `Launch scheduled at ${format(new Date(launchTimestamp), 'EEEE, MMMM d, yyyy hh:mm aa')}<br/>`,
    //   (arg.scheduleTermination
    //     ? `and terminate at ${format(new Date(terminateAt), 'EEEE, MMMM d, yyyy hh:mm aa')}`
    //     : ""))
    return {
      executionId: responseSplit[responseSplit.length - 1],
      clusterName: res.clusterName,
      message
    };
  }
)

export type RemoveClusterArgs = {
  cluster: TCluster;
  terminateImmediately: boolean;
  terminateAt: string;
  silent?: boolean
};
export const removeCluster = createAsyncThunk<
  { clusterName: string, message: string },
  RemoveClusterArgs,
  { rejectValue: APIError }
>(
  'clusters/removeCluster',
  async (arg, thunkAPI) => {
    const {clusterName, className, tasks} = arg.cluster;
    const {terminateImmediately, terminateAt} = arg;
    try {
      await API.del("step-function-rest", "/Prod/step-function", {
        body: {
          clusterName,
          taskArns: (tasks || []).map(item => item.taskArn),
          terminateAllTasks: true,
          terminateTimestamp: terminateImmediately
            ? formatISO(new Date())
            : formatISO(new Date(terminateAt))
        },
        headers: {},
      })
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'removeCluster',
        message: `Failed to initiate removal for class "${className}"`
      })
    }
    return {
      clusterName,
      message: `Remove class initiated for class "${className}"`
    };
  }
);

/* istanbul ignore next */
export const getTerminateAt = (scheduleTermination: boolean|undefined, endDate: string) => scheduleTermination
        ? addHours(new Date(endDate), AUTO_DESTROY_ADD_HOURS)
        : addDays(new Date(endDate), MAXIMUM_EXPIRATION_DAYS);

type UpdateClusterReturned = TCluster & { message?: string };
export const updateCluster = createAsyncThunk<UpdateClusterReturned, TClusterForm, { rejectValue: APIError }>(
  'clusters/updateCluster',
  async (cluster, thunkAPI) => {
    const {clusterName, className, trainer, coTrainer, support, startDate, endDate, launchTimestamp, launched, scheduleTermination} = cluster;
    let res: TCluster;
    const terminateAt = getTerminateAt(scheduleTermination, endDate);

    try {
      res = await API.put("step-function-rest", "/Prod/step-function?action=updateClass", {
        body: {
          clusterName, className, trainer, coTrainer, support,
          startDate: formatISO(new Date(startDate)),
          endDate: formatISO(new Date(endDate)),
          launchTimestamp: formatISO(new Date(launchTimestamp)),
        },
        headers: {},
      })
      .then(response => {
        // Currently response comes with empty email,
        // for now we can use users data from redux to replace emails back
        // let's wait for backend change.
        const users = store.getState().users.normalized.byUsername;
        // console.log("STATE\n", store.getState())
        return {...cluster,
          className: response.className,
          trainer: response.trainer &&
            response.trainer.username &&
            users[response.trainer.username] || null,
          coTrainer: response.coTrainer &&
            response.coTrainer.username &&
            users[response.coTrainer.username] || null,
          support: response.support &&
            response.support.username &&
            users[response.support.username] || null,
          startDate,
          endDate,
          launchTimestamp,
        } as TCluster;
      })
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'updateCluster',
        message: `Failed to update for class "${className}"`
      })
    }
    return {...res,
      message: (
        `Updated successfully for class "${className}".<br/>`+
        (launched ? "Already launched " : "Launch scheduled at ") +
        format(new Date(launchTimestamp), 'EEEE, MMMM d, yyyy hh:mm aa') + " " +
        (scheduleTermination ? `and terminate at ${format(new Date(terminateAt), 'EEEE, MMMM d, yyyy hh:mm aa')}` : "")
      )
    };
  }
);

export const addClusterTask = createAsyncThunk<
  { clusterName: string, taskArn: string, message: string },
  TCluster,
  { rejectValue: APIError }>(
  'clusters/addClusterTask',
  async (cluster, thunkAPI) => {
    const {clusterName, className, taskDefinition} = cluster;
    let res: {error: string; ret: string};
    try {
      res = await API.post("step-function-rest", "/Prod/step-function", {
        body: {
          clusterName, className, taskDefinition,
          taskCount: 1,
          newTask: true
        },
        headers: {},
      })
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'addClusterTask',
        message: `Failed to add task to class "${className}"`
      })
    }
    return {
      clusterName,
      taskArn: res.ret,
      message: `Create task initiated for class "${className}"`
    };
  }
);

export const updateClusterTask = createAsyncThunk<
  {clusterName: string, task: TTask, message: string|null},
  {
    clusterName: string,
    className: string,
    task: TTask,
    silent?: boolean,
    messages?: {onSucceed?: string, onFailed?: string}|null
  },
  { rejectValue: APIError }
>(
  'clusters/updateClusterTask',
  async (cluster, thunkAPI) => {
    const {clusterName, className, task, messages, silent} = cluster;
    let res: { clusterName: string; task: TTask; };
    try {
      res = await API.put("step-function-rest", "/Prod/step-function", {
        body: { clusterName: clusterName, task },
        headers: {},
      })
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'updateClusterTask',
        message: silent ? null : messages && messages.onFailed ? messages.onFailed : `Failed to update task to class "${className}"`
      })
    }
    return {
      clusterName,
      task: res.task,
      message: silent ? null : messages && messages.onSucceed ? messages.onSucceed : `Updated task [${task.publicIPv4Address}]for class "${className}"`
    };
  }
)

export const stopClusterTask = createAsyncThunk<
  { clusterName: string, taskArn: string },
  { clusterName: string, className: string, task: TTask, isRestart?: boolean },
  { rejectValue: APIError }
>(
  'clusters/stopClusterTask',
  async (payload, thunkAPI) => {
    const { clusterName, className, task, isRestart } = payload;
    let res: {error: string; ret: string};
    try {
      res = await API.del("step-function-rest", "/Prod/step-function", {
        body: { clusterName, taskArns:[task.taskArn], isRestart },
        headers: {},
      })
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'stopClusterTask',
        message: `Failed to delete task to class "${className}"`
      })
    }
    return {
      clusterName,
      taskArn: task.taskArn,
      message: `Delete task initiated for class "${className}"`
    };
  }
)

export const restartClusterTask = createAsyncThunk<
  { clusterName: string, taskArn: string, message: string },
  { clusterName: string, className: string, task: TTask, taskDefinition: TClusterTaskDefinition },
  { rejectValue: APIError }
>(
  'clusters/restartClusterTask',
  async (cluster, thunkAPI) => {
    const {clusterName, className, task, taskDefinition} = cluster;
    let res: {error: string; ret: string};
    try {
      res = await API.post("step-function-rest", "/Prod/step-function", {
        body: {
          clusterName,
          taskDefinition: taskDefinition,
          taskCount: 1,
          participants: task.participants,
          replacementFor: task.taskArn,
        },
        headers: {},
      })
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'restartClusterTask',
        message: `Failed to restart task to class "${className}"`
      })
    }
    // console.log('restartClusterTask', res);
    return {
      clusterName, 
      taskArn: task.taskArn,
      message: `Restart task initiated for class "${className}"`
    };
  }
)


export const loadClusterTasks = createAsyncThunk<
  { clusterName: string, tasks: TTask[] },
  TCluster,
  { rejectValue: APIError }
>(
  'clusters/loadClusterTasks',
  async (cluster, thunkAPI) => {
    const {clusterName, className} = cluster;
    let tasks: TTask[];
    try {
      tasks = await API.get("step-function-rest", "/Prod/step-function?cluster=" + clusterName, null)
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'loadClusterTasks',
        message: `Failed to load tasks for class "${className}"`
      })
    }
    return {clusterName, tasks};
  }
);


// export const 
