import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import {
  // BrowserRouter as Router,
  HashRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect
} from "react-router-dom";
import Amplify from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import CssBaseline from '@material-ui/core/CssBaseline';
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { List as ListIcon, Play, Users as UsersIcon , Archive as ArchiveIcon } from 'react-feather';
import ClassFormPage from './routes/class/new';
import V2ClassListPage from './routes/class/list';
import { withAuthenticator } from '@aws-amplify/ui-react';
import awsExports from "./constants/aws-exports";
import GlobalNotification from './components/notification/GlobalNotification';
import { useAppDispatch } from './store/app.store';
import { setUser, amplifyAuthUserToState, AuthenticatedUserState } from './store/authenticatedUser.slice';
import UserManagementPage from './routes/users';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { blue, grey, pink } from '@material-ui/core/colors';
import { AppBar, Collapse, Divider, Drawer, Hidden, IconButton, List, ListItem, Toolbar, Typography } from '@material-ui/core';
import logo from './assets/logo-white.svg';
import { ChevronLeft, ExitToApp, Menu as MenuIcon, PollOutlined as StatIcon } from '@material-ui/icons';
import ClassDetailPage from './routes/class/detail';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';
import AuditPage from './routes/audit';
import { connect } from '@giantmachines/redux-websocket';
import { useSelector } from 'react-redux';
import { userRoleSelector, versionSelector } from './store/selectors';
import {config} from './constants/constants';
import { getVersion } from './store/version.slice';
import { getTaskDefinitions } from './components/task-definitions/taskList.slice';
import TasksPage from './routes/tasks';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibraryOutlined';
import { getTaskImages } from './store/images.slice';

// Amplify not working in production - get blank white screen
// https://github.com/aws-amplify/amplify-js/issues/5458#issuecomment-633158934
Amplify.register(Auth);
Amplify.configure(awsExports);
Auth.configure(awsExports);


const theme = createTheme({
  palette: {
    primary: {
      main: blue[700],
    },
    secondary: {
      main: pink[500],
    },
  },
});

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  navLink: {
    color: grey[900],
    fontWeight: 500,
    '&.active': {
      color: blue[700],
    },
    '&:hover': {
      textDecoration: 'unset'
    }
  },
  signOutButton: {
    backgroundColor: 'transparent !important'
  },
  logoLink: {
    display: 'flex',
    justifyContent: 'start',
    textDecoration: 'unset !important',
    fontSize: 16,
    fontWeight: 500
  },
  
  appBar: {
    backgroundColor: '#343a40',
    height: 52,
    minHeight: '52px !important',
    // zIndex: 1201,
    transition: theme.transitions.create(['height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    height: 104,
    transition: theme.transitions.create(['height'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },


  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  
  mainShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },

  appToolbar: {
    ...theme.mixins.toolbar,
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    height: 52,
    minHeight: '52px !important',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    }
  },

  statToolbar: {
    ...theme.mixins.toolbar,
    height: 52,
    minHeight: '52px !important',
    backgroundColor: '#636363'
  },
  menuButton: {
    // marginRight: theme.spacing(2),
    // [theme.breakpoints.up('md')]: {
    //   display: 'none',
    // },
  },
  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar,
    height: 52,
    minHeight: '52px !important',
  },
  hide: { display: 'none' },
  main: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  content: { padding: 0 },
}));

const useScrollTopStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const ScrollTop: React.FC<any> = (props) => {
  const { children, window } = props;
  const classes = useScrollTopStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: any) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#app');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

// const signOutButton = (
//   <ListItem button onClick={() => Auth.signOut()}>
//     <ExitToApp />
//     <span className="ml-2">Sign out</span>
//   </ListItem>
// );

export function App(props: any) {
  const { window } = props;
  const classes = useStyles();
  const [authState, setAuthState] = useState<AuthState|null>(null);
  const [user, setAuthUser] = React.useState<AuthenticatedUserState|null>(null);
  const dispatch = useAppDispatch();
  const role = useSelector(userRoleSelector);
  const version = useSelector(versionSelector);

  const [showSecondaryToolbar, setSecondaryToolbarOpen] = useState<boolean>(false);
  const toggleSecondaryToolbarOpen = () => {
		setSecondaryToolbarOpen(!showSecondaryToolbar);
	};

  const signOut = () => {
    Auth.signOut();
    setAuthState(null);
    setAuthUser(null);
  }

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleDrawerOpen = () => {
    setMobileOpen(true);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeft />
        </IconButton>
        <Typography variant="caption">
          v{version || ""}
        </Typography>
      </div>
      <Divider />
      <List>
        <ListItem button onClick={handleDrawerClose} disabled={role === 'user'}>
          <NavLink className={classes.navLink} to="/new-class">
            <span className="feather mr-2"><Play size={16} /></span> Start Tasks
          </NavLink>
        </ListItem>
        <ListItem button onClick={handleDrawerClose}>
          <NavLink className={classes.navLink} to="/list-tasks">
            <span className="feather mr-2"><ListIcon size={16} /></span> List Classes
          </NavLink>
        </ListItem>
        <ListItem button onClick={handleDrawerClose}>
          <NavLink className={classes.navLink} to="/users">
            <span className="feather mr-2"><UsersIcon size={16} /></span> Manage Users
          </NavLink>
        </ListItem>
        <ListItem button onClick={handleDrawerClose}>
          <NavLink className={classes.navLink} to="/audit">
            <span className="feather mr-2"><ArchiveIcon size={16} /></span> Audit Trail
          </NavLink>
        </ListItem>
        {role === "admin" ?
        <ListItem button onClick={handleDrawerClose}>
          <NavLink className={classes.navLink} to="/tasks">
            <span className="feather mr-2" style={{
              marginRight: '0.3rem !important'
            }}><PhotoLibraryIcon fontSize="small" color="inherit" /></span> Task Definitions
          </NavLink>
        </ListItem> :
        <></>
        }
      </List>
      {/** Hide signout button on side drawer responsive breakpoint md up */}
      <Hidden mdUp implementation="css">
        
        <Divider />
        <List>
          <ListItem button onClick={signOut}>
            <ExitToApp />
            <span className="ml-2">Sign out</span>
          </ListItem>
        </List>
      </Hidden>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  useEffect(() => {
    if (user) {
      dispatch(connect(
        `${config.websocket}?Auth=${user.signInUserSession.idToken.jwtToken}`
      ));
      
    }
  }, [user])



  useEffect(() => {
    dispatch(getTaskDefinitions({includeResourceDetails: true}));
    dispatch(getTaskImages());
    onAuthUIStateChange((nextAuthState, authData: any) => {
      // console.log("onAuthUIStateChange", nextAuthState);
      setAuthState(nextAuthState);
      if (authData) {
        dispatch(setUser(amplifyAuthUserToState(authData)));
        setAuthUser(authData);
        dispatch(getVersion() as any);
      }
      
    });

    
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <ThemeProvider theme={theme}>
      <Router>
        <div id="app" className={classes.root}>
          <CssBaseline />
          <GlobalNotification />
          <AppBar position="fixed"
            className={showSecondaryToolbar ? clsx(classes.appBarShift) : clsx(classes.appBar)}
          >
            <Toolbar className={classes.appToolbar} variant="dense">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerOpen}
                className={clsx(classes.menuButton)}>
                <MenuIcon />
              </IconButton>
              <div className="d-flex justify-content-start align-items-center" style={{flexGrow: 1}}>
                <a className={classes.logoLink}	href="/">
                  <img src={logo} className="mr-0 mr-md-2" alt="logo" style={{
                    width: "30px"
                  }} />
                  <span className="text-light">
                    Training Image Manager
                  </span>
                </a>
                <Hidden smDown implementation="css">
                  <div className="d-flex align-items-center">
                  <List>
                    <ListItem button disabled={role === 'user'}>
                      <NavLink className="text-light text-decoration-none" to="/new-class" style={{fontWeight: 400}}>
                        Start Tasks
                      </NavLink>
                    </ListItem>
                  </List>
                  <Typography variant="caption">
                    v{version || ""}
                  </Typography>
                  </div>
                  
                </Hidden>
                {/* <Hidden mdDown implementation="css">
                  <StatisticOverview statistics={statistics} />
                </Hidden> */}
                
              </div>
              {/* <Tooltip title={showStats ? "Hide statistics" : "Show statistics"}>
                <IconButton color="inherit"
                  aria-label="open drawer"
                  onClick={() => toggleStatOpen()}>
                  <StatIcon />
                </IconButton>
              </Tooltip> */}
              <Hidden smDown implementation="css">
                <div className="d-flex justify-content-end align-items-center">
                {(user ?
                <Typography variant="body1" component="div">
                  Welcome, {user?.attributes.email}
                </Typography> : <></>
                )}
                <List>
                  <ListItem button onClick={signOut}>
                    <ExitToApp />
                    <span className="ml-2">Sign out</span>
                  </ListItem>
                </List>
                </div>
              </Hidden>
            </Toolbar>
            <Collapse in={showSecondaryToolbar}>
              <Toolbar className={classes.statToolbar} variant="dense">
                {/* <StatisticOverview statistics={statistics} /> */}
              </Toolbar>
            </Collapse>
            {/* <Hidden mdDown implementation="css">
              <Toolbar className={classes.appToolbar} variant="dense">
                <StatisticOverview statistics={statistics} />
              </Toolbar>
            </Hidden> */}
            
          </AppBar>
          
          {/* <AppHeader /> */}
          <nav className={classes.drawer} aria-label="mailbox folders">
            <Drawer
              container={container}
              variant="persistent"
              anchor="left"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </nav>
          <main className={clsx(classes.main, {
            [classes.mainShift]: mobileOpen
          })} role="main">
            <div className={classes.toolbar} />
            {/* <div className={classes.toolbar} /> */}
            <div className={classes.content}>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/new-class" />
                </Route>

                <Route path="/list-tasks">
                  {/* <ClassListPage /> */}
                  <V2ClassListPage />
                </Route>
                <Route path="/class/:clusterName">
                  <ClassDetailPage />
                </Route>
                <Route path="/new-class">
                  <ClassFormPage />
                </Route>

                <Route path="/users">
                  <UserManagementPage />
                </Route>

                <Route path="/users">
                  <UserManagementPage />
                </Route>

                <Route path="/audit">
                  <AuditPage />
                </Route>

                <Route path="/tasks">
                  <TasksPage />
                </Route>
              </Switch>
            </div>
          </main>
          <ScrollTop {...props}>
            <Fab color="secondary" size="small" aria-label="scroll back to top">
              <KeyboardArrowUpIcon />
            </Fab>
          </ScrollTop>
        </div>
      </Router>
    </ThemeProvider>
  ) : (
    <AmplifyAuthenticator>
			<AmplifySignIn slot="sign-in" hideSignUp></AmplifySignIn>
		</AmplifyAuthenticator>
		
	);
}

export default withAuthenticator(App);
