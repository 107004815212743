import React, { useRef, useEffect, useState } from "react";
import { Save, RotateCcw } from "react-feather";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Input, { InputProps } from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  view: {
    color: `${theme.palette.grey[900]} !important`,
    cursor: 'edit',
    '&:before':{
      borderBottomStyle: 'solid !important'
    }
  }
}))

export type InlineEditInputPropType = Pick<InputProps,
  "multiline" | "rows" | "rowsMax" | "startAdornment" | "disabled" | "placeholder" | "style"
> & {
  id?: string;
  dataTestId?: string
  label?: string;
  type?: string;
  defaultValue?: any;
  disabled?: boolean;
  onSave?: (value: any) => void;
  onCancel?: () => void;
  validate?: (value: any) => Record<string, string>;
  helperText?: any;
  viewField?: React.ReactNode,
  viewFieldStyle?: any;
  viewFieldPlaceholder?: string;
}
const InlineEditInput: React.FC<InlineEditInputPropType> = ({
  id, dataTestId, label, type, defaultValue, disabled, onSave, onCancel,
  validate,
  viewField, startAdornment, helperText,
  viewFieldStyle, placeholder, viewFieldPlaceholder,
  style, multiline, rows, rowsMax
}) => {
  const [editing, setState] = useState(false);
  const inputRef = useRef<HTMLInputElement|null>(null);
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState<Record<string, string>>({});

  const hasError = () => !!Object.keys(error).length;

  // validate = validate || ((v: any) => ({}));
  const classes = useStyles();

  const [isEmpty, setIsEmpty] = useState(!(defaultValue && defaultValue !== ''));

  const getHelperText: () => React.ReactNode = () => {
    if (!helperText) return <></>;
    return helperText({error});
  }

  const handleChange = (e: any) => {
    if (!validate) {
      setValue(e.target.value);
      return;
    }
    const validateResult = validate(e.target.value);
    // console.log(validateResult);
    setError(validateResult);
    setValue(e.target.value);
  };

  const handleMouseDown = (event: any) => {
    event.preventDefault();
  };

  const handleKeyDown = (action: (event: any) => void) => (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      action(e)
    }
  }
  const handleSaveClick = (e: any) => {
    e.preventDefault();
    setState(false);
    
    if (inputRef.current) {
      setValue(inputRef.current.value)
      setIsEmpty(inputRef.current.value === '')
      // console.log('handleSaveClick', inputRef.current.value, defaultValue)
      if (inputRef.current.value !== defaultValue && onSave)
        onSave(inputRef.current.value);
    }
    
  }
  const handleCancelClick = (e: any) => {
    e.preventDefault();
    setState(false);
    // console.log("A - ", inputRef.current.value);
    // console.log("B - ", defaultValue);
    if (inputRef.current && inputRef.current.value !== defaultValue) {
      inputRef.current.value = defaultValue || "";
      setValue(inputRef.current.value);
    }
    // if (value !== defaultValue) setValue(defaultValue);
    if (onCancel) onCancel();
  }
  const onViewModeFieldClick = (e: any) => {
    e.preventDefault();
    // console.log("onViewModeFieldClick");
    setState(true);
  }

  // Side effect for whenever, editing is set to true
  // Focus to input field
  useEffect(() => {
    if (inputRef.current && editing) inputRef.current.focus();
  }, [editing])

  useEffect(() => {
    // console.log('defaultValue', defaultValue)
    setValue(defaultValue);
  }, [defaultValue])

  return (
    <div data-testid={dataTestId}>
      <div data-testid="inline-view-wrapper" className="inline-view-wrapper w-100" onClick={onViewModeFieldClick}
        style={{display: editing ? 'none' : 'block'}}>
        {viewField ? (viewField) : (
          <>
            {(label && <InputLabel htmlFor={id}>{label}</InputLabel>)}
            <Input data-testid="inline-view-input" className={classes.view} disabled
              fullWidth
              multiline={multiline}
              rows={rows}
              rowsMax={rowsMax}
              value={value}
              style={viewFieldStyle && {...viewFieldStyle}}
              startAdornment={startAdornment}
              placeholder={viewFieldPlaceholder} />
          </>
        )}
      </div>
      <FormControl data-testid="inline-edit-control"
        className="w-100"
        style={{ display: editing ? 'flex' : 'none' }}>
        {(label && <InputLabel data-testid="inline-edit-label" htmlFor={id}>{label}</InputLabel>)}
        <Input
          data-testid="inline-edit-input"
          id={id}
          className="w-100"
          type={type || "text"}
          inputRef={inputRef}
          value={value}
          multiline={multiline}
          minRows={rows}
          maxRows={rowsMax}
          disabled={disabled}
          placeholder={placeholder}
          onKeyDown={handleKeyDown(handleSaveClick)}
          onChange={handleChange}
          startAdornment={startAdornment}
          style={style}
          endAdornment={
          <InputAdornment position="end">
            <IconButton
              data-testid="inline-edit-save-btn"
              disabled={hasError()}
              aria-label="save value"
              onClick={(e) => handleSaveClick(e)}
              onKeyDown={handleKeyDown(handleSaveClick)}
              onMouseDown={e => handleMouseDown(e)}
            >
              <Save size={16} />
            </IconButton>
            <IconButton
              data-testid="inline-edit-cancel-btn"
              aria-label="cancel"
              onClick={e => handleCancelClick(e)}
              onKeyDown={handleKeyDown(handleCancelClick)}
              onMouseDown={e => handleMouseDown(e)}
            >
              <RotateCcw size={16} />
            </IconButton>
          </InputAdornment>
        }
        />
        {getHelperText()}
      </FormControl>
    </div>
  )
}

export default InlineEditInput;
