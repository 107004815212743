import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatusVariantType } from '../../types/status.types';


export type NotificationState = {
  messages: TNotification[];
}

export type TNotification = {
  status: StatusVariantType;
  message: string;
  timestamp?: number;
}

const notificationInitialState: NotificationState = {
  messages: [],
}

/**
 * Global notification handler
 */
const notificationSlice = createSlice({
  name: 'notification',
  initialState: notificationInitialState,
  reducers: {
    pushNotification(state, action: PayloadAction<TNotification>) {
      state.messages = [
        ...state.messages, {
          ...action.payload,
          timestamp: Date.now()
        }
      ];
    },
    removeNotification(state) {
      state.messages = state.messages.slice(1);
    },
    clearNotifications(state) {
      state.messages = [];
    }
  },
});

export const { pushNotification, removeNotification, clearNotifications } = notificationSlice.actions;
export default notificationSlice.reducer;
