// import API from "@aws-amplify/api";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { Epic, ofType } from "redux-observable";
import { APIError } from "../types/common.types";
// import { map, mergeMap } from 'rxjs/operators';
// import { forkJoin } from "rxjs";
import  { format } from 'date-fns-tz';
// import {subDays, startOfMonth, startOfToday, endOfToday, startOfDay, endOfDay} from 'date-fns'
// import { formatDate } from "../utils/formatDate";
// import { TAuditTrailEntry } from "../types/app.types";
import API_MODULE, { GetAuditLogArg, GetAuditLogBetweenArg } from '../api';
import { TAuditTrailEntry } from "../types/app.types";
// import startOfToday from 'date-fns/startOfToday'
// import endOfToday from 'date-fns/endOfToday'


type AuditLogTimeOperator = 'after'|'before'|'afterOrEqual'|'beforeOrEqual'|'equal';
/* istanbul ignore next */
// export const getDateArrays: (payload: any) => any[] = (payload: any) => {
//   const {dateOption, from, to} = payload;

//   // console.log("getDateArrays", payload);
//   switch(dateOption) {
//     case 'Today':
//       return [
//         startOfToday().toISOString(),
//         endOfToday().toISOString(),
//       ]
//     case 'Last 7 Days':
//       return [
//         subDays(startOfToday(), 6).toISOString(),
//         endOfToday().toISOString(),
//         // format(new Date(), "yyyy-MM-dd"),
//         // ...Array(6)
//         //   .fill(null).map((d,i) => format(subDays(new Date(), i+1), "yyyy-MM-dd"))
//       ];
//     case 'Last 30 Days':
//       return [
//         subDays(startOfToday(), 29).toISOString(),
//         endOfToday().toISOString(),
//         // format(new Date(), "yyyy-MM-dd"),
//         // ...Array(29)
//         //   .fill(null).map((d,i) => format(subDays(new Date(), i+1), "yyyy-MM-dd"))
//       ];
//     case 'This Month': {
//       // const diff = differenceInDays(new Date(), startOfMonth(new Date())) - 1;
//       // console.log(diff);
//       return [
//         startOfMonth(startOfToday()).toISOString(),
//         endOfToday().toISOString()
//       ];
//     }
//     case 'Custom':
//     default: {
//       // const diff = differenceInDays(new Date(to), new Date(from)) - 1;
//       return [
//         startOfDay(new Date(from)).toISOString(),
//         endOfDay(new Date(to)).toISOString()
//         // format(addMinutes(new Date(to), new Date(to).getTimezoneOffset()), "yyyy-MM-dd"),
//         // ...(diff > 0 ? Array(diff) : [])
//         //   .fill(null).map((d,i) => format(subDays(new Date(to), i+1), "yyyy-MM-dd"))
//       ];
//     }
//   }
// }


export type TAuditTrail = {
  date: string;
  time: string;
  action: string;
  clusterName: string;
  source: string;
  prevState: string;
  username: string;
  class: string;
}

export const getAuditLog = createAsyncThunk<any, GetAuditLogBetweenArg,{ rejectValue: APIError }>(
  'audit/getLog',
  async (arg, thunkAPI) => {
    let logs: TAuditTrail[];
    try {
      logs = await API_MODULE.getAuditLogBetween(arg)
        .then(res => res || []); // case when API return null
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'getLog',
        message: "Failed to get audit log."
      })
    }
    return logs;
  }
)

export type AuditTrailState = {
  data: TAuditTrail[];
  dates: string[];
  byDate: Record<string, TAuditTrail[]>;
  loading: boolean;
  error: string|null;
  selected: {
    dateOption: string;
    from: string;
    to: string;
  },
  count: number;
}

export const auditInitialState: AuditTrailState = {
  data: [],
  dates: [],
  byDate: {},
  loading: false,
  error: null,
  selected: {
    dateOption: "Today",
    from: format(new Date(), 'yyyy-MM-dd'),
    to: format(new Date(), 'yyyy-MM-dd'),
  },
  count: 0,
}

const auditSlice = createSlice({
  name: 'audit',
  initialState: auditInitialState,
  reducers: {
    // // get logs using date range
    // getLogs(state, action) {
    //   state.selected = action.payload;
    // },
    // setLogs(state, action) {
    //   state.dates = getDateArrays(state.selected);
    //   state.byDate = {
    //     // keep what has already fetched
    //     ...state.byDate,
    //     ...action.payload.byDate
    //   };
    //   state.count = action.payload.count;
    // }
  },
  extraReducers: builder => {
    builder
    .addCase(getAuditLog.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getAuditLog.fulfilled, (state, action: PayloadAction<TAuditTrail[]>) => {
      state.loading = false;
      state.data = action.payload;
      state.count = action.payload.length;
      const byDate = {
        // ...state.byDate,
        ...action.payload.reduce((o: any, d: TAuditTrail) => {
          if (!o[d.date]) o[d.date] = [];
          o[d.date].push(d);
          return o;
        }, {})
      };
      state.byDate = byDate;
      state.dates = Object.keys(byDate);
    })
  }
})

// export const { getLogs, setLogs } = auditSlice.actions;

export default auditSlice.reducer;
