import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { stat } from 'fs';
// import store from '../../store/app.store';
import { TCluster, TTaskMetric, TNewCluster, TTask, TUser, TParticipant, TParticipantAttendanceRow } from '../../types/app.types';
import { sort } from '../../utils/sort';
import {
  addClusterTask,
  stopClusterTask,
  loadClusterTasks,
  loadClusters,
  removeCluster,
  restartClusterTask,
  updateCluster,
  updateClusterTask,
  addCluster,
  parseTaskId,
} from './clusters.actions';
import { MOCK_CLUSTERS_DATA } from './clusters.mock';


export type ClusterQueues = {
  stopping: string[], // (DEACTIVATING || STOPPING || DEPROVISIONING) && !STOPPED
  restarting: string[],
  starting: string[], // (PROVISIONING || PENDING || ACTIVATING) && !RUNNING
}

const clusterQueuesInitialState: ClusterQueues = { stopping: [], restarting: [], starting: [] };

// type ClusterData = TCluster & {
//   // trainer: TUser|null;
//   // coTrainer: TUser|null;
//   // support: TUser|null;
//   // createdAt: string|null;
//   // taskDefinition: TClusterTaskDefinition|null;
// }

export type ClusterStatistics = {
  total:number;
  // launched: number;
  assigned:number;
  unassigned:number;
  healthy:number;
  unhealthy:number;
  provisioningTasks: number;
  runningTasks: number;
  emptyTasks: string[];
}

export type ClusterState = {
  data: TCluster;
  // polling: boolean;
  loading: boolean;
  // determine whether this cluster is currently polling for data refresh
  // polling: boolean;
  error: string|null;
  // stores taskArn to queue for depends on its lastStatus
  queues: ClusterQueues;
  // provisioningTasks: number;
  // runningTasks: number;
  taskMetric: Record<string, TTaskMetric>;
  taskIds: {
    ids: string[];
    byIds: Record<string, string>;
    byArn: Record<string, string>;
  };
  statistics: ClusterStatistics;
  canEditDate: boolean;
}

export const clusterInitialState: ClusterState = {
  loading: false,
  // polling: false,
  error: null,
  taskMetric: {},
  taskIds: {
    ids: [],
    byIds: {},
    byArn: {}
  },
  data: {
    launchTimestamp: "",
    startDate: "",
    endDate: "",
    clusterName: '',
    className: '',
    trainer: null,
    coTrainer: null,
    support: null,
    createdAt: null,
    taskDefinition: null,
    runningTasks: 0,
    pendingTasks: 0,
    requestedTasks: 0,
    tasks: [],
    launched: false
  },
  queues: clusterQueuesInitialState,
  statistics: {
    total: 0,
    assigned: 0,
    healthy: 0,
    unhealthy: 0,
    provisioningTasks: 0,
    runningTasks: 0,
    unassigned: 0,
    emptyTasks: [],
  },
  canEditDate: false
  // provisioningTasks: 0,
  // runningTasks: 0,
}
/* istanbul ignore next */
function getClusterQueue(tasks: TTask[]): ClusterQueues {
  return tasks.reduce((queues: ClusterQueues, task) => {
    const output = {...queues};
    switch(task.lastStatus) {
      case "DEACTIVATING":
      case "STOPPING":
      case "DEPROVISIONING": {
        output.stopping = [...output.stopping, task.taskArn];
        return output;
      }

      case "RUNNING": {
        // Well.. this is not ideal
        // how would this detect when instance was healthy but turned unhealthy
        // TODO: implement throttle with limit
        if (task.health !== "HEALTHY") {
          output.starting = [...output.starting, task.taskArn];
        }
        return output;
      }
      case "PROVISIONING":
      case "PENDING":
      case "ACTIVATING": {
        output.starting = [...output.stopping, task.taskArn];
        return output;
      }
      default:
        return output;
    }
  }, clusterQueuesInitialState)
}
/* istanbul ignore next */
function getStatistics(tasks: TTask[]) {
  const emptyTasks: string[] = [];
  let runningTasks = 0,
    provisioningTasks = 0,
    healthy = 0,
    unhealthy = 0,
    assigned = 0,
    unassigned = 0;

  tasks.forEach(task => {
    if (task.lastStatus === "RUNNING")       runningTasks++;
    if (task.lastStatus === "PROVISIONING")  provisioningTasks++;
    if (task.health === "HEALTHY")           healthy++;
    if (task.health === "UNHEALTHY")         unhealthy++;
    // if (launched)

    const assignedParticipantCount = task.participants && task.participants.length
      ? task.participants.filter(({email}) => (email && email !== "")).length
      : 0;
    // when there are multiple participants in single tasks it's still one assigned instance
    if (assignedParticipantCount > 0) {
      assigned ++;
    } else {
      unassigned++;
      emptyTasks.push(task.taskArn);
    }
  });

  return {
    total: tasks.length,
    runningTasks, provisioningTasks,
    healthy, unhealthy,
    assigned, unassigned,
    emptyTasks
  }
}
/* istanbul ignore next */
export function sortClusters(option: ClusterSortState, clusters: TCluster[]): TCluster[] {
  const sortedByName = sort(clusters).by(d => d.className)['asc']() as TCluster[];
  switch (option.orderBy) {
    case ClusterSortable.ClassStartDate:
      return sort(sortedByName).by(d => d.startDate)[option.order]() as TCluster[];
    case ClusterSortable.ClassName:
      return sort(clusters).by(d => d.className)[option.order]() as TCluster[];
    default:
      return clusters;
  }
}
/* istanbul ignore next */
function sortTasks(option: TaskSortState, tasks: TTask[]): TTask[] {
  switch (option.orderBy) {
    case TaskSortable.InstanceAddress:
      return sort(tasks)
        .by(d => d.publicIPv4Address)
        [option.order]();
    case ParticipantSortable.ParticipantEmail:
      return sort(tasks.map(task => ({
          ...task,
          participants: sortParticipants(option, task.participants ? [...task.participants] : [])
        })))
        .by(d => {
          return d.participants && d.participants[0] && d.participants[0].email
            ? d.participants[0].email.toLowerCase() : ""
        })[option.order]();
    case ParticipantSortable.ParticipantFirstName:
      return sort(tasks.map(task => ({
        ...task,
        participants: sortParticipants(option, task.participants ? [...task.participants] : [])
      })))
      .by(d => {
        return d.participants && d.participants[0] && d.participants[0].firstName
          ? d.participants[0].firstName.toLowerCase() : ""
      })[option.order]();
    case ParticipantSortable.ParticipantLastName:
      return sort(tasks.map(task => ({
        ...task,
        participants: sortParticipants(option, task.participants ? [...task.participants] : [])
      })))
      .by(d => {
        return d.participants && d.participants[0] && d.participants[0].lastName
          ? d.participants[0].lastName.toLowerCase() : ""
      })[option.order]();
    default:
      return tasks;
  }
}
/* istanbul ignore next */
function sortParticipants(option: TaskSortState, participants: TParticipant[]): TParticipant[] {
  switch (option.orderBy) {
    case ParticipantSortable.ParticipantFirstName:
      return sort(participants)
        .by(d => d && d.firstName ? d.firstName.toLowerCase() : "")[option.order]();
    case ParticipantSortable.ParticipantLastName:
      return sort(participants)
        .by(d => d && d.lastName ? d.lastName.toLowerCase() : "")[option.order]();

    case ParticipantSortable.ParticipantEmail:
    default:
      return sort(participants)
        .by(d => d && d.email ? d.email.toLowerCase() : "")[option.order]();
  }
}

/* istanbul ignore next */
export function initializeClusterState (tasks: TTask[], state = clusterInitialState, sort: GlobalClusterSortState = DEFAULT_SORT_STATE): ClusterState {

  // if ()
  /**
   * !IMPORTANT NOTE
   * be aware that deleted tasks are still part of API response.
   * to hide these deleted tasks we need to filter them.
   */
  // const filteredData = tasks.filter(task =>
  //   !(task.stoppedReason === "invoked by delete-ecs-task" && task.lastStatus === "STOPPED"));
  const sortedTasks = sortTasks(sort.task, replaceRestartedTasks(tasks));

  /**
   * If there is "true" in one of the participant, user can't edit date
   * [
   *   {
   *     participants: [
   *        {..., attendances: [true, false]}
   *     ]
   *   }
   *   {
   *     participants: [
   *        {..., attendances: [false, false]}
   *     ]
   *   }
   * ]
   */
  const canEditDate = !sortedTasks.reduce((o, d: TTask) => {
    const participantAttendanceChecked: boolean[] = (d.participants || [])
      .map(participant => participant.attendances?.includes(true)) as boolean[];

    // console.log(participantAttendanceChecked);
    if (participantAttendanceChecked)
      o = [...o, ...participantAttendanceChecked];

    // console.log(o);
    return o;
  }, [] as boolean[]).includes(true);

  return {
    ...state,
    data: {...state.data,
      // https://github.com/arielpartners/training-image-manager/pull/56/commits/a4f77fcd5d82c341f8d2a53154ca6c1dcd2e30f5#diff-1ffddd252513f4f49b22dbf982c391657f4c92f78fde619b24de8c6c3f8a0040R25-R36
      // replaceRestartedTasks is very expensive operation
      // this is not ideal with interval function...
      // TODO: optimize replaceRestartedTasks function
      tasks: sortedTasks,
    },
    taskIds: sortedTasks.reduce((o: any, d: TTask) => {
      const taskId = parseTaskId(d.taskArn);
      // if (!o[d].tas)
      o.byIds[taskId] = d.taskArn;
      o.byArn[d.taskArn] = taskId;
      o.ids = [...o.ids, taskId];
      return o;
    }, {
      ids: [],
      byIds: {},
      byArn: {}
    }),
    queues: getClusterQueue(tasks || []),
    statistics: getStatistics(sortedTasks || []),
    canEditDate
  }
}

export type NormalizedClusters = {
  byClusterName: Record<string, ClusterState>;
  clusterNames: string[];
}

export type ClusterTaskDefinitionType = 'all' | 'regular' | 'shared';
// export type TClusterTaskDefinitionOptions = ClusterTaskDefinitionType[]

export type ClustersQuickFilter = {
  onlyMine: boolean;
  inactive: boolean;
  launched: boolean;
  taskDefinitionType: 'all' | 'regular' | 'shared';
};

export enum ClusterSortable {
  ClassStartDate = 'startDate',
  ClassName = 'className',
}

export enum TaskSortable {
  InstanceAddress = 'publicIPv4Address',
  InstanceStatus = 'status',
}

export enum ParticipantSortable {
  ParticipantFirstName = 'participantFirstName',
  ParticipantLastName = 'participantLastName',
  ParticipantEmail = 'participantEmail',
}

export type ClusterSortableType =
  ClusterSortable.ClassStartDate |
  ClusterSortable.ClassName;
  // TaskSortable.InstanceAddress |
  // TaskSortable.InstanceStatus |
  // ParticipantFirstName.ParticipantFirstName |
  // ClusterSortable.ParticipantLastName |
  // ClusterSortable.ParticipantEmail;

export type TaskSortableType =
  TaskSortable.InstanceAddress |
  TaskSortable.InstanceAddress |
  ParticipantSortable.ParticipantFirstName |
  ParticipantSortable.ParticipantLastName |
  ParticipantSortable.ParticipantEmail;

export type ClusterSortState = {
  orderBy: ClusterSortableType;
  order: 'asc' | 'desc';
}

export type TaskSortState = {
  orderBy: TaskSortableType;
  order: 'asc' | 'desc';
}

export type GlobalClusterSortState = {
  cluster: ClusterSortState;
  task: TaskSortState;
}

export type ClustersState = {
  data: TCluster[];
  loading: boolean;
  polling: boolean;
  error: string|null;

  entities: NormalizedClusters;
  // normalized: Record<string, ClusterState>|null;
  // stores taskArn to queue for depends on its lastStatus
  // is this needed??
  queues: { removals: string[], starting: string[] };
  quickFilters: ClustersQuickFilter;
  searchInput: string;
  pollingQueues: Record<string,boolean>;
  sort: GlobalClusterSortState
}


export const DEFAULT_SORT_STATE: GlobalClusterSortState = {
  cluster: {
    order: 'desc',
    orderBy: ClusterSortable.ClassStartDate
  },
  task: {
    order: 'desc',
    orderBy: ParticipantSortable.ParticipantFirstName
  }
}

export const clustersInitialState: ClustersState = {
  data: [],
  // store as normalized dataset
  // https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape
  entities: {
    byClusterName: {}, clusterNames: []
  },
  loading: false,
  polling: false,
  error: null,
  queues: { removals: [], starting: [] },
  quickFilters: {
    onlyMine: false,
    inactive: false,
    launched: true,
    taskDefinitionType: 'all',
  },
  searchInput: '',
  pollingQueues: {},
  sort: DEFAULT_SORT_STATE
}


// https://github.com/arielpartners/training-image-manager/commit/f9bf7ef59fbd93f45e7decce2e85c96957a3cbe3#diff-1ffddd252513f4f49b22dbf982c391657f4c92f78fde619b24de8c6c3f8a0040

/* istanbul ignore next */
function replaceRestartedTasks(tasksUnfiltered: TTask[]) {
  const newTasks: TTask[] = [];
  const replacementFor: any[] = [];
  // filter out stopped tasks
  const tasks = (tasksUnfiltered || []).filter(task =>
    !(task.stoppedReason === "invoked by delete-ecs-task" && task.lastStatus === "STOPPED"));
  // replace tasks
  tasks.map((task, index) => {
    if(task.replacementFor !== "" && task.lastStatus !== "STOPPED") {
      // if original task still exists, then populate the replacementFor variable,
      // otherwise, just push the task to the newTasks, as we're not going to replace anything
      let originalTaskExists = false
      tasks.map(t => {
        if(task.replacementFor === t.taskArn) {
          originalTaskExists = true
        }
      })
      if(originalTaskExists) {
        replacementFor.push({replacementFor: task.replacementFor, index})
      } else {
        newTasks.push(task)
      }
    } else {
      newTasks.push(task)
    }
    return null;
  })
  // replace restarted tasks with new task
  let replacements: any[] = []
  let deletes: any[] = []
  if(replacementFor.length > 0) {
    newTasks.map((task, index) => {
      let doReplacement = false
      replacementFor.map(replacement => {
        if(task.taskArn === replacement.replacementFor) {
          doReplacement = true
          replacements.push({newTaskIndex: index, taskIndex: replacement.index})
        }
      })
      if(!doReplacement && task.replacementFor !== "" && task.lastStatus === "STOPPED") {
        deletes.push({deletionIndex: index})
      }
    })
  }
  replacements.map(r => {
    newTasks[r.newTaskIndex] = tasks[r.taskIndex];
  })
  // console.log('replaceRestartedTasks', newTasks, replacementFor, replacements, deletes)
  return newTasks
  // // remove tasks that are stopped, have a reference to a restarted task but are not replaced
  // let newTasksAfterDeletions = []
  // newTasks.map((task, index) => {
  //   let taskDeleted = false
  //   deletes.map(d => {
  //     if(index === d.deleteIndex) {
  //       taskDeleted = true
  //     }
  //   })
  //   if(!taskDeleted) {
  //     newTasksAfterDeletions.push(task)
  //   }
  // })
  // console.log(newTasksAfterDeletions)
  // return newTasksAfterDeletions
}


export const clusterListSlice = createSlice({
  name: 'clusters',
  initialState: clustersInitialState,
  reducers: {
    setQuickFilter(state, action: PayloadAction<ClustersQuickFilter>) {
      state.quickFilters = action.payload;
    },
    setSearchInput(state, action: PayloadAction<string>) {
      state.searchInput = action.payload;
    },
    // this is batch polling class start action for epic
    startPollingClasses(state, action: PayloadAction<void>) {
      state.polling = true;
    },
    // this is batch polling class stop action for epic
    stopPollingClasses(state, action: PayloadAction<void>) {
      state.polling = false;
    },
    // start polling for cluster tasks
    startPollingTasks(state, action: PayloadAction<{clusterName: string}>) {
      const {clusterName} = action.payload;
      // state.entities.byClusterName[clusterName].polling = true;
      state.pollingQueues[clusterName] = true;
    },
    // stop polling for cluster tasks
    stopPollingTasks(state, action: PayloadAction<{clusterName: string}>) {
      const {clusterName} = action.payload;
      // state.entities.byClusterName[clusterName].polling = false;
      state.pollingQueues[clusterName] = false;
    },
    // start polling for new class
    startPollingNewClass(state, action: PayloadAction<{executionId: string}>) { },
    // stop polling for new class
    stopPollingNewClass(state, action: PayloadAction<{executionId: string}>) { },
    startPollingMetric(state, action: PayloadAction<{clusterName: string, tasks: TTask[]}>) {},
    stopPollingMetric(state, action: PayloadAction<void>) {},
    updateAllParticipantsAttendance(state, action: PayloadAction<{
      clusterName: string,
      participants: TParticipantAttendanceRow[],
      attendanceIndex: number,
      value: boolean
    }>) {},
    // updateAllParticipantsAttendanceFulfilled(state, action: PayloadAction<{
    //   clusterName: string,
    //   participants: TParticipant[],
    //   attendanceIndex: number,
    //   value: boolean
    // }>){

    // },
    setTaskMetric(state, action: PayloadAction<{
      clusterName: string,
      metric: TTaskMetric[]
    }>) {
      if (action.payload && action.payload.clusterName) {
        const { clusterName, metric } = action.payload;
        // const { tasks } = state.entities.byClusterName[clusterName].data;
        state.entities.byClusterName[clusterName].taskMetric = metric.reduce((o: any, d) => {
          if (!o[d.taskID]) {
            o[d.taskID] = d;
          }
          return o;
        }, {});
      }
    },
    setClusterTasks(state, action: PayloadAction<TCluster>) {
      if (action.payload && action.payload.clusterName) {
        const {clusterName, tasks} = action.payload;
        state.entities.byClusterName[clusterName] = initializeClusterState(tasks, {
          ...state.entities.byClusterName[clusterName],
          loading: false
        }, {...state.sort});
      }
    },
    sortCluster(state, action: PayloadAction<GlobalClusterSortState>) {
      state.sort = action.payload;
      const sortedData = sortClusters(action.payload.cluster, [...state.data]);
      state.data = sortedData;
      state.entities = sortedData.reduce((entities: NormalizedClusters, cluster) => {
        if (!entities.byClusterName) entities.byClusterName = {};
        const { clusterName } = cluster;
        if (!entities.byClusterName[clusterName]) {
          entities.byClusterName[clusterName] = {
            ...initializeClusterState(cluster.tasks, {...clusterInitialState, data: cluster}, {...state.sort})
          };
          entities.byClusterName[clusterName].data.clusterName = clusterName;
          entities.clusterNames.push(clusterName);
        }
        return entities;
      }, {
        byClusterName: {},
        clusterNames: []
      })
    }
  },
  extraReducers: builder => { builder
    .addCase(loadClusters.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(loadClusters.fulfilled, (state, action) => {
      state.loading = false;
      // since all clusters are normalized by clusterName every cluster data required to have clusterName.
      // however, there could be a case when data is corrupted on server-side during data entry
      // therefore, we will do a filter on client side whenever clusters are loaded 
      // to ensure client state doesn't hold any corrupt data
      const data = (action.payload || []).filter(item => item.clusterName && item.clusterName !== "");
      const clusterSortOption = {...state.sort.cluster};

      const sortedData = sortClusters(clusterSortOption, [...data]);
      state.data = sortedData;
      state.entities = sortedData.reduce((entities: NormalizedClusters, cluster) => {
        const { clusterName } = cluster;
          entities.byClusterName[clusterName] = {
            ...initializeClusterState(cluster.tasks, {...clusterInitialState, data: cluster}, {...state.sort})
          };
          entities.byClusterName[clusterName].data.clusterName = clusterName;

          if (!entities.clusterNames.includes(clusterName))
            entities.clusterNames.push(clusterName);
        return entities;
      }, {
        byClusterName: {},
        clusterNames: []
      })
    })
    .addCase(loadClusters.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || null;
    })
    .addCase(addCluster.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(addCluster.fulfilled, (state, action) => {
      state.loading = false;
      // state.queues.starting = [...state.queues.starting, action.payload.executionId];
    })
    .addCase(removeCluster.fulfilled, (state, action) => {
      const {clusterName} = action.payload;
      const set = new Set(state.queues.removals);
      state.queues = {
        ...state.queues,
        removals: set.has(clusterName)
          ? state.queues.removals
          : [...(state.queues.removals || []), clusterName]
      };
    })
    .addCase(removeCluster.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || null;
    })

    .addCase(updateCluster.pending, (state, action) => {
      if (action.meta.arg.clusterName) {
        const {clusterName, className, trainer, coTrainer, support} = action.meta.arg;
        state.entities.byClusterName[clusterName].loading = true;
      }
    })
    .addCase(updateCluster.fulfilled, (state, action) => {
      if (action.meta.arg.clusterName) {
        const {clusterName, className, trainer, coTrainer, support, startDate, endDate, tasks} = action.payload;

        // console.log({...state.entities.byClusterName}, clusterName, action.payload)

        // const users = store.getState().users.normalized.byUsername;
        state.entities.byClusterName[clusterName].loading = false;
        state.entities.byClusterName[clusterName].data.className = className;
        state.entities.byClusterName[clusterName].data.trainer = trainer;
        state.entities.byClusterName[clusterName].data.coTrainer = coTrainer;
        state.entities.byClusterName[clusterName].data.support = support;
        state.entities.byClusterName[clusterName].data.startDate = startDate;
        state.entities.byClusterName[clusterName].data.endDate = endDate;
        state.entities.byClusterName[clusterName].data.tasks = tasks;
      }
    })

    .addCase(updateCluster.rejected, (state, action) => {
      if (action.meta.arg.clusterName) {
        const {clusterName} = action.meta.arg;
        state.entities.byClusterName[clusterName].loading = false;
      }
      state.error = action.error.message || null;
    })

    .addCase(updateClusterTask.pending, (state, action) => {
      if (action.meta.arg.clusterName) {
        const {clusterName} = action.meta.arg;
        state.entities.byClusterName[clusterName].loading = true;
        // state.loading = true;
      }
    })
    .addCase(addClusterTask.pending, (state, action) => {
      if (action.meta.arg.clusterName) {
        const {clusterName} = action.meta.arg;
        state.entities.byClusterName[clusterName].loading = true;
        // state.loading = true;
      }
    })
    .addCase(restartClusterTask.pending, (state, action) => {
      if (action.meta.arg.clusterName) {
        const {clusterName} = action.meta.arg;
        state.entities.byClusterName[clusterName].loading = true;
        // state.loading = true;
      }
    })
    .addCase(stopClusterTask.pending, (state, action) => {
      if (action.meta.arg.clusterName) {
        const {clusterName} = action.meta.arg;
        state.entities.byClusterName[clusterName].loading = true;
        // state.loading = true;
      }
    })
    
    .addCase(loadClusterTasks.pending, (state, action) => {
      if (action.meta.arg.clusterName) {
        const {clusterName} = action.meta.arg;
        state.entities.byClusterName[clusterName].loading = true;
        // state.loading = true;
      }
    })

    .addCase(loadClusterTasks.fulfilled, (state, action) => {
      const {clusterName} = action.payload;
      state.entities.byClusterName[clusterName].loading = false;
    })

    .addCase(loadClusterTasks.rejected, (state, action) => {
      const clusterName = action.meta.arg.clusterName;
      state.entities.byClusterName[clusterName].loading = false;
      state.entities.byClusterName[clusterName].error = action.error.message || null;
    })

    .addCase(updateClusterTask.fulfilled, ((state, action) => {
      if (action.payload && action.payload.clusterName) {
        const { clusterName, task } = action.payload;
        const { tasks } = state.entities.byClusterName[clusterName].data;
        // console.log('updateClusterTask.fulfilled',action.payload, 'state.entities.byClusterName[clusterName].data.tasks', state.entities.byClusterName[clusterName].data.tasks)
        const updatedTasks = [...tasks].map(item =>
          item.taskArn === task.taskArn ? task : item
        );

        const canEditDate = !updatedTasks.reduce((o, d: TTask) => {
          const participantAttendanceChecked: boolean[] = (d.participants || [])
            .map(participant => participant.attendances?.includes(true)) as boolean[];
      
          // console.log(participantAttendanceChecked);
          if (participantAttendanceChecked)
            o = [...o, ...participantAttendanceChecked];
      
          // console.log(o);
          return o;
        }, [] as boolean[]).includes(true);

        state.entities.byClusterName[clusterName].data.tasks = updatedTasks;
        state.entities.byClusterName[clusterName].loading = false;
        state.entities.byClusterName[clusterName].statistics = getStatistics(updatedTasks);
        state.entities.byClusterName[clusterName].canEditDate = canEditDate;
      }
    }))

    .addCase(updateClusterTask.rejected, (state, action) => {
      const clusterName = action.meta.arg.clusterName;
      state.entities.byClusterName[clusterName].loading = false;
      state.entities.byClusterName[clusterName].error = action.error.message || null;
    })

    .addCase(addClusterTask.fulfilled, ((state, action) => {
      if (action.payload && action.payload.clusterName) {
        const { clusterName } = action.payload;
        const { queues } = {...state.entities.byClusterName[clusterName]};
        state.entities.byClusterName[clusterName].queues = {
          ...queues,
          starting: [...queues.starting, action.payload.taskArn]
        }
      }
    }))

    .addCase(addClusterTask.rejected, (state, action) => {
      const clusterName = action.meta.arg.clusterName;
      state.entities.byClusterName[clusterName].loading = false;
      state.entities.byClusterName[clusterName].error = action.error.message || null;
    })

    .addCase(stopClusterTask.fulfilled, ((state, action) => {
      if (action.payload && action.payload.clusterName) {
        const { clusterName } = action.payload;
        const { queues } = {...state.entities.byClusterName[clusterName]};
        state.entities.byClusterName[clusterName].queues = {
          ...queues,
          stopping: [...queues.stopping, action.payload.taskArn]
        }
      }
    }))

    .addCase(stopClusterTask.rejected, (state, action) => {
      const clusterName = action.meta.arg.clusterName;
      state.entities.byClusterName[clusterName].loading = false;
      state.entities.byClusterName[clusterName].error = action.error.message || null;
    })

    .addCase(restartClusterTask.fulfilled, (state, action) => {
      if (action.payload && action.payload.clusterName) {
        const { clusterName } = action.payload;
        const { queues } = {...state.entities.byClusterName[clusterName]};
        state.entities.byClusterName[clusterName].queues = {
          ...queues,
          restarting: [...queues.restarting, action.payload.taskArn]
        }
      }
    })

    .addCase(restartClusterTask.rejected, (state, action) => {
      const clusterName = action.meta.arg.clusterName;
      state.entities.byClusterName[clusterName].loading = false;
      state.entities.byClusterName[clusterName].error = action.error.message || null;
    })
  }
})

export const {
  setQuickFilter, setSearchInput,
  startPollingClasses, stopPollingClasses, 
  startPollingTasks, stopPollingTasks,
  startPollingNewClass, stopPollingNewClass,
  setClusterTasks,
  startPollingMetric, stopPollingMetric, setTaskMetric,
  sortCluster, updateAllParticipantsAttendance
} = clusterListSlice.actions;

export default clusterListSlice.reducer;
