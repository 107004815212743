import React, { useState } from "react";
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { MoreHorizontal } from 'react-feather';

export type RowActionsPropType = {
  id?: string,
  actions: { id: string, disabled?: boolean, label: string, onClick: () => void }[];
}

/**
 * Simple wrapper for row action dropdown menu
 */
const RowActions: React.FC<RowActionsPropType> = ({id, actions}) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const handleMenuButtonClick = (event: any) => {
    // console.log("handleMenuButtonClick")
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton data-testid="row-action-button"
        aria-label="View Actions"
        aria-controls={id}
        aria-haspopup="true"
        onClick={handleMenuButtonClick}>
        <MoreHorizontal size={16} />
      </IconButton>
      <Menu
        data-testid="row-action-menu"
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {actions.map((action, key) => <MenuItem key={key}
          data-testid={action.id}
          disabled={action.disabled}
          onClick={() => {
            action.onClick();
            handleMenuClose();
          }}>
          {action.label}
        </MenuItem>)}
      </Menu>
    </>
  )
}

export default RowActions;
