import React, { useState } from 'react';
import Typography from "@material-ui/core/Typography";
import { TNewCluster, TUser } from "../../../types/app.types";
import ParticipantsList from "./ParticipantsList";
import format from "date-fns/format";
import formatDistance from 'date-fns/formatDistance'
import { Divider, FormControlLabel, IconButton, makeStyles, Paper, TextField } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/app.store";
import Checkbox from '@material-ui/core/Checkbox';
import HelpIcon from '@material-ui/icons/Help';
import Popover from '@material-ui/core/Popover';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  popover: {
    padding: theme.spacing(2),
    backgroundColor: grey[600],
    color: grey[100],
    width: 250
  },
  icon: {
    width: 16,
    height: 16,
  },
  label: {
    fontWeight: 500,
  },
}))

function Label(props: {children: React.ReactNode, helpText: string}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="d-flex justify-content-start align-items-center">
      <Typography variant="caption" component="div" className={classes.label}>
        {props.children}
      </Typography>
      {props.helpText &&
      <IconButton  size="small" onClick={handleClick}>
        <HelpIcon className={classes.icon} />
      </IconButton>}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography variant="body1" className={classes.popover}>
          {props.helpText}
        </Typography>
      </Popover>
    </div>
  )
}

function DateViewer(props: {'data-testid': string; date: number|string|Date;}) {
  return (
    <div data-testid={props['data-testid']} >
      <Typography variant="h2" component="div">
        {format(new Date(props.date), 'dd')}
      </Typography>
      <Typography variant="subtitle1" component="div">
        {format(new Date(props.date), 'MMMM')}
      </Typography>
      <Typography variant="body1" component="div">
        {format(new Date(props.date), 'hh:mm aa')}
      </Typography>
    </div>
  )
}

function TrainerViewer(props: { label: string, user: TUser }) {
  const { user, label } = props;
  return (
    <div className="d-flex flex-column mb-3 pl-2">
      <Typography variant="caption" component="div" className="mb-1" style={{fontWeight: 500}}>
        {label}
      </Typography>
      <div className="d-flex flex-column mb-3">
        {(user?.firstName || user?.lastName) &&
        <Typography variant="body1" component="div">
          {user?.firstName} {user?.lastName}
        </Typography>
        }
        <Typography variant="body2" component="div">
          {user?.email}
        </Typography>
      </div>
    </div>
  )
}

export type ClassCreationReviewStepProp = {
  formik: any;
  extraInstances: number;
  handleTaskCount: (type: 'participant'|'extra', value: number) => void;
  createShared: boolean;
}

export default function ClassCreationReviewStep (props: ClassCreationReviewStepProp) {
  const { formik, extraInstances, handleTaskCount, createShared } = props;
  const { className, startDate, endDate, trainer, coTrainer, support, taskDefinition, participants, taskCount } = formik.values as TNewCluster;
  const assignedCount = () => {
    return participants.reduce<number>((o, d) => {
      if (d.email && d.email !== "") o++;
      return o;
    }, 0);
  };
  const classes = useStyles();
  const byUsername = useSelector((state: RootState) => state.users.normalized.byUsername);

  return (
    <>
      <div className="row mt-3">
        <div className="col-12 col-lg-3 mb-3">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-start mb-3">
              <DateViewer data-testid="startDate" date={startDate} />
              <Typography variant="h2" component="span">~</Typography>
              <DateViewer data-testid="endDate" date={endDate} />
            </div>
            <div>
              <Typography variant="h6" component="div">
                for {formatDistance(new Date(startDate), new Date(endDate))}
              </Typography>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-9 mb-3">
          <Typography variant="h5" component="h2" className="mb-3">{className}</Typography>
          <div data-testid="taskDefinition" className="pl-2 mb-3">
            <Typography variant="caption" component="div" className="mb-1" style={{fontWeight: 500}}>
              Task Definitions
            </Typography>
            <Typography variant="body1" component="div">
              {taskDefinition}
            </Typography>
          </div>

          <div className="row">
            <div className="col-12 col-lg-4">
              <TrainerViewer label="Trainer" user={byUsername[trainer]} />
            </div>
            <div className="col-12 col-lg-4">
              <TrainerViewer label="Co Trainer" user={byUsername[coTrainer]} />
            </div>
            <div className="col-12 col-lg-4">
              <TrainerViewer label="Support" user={byUsername[support]} />
            </div>
          </div>
        </div>
        <div className="col-12">
          <Divider className="w-100" />
        </div>
      </div>
      <div className="row mt-3">
       <div className="col-12 col-lg-3 mb-3">
        <Typography variant="h5" component="div" className="mb-4">
          Instances
        </Typography>

        <div className="row mb-4">
          <div data-testid="taskCount"  className="col text-center">
            <Typography variant="caption" component="div" className="mb-1" style={{fontWeight: 500}}>
              Total
            </Typography>
            <Typography variant="body1" component="div">
              {taskCount}
            </Typography>
          </div>

          <div data-testid="assigned-count" className="col text-center">
            <Typography variant="caption" component="div" className="mb-1" style={{fontWeight: 500}}>
              Assigned
            </Typography>
            <Typography variant="body1" component="div">
              {assignedCount()}
            </Typography>
          </div>

          <div data-testid="unassigned-count" className="col text-center">
            <Typography variant="caption" component="div" className="mb-1" style={{fontWeight: 500}}>
              Unassigned
            </Typography>
            <Typography variant="body1" component="div">
              {taskCount - (createShared ? 1 : assignedCount())}
            </Typography>
          </div>
        </div>

        <Paper elevation={0} className="border border-light">
          <div className="px-2 mb-3">
            <Label helpText="Having unassigned extra instances can prevent delays caused by provisioning new instance during the training.">
              Extra Instances
            </Label>
            <TextField data-testid="extra-instances" type="number"
              id="extra-instances"
              autoFocus
              variant="outlined"
              value={extraInstances}
              fullWidth
              onChange={(e:any) => {
                handleTaskCount('extra', Number(e.target.value));
              }}
              inputProps={{min: 0}}
            />
          </div>
          {/* <div className="px-2 mb-3">
            <FormControlLabel
              control={
                <Checkbox
                  disabled={
                    taskDefinition === 'jira-fundamentals-class-shared' ||
                    taskDefinition === 'jira-advanced-class-shared' ||
                    taskDefinition === 'pmi-jira-fundamentals-class-shared'
                  }
                  checked={createShared}
                  onChange={handleChange}
                  name="checkedB"
                  color="primary"
                />
              }
              label={
                <Typography variant="caption" className={classes.label}>
                  Create shared instance
                </Typography>
              }
            />
          </div> */}
        </Paper>
      </div>
      <div className="col-12 col-lg-9 mb-3">
        <div className="d-flex flex-column flex-lg-row justify-content-lg-between mb-3">
          <Typography variant="h5" component="div">
            Participants
          </Typography>
        </div>
        <ParticipantsList formik={formik} />
      </div>
    </div>
    </>
  );
}
