import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Input, { InputProps } from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import { X , Search} from "react-feather";

const styles = (theme: any) => ({
  root: {
    height: theme.spacing(6),
    display: "flex",
    justifyContent: "space-between",
  },
  iconButton: {
    color: theme.palette.action.active,
    transform: "scale(1, 1)",
    transition: theme.transitions.create(["transform", "color"], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  iconButtonHidden: {
    transform: "scale(0, 0)",
    "& > $icon": {
      opacity: 0,
    },
  },
  searchIconButton: {
    marginRight: theme.spacing(-6),
  },
  icon: {
    transition: theme.transitions.create(["opacity"], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  input: {
    width: "100%",
  },
  searchContainer: {
    margin: "auto 16px",
    width: `calc(100% - ${theme.spacing(6 + 4)}px)`, // 6 button + 4 margin
  },
});

export type SearchInputPropType = InputProps & {
  /** Whether to clear search on escape */
  cancelOnEscape?: boolean;
  /** Override or extend the styles applied to the component. */
  classes: any;
  /** Custom top-level class */
  className: string;
  /** Override the close icon. */
  closeIcon?: React.ReactElement;
  /** Disables text field. */
  disabled?: boolean;
  /** Fired when the search is cancelled. */
  onCancelSearch?: (e?: any) => void;
  /** Fired when the text value changes. */
  onChange?: (e?: any) => void;
  /** Fired when the search icon is clicked. */
  onRequestSearch?: (e?: any) => void;
  /** Sets placeholder text for the embedded text field. */
  placeholder?: string;
  /** Override the search icon. */
  searchIcon?: React.ReactElement;
  /** Override the inline-styles of the root element. */
  style?: any;
  /** The value of the text field. */
  value?: string;
};

/**
 * Material design search bar
 * @see [Search patterns](https://material.io/archive/guidelines/patterns/search.html)
 */
const SearchInput = React.forwardRef<any, SearchInputPropType>(
  (
    {
      cancelOnEscape,
      className = "",
      classes,
      closeIcon = <X size={14} />,
      disabled = false,
      onCancelSearch,
      onRequestSearch,
      searchIcon = <Search size={14} />,
      style = null,
      ...inputProps
    },
    ref
  ) => {
    const inputRef = React.useRef<any>();
    const [value, setValue] = React.useState<string>(inputProps.value || "");

    // SearchBar.defaultProps = {
//   className: "",
//   closeIcon: <ClearIcon />,
//   disabled: false,
//   placeholder: "Search",
//   searchIcon: <SearchIcon />,
//   style: null,
//   value: "",
// };

    React.useEffect(() => {
      setValue(inputProps.value || "");
    }, [inputProps.value]);

    const handleFocus = React.useCallback(
      (e) => {
        if (inputProps.onFocus) {
          inputProps.onFocus(e);
        }
      },
      [inputProps.onFocus]
    );

    const handleBlur = React.useCallback(
      (e) => {
        setValue((v) => v.trim());
        if (inputProps.onBlur) {
          inputProps.onBlur(e);
        }
      },
      [inputProps.onBlur]
    );

    const handleInput = React.useCallback(
      (e) => {
        setValue(e.target.value);
        if (inputProps.onChange) {
          inputProps.onChange(e.target.value);
        }
      },
      [inputProps.onChange]
    );

    const handleCancel = React.useCallback(() => {
      setValue("");
      if (onCancelSearch) {
        onCancelSearch();
      }
    }, [onCancelSearch]);

    const handleRequestSearch = React.useCallback(() => {
      if (onRequestSearch) {
        onRequestSearch(value);
      }
    }, [onRequestSearch, value]);

    const handleKeyUp = React.useCallback(
      (e) => {
        if (e.charCode === 13 || e.key === "Enter") {
          handleRequestSearch();
        } else if (
          cancelOnEscape &&
          (e.charCode === 27 || e.key === "Escape")
        ) {
          handleCancel();
        }
        if (inputProps.onKeyUp) {
          inputProps.onKeyUp(e);
        }
      },
      [handleRequestSearch, cancelOnEscape, handleCancel, inputProps.onKeyUp]
    );

    React.useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current.focus();
      },
      blur: () => {
        inputRef.current.blur();
      },
    }));

    return (
      <Paper data-testid="search-input-wrapper" className={classNames(classes.root, className)} style={style}>
        <div className={classes.searchContainer}>
          <Input
            data-testid="search-input"
            id="search-input"
            {...inputProps}
            inputRef={inputRef}
            onBlur={handleBlur}
            value={value}
            placeholder={inputProps.placeholder}
            onChange={handleInput}
            onKeyUp={handleKeyUp}
            onFocus={handleFocus}
            fullWidth
            className={classes.input}
            disableUnderline
            disabled={disabled}
          />
        </div>
        <IconButton
          data-testid="search-confirm-btn"
          onClick={handleRequestSearch}
          className={classNames(classes.iconButton, classes.searchIconButton, {
            [classes.iconButtonHidden]: value !== "",
            "search-input-confirm-btn": true,
          })}
          disabled={disabled}
        >
          {React.cloneElement(searchIcon, {
            classes: { root: classes.icon },
          })}
        </IconButton>
        <IconButton
          data-testid="search-cancel-btn"
          onClick={handleCancel}
          className={classNames(classes.iconButton, {
            [classes.iconButtonHidden]: value === "",
            "search-input-cancel-btn": true,
          })}
          disabled={disabled}
        >
          {React.cloneElement(closeIcon, {
            classes: { root: classes.icon },
          })}
        </IconButton>
      </Paper>
    );
  }
);



export default withStyles(styles)(SearchInput);
