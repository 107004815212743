import API from "@aws-amplify/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { TUser, TNewUser, TUserWithPassword } from "../../types/app.types";
import { APIError } from "../../types/common.types";
import shortid from 'shortid'
import { MOCK_USER_DATA } from "./users.mock";
import API_MODULE from "../../api";


export const loadUsers = createAsyncThunk<TUser[], void, { rejectValue: APIError }>(
  'users/loadUsers',
  async (arg: void, thunkAPI) => {
    let users: TUser[];
    try {
      users = await API_MODULE.loadUsers();
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'loadUsers',
        message: "Failed to load users."
      })
    }
    return users;
  });

// TODO: confirm id this API is ready
export const addUser = createAsyncThunk<TUser, TNewUser, { rejectValue: APIError }>(
  'users/addUser',
  async (arg, thunkAPI) => {
    let user: TUser;
    try {
      // user = await API.post("step-function-rest", "/Prod/step-function?addUser=true", {
      //   body: {...arg, username: arg.email},
      //   headers: {}
      // })
      user = await API_MODULE.addUser(arg);
      // This for mock purpose;
      // user = await Promise.resolve().then<TUser>(() => ({...arg, username: "mock-"+shortid.generate()}))
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'addUser',
        message: `Failed to add user. ${arg.email}`
      })
    }
    return {...user,
      message: `New user "${user.email}" is added`
    };
  });

// TODO: confirm id this API is ready
export const updateUser = createAsyncThunk<TUser, TUser, { rejectValue: APIError }>(
  'users/updateUser',
  async (arg, thunkAPI) => {
    const { email } = arg;
    let res: TUser;
    try {
      // This API should be available
      // res = await API.put("step-function-rest", "/Prod/step-function?updateUser=true", {
      //   body: { ...arg },
      //   headers: {}
      // })
      res = await API_MODULE.updateUser(arg);
      // This for mock purpose;
      // res = await Promise.resolve().then<TUser>(() => ({...arg, username: "mock-"+shortid.generate()}))
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'updateUser',
        message: `Failed to update user ${email}.`
      })
    }
    return {...res,
      message: `User "${email}" is updated`
    };
  });

// TODO: confirm id this API is ready
export const removeUser = createAsyncThunk<{username: string}, TUser, { rejectValue: APIError }>(
  'users/removeUser',
  async (arg, thunkAPI) => {
    const { username, email } = arg;
    try {
      // This API should be available
      // await API.del("step-function-rest", "/Prod/step-function?removeUser=true", {
      //   body: { username },
      //   headers: {}
      // })
      await API_MODULE.removeUser(arg);
      // This for mock purpose;
      // await Promise.resolve().then<{username: string}>(() => ({username}));
      return { username, message: `User "${email}" is removed` };
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'removeUser',
        message: "Failed to remove user."
      })
    }
  });

// TODO: confirm id this API is ready
export const resetPassword = createAsyncThunk<{message: string}, TUserWithPassword, { rejectValue: APIError }>(
  'users/resetPassword',
  async function (arg, thunkAPI) {
    const { username, email, password, permanent } = arg;
    
    try {
      await API_MODULE.resetPassword(arg);
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'resetPassword',
        message: `Failed to quest reset password for user. ${email}`
      })
    }
    return { message: `Password is reset for user "${email}"` };
  });

export const resendEmail = createAsyncThunk<{message: string}, TUser, { rejectValue: APIError }>(
  'users/resendEmail',
  async function (arg, thunkAPI) {
    const { username, email } = arg;
    try {
      await API_MODULE.resendEmail(arg);
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: 'resendEmail',
        message: `Failed to quest resend password for user. ${email}`
      })
    }
    return { message: `Resend password for user "${email}"` };
  });
