/* istanbul ignore file */
import React, { useRef, useEffect } from 'react';
import { TNewCluster } from '../../types/app.types';
import { useHistory } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import { RootState } from '../../store/app.store';
import { StatusVariant, StatusVariantType } from '../../types/status.types';
import { pushNotification } from '../../components/notification/notification.slice';
import { loadUsers as loadUsersAction } from '../../components/users/users.actions';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import CreateClassStepper from '../../components/clusters/form/CreateClassStepper';
import { addCluster as addClusterAction } from '../../components/clusters/clusters.actions';
import { userRoleSelector } from '../../store/selectors';
import { clusterInitialState } from '../../components/clusters/clusters.slice';
import format from 'date-fns/format';

const loadingSelector = (state: RootState) => {
	return state.clusters.loading || !!state.clusters.entities.clusterNames
		.filter(clusterName => (state.clusters.entities.byClusterName[clusterName] || clusterInitialState).loading).length
}

const mapStateToProps = (state: RootState, props: any) => ({
	starting: state.clusters.queues.starting,
	loading: loadingSelector(state)
});
const mapDispatchToProps = (dispatch: any) => ({
	loadUsers:() => dispatch(loadUsersAction()),
  addCluster: (cluster: TNewCluster) => dispatch(addClusterAction(cluster)),
	pushNotification: (status: StatusVariantType, message: string) =>
		dispatch(pushNotification({ status, message }))
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const ClassFormPage: React.FC<PropsFromRedux> = (props) => {
  const role = useSelector(userRoleSelector);
  let history = useHistory();
  const timeoutRef = useRef<NodeJS.Timeout|null>();

  const handleSubmit = (e: {values: TNewCluster, createShared: boolean, launchOnStart: boolean, scheduleTermination: boolean}) => {
    const values = {...e.values};
    if (e.launchOnStart) {
      values.launchTimestamp = format(new Date(), "yyyy-MM-dd'T'HH:mm")
    }
    if (e.scheduleTermination) values.scheduleTermination = e.scheduleTermination;
    // console.log("values", values)
    props.addCluster(values);

    // Following code is irrelevant because we are using only non-shared are option
    // This used to call extra API call to created shared instance
    // if (e.createShared) {
    //   props.addCluster({
    //     ...values,
    //     className: (
    //       values.className.endsWith("Shared") ? values.className : values.className + " Shared"
    //     ),
    //     taskDefinition: (
    //       values.taskDefinition.endsWith("-shared") ? values.taskDefinition : values.taskDefinition + "-shared"
    //     ),
    //     // taskCount: 1
    //   });
    // }

    // temp solution until migrating function waitUntilClassStarts to redux observable
    timeoutRef.current = setTimeout(() => {
      history.push('/list-tasks');
    }, 3000);
  }

  useEffect(() => {
    if (role === 'user') {
      history.push('/list-tasks');
      props.pushNotification(StatusVariant.Warnings, `You are not authorized to create class`);
    }
  }, [role])


  useEffect(() => {
    props.loadUsers();
    // disabled={role === 'user'
    return () => {
      if (timeoutRef.current) clearInterval(timeoutRef.current);
    }
  }, [])
  return (
    <div className="container pt-3">
      <div className="d-flex flex-column flex-lg-row justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">
        <Typography variant="h4" component="h1" className="d-inline-block mr-2">
          Create Class
        </Typography>
      </div>
      <div className="row">
        <div className="col-12">
          <CreateClassStepper
            onSubmit={handleSubmit} />
        </div>
      </div>
    </div>
  );
}

export default connector(ClassFormPage);
