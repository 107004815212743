import React from 'react';
/**
 * This component for instance health
 * inspired by atlassian design system which derives from bootstrap theme
 * https://atlassian.design/components/avatar/avatar-presence/examples
 */
export const PRESENSE_TYPE = {
  ONLINE: 'online',
  BUSY: 'busy',
  FOCUS: 'focus',
  OFFLINE: 'offline'
};

const Online: React.FC<{color?:string}> = ({color}) => (
  <svg data-testid="online-presence-icon" height="100%" version="1.1" viewBox="0 0 8 8" width="100%" xmlns="http://www.w3.org/2000/svg">
    <circle fill={color || "#36B37E"} cx="4" cy="4" r="4" />
  </svg>
)
const Busy: React.FC<{color?:string}> = ({color}) => (
  <svg data-testid="busy-presence-icon" height="100%" version="1.1" viewBox="0 0 8 8" width="100%" xmlns="http://www.w3.org/2000/svg">
    <circle fill={color || "#FF5630"} cx="4" cy="4" r="4" />
    <path fill="#FFFFFF" d="M3.3,1.9l2.8,2.8c0.2,0.2,0.2,0.5,0,0.7L5.4,6.1c-0.2,0.2-0.5,0.2-0.7,0L1.9,3.3c-0.2-0.2-0.2-0.5,0-0.7l0.7-0.7C2.8,1.7,3.1,1.7,3.3,1.9z" />
  </svg>
)
const Focus: React.FC<{color?:string}> = ({color}) => (
  <svg data-testid="focus-presence-icon" height="100%" version="1.1" viewBox="0 0 8 8" width="100%" xmlns="http://www.w3.org/2000/svg">
    <path fill={color || "#6554C0"} d="M4,8 C1.790861,8 0,6.209139 0,4 C0,1.790861 1.790861,0 4,0 C6.209139,0 8,1.790861 8,4 C8,6.209139 6.209139,8 4,8 Z M4,6.66666667 C5.47275933,6.66666667 6.66666667,5.47275933 6.66666667,4 C6.66666667,2.52724067 5.47275933,1.33333333 4,1.33333333 C2.52724067,1.33333333 1.33333333,2.52724067 1.33333333,4 C1.33333333,5.47275933 2.52724067,6.66666667 4,6.66666667 Z M4,5.33333333 C3.26362033,5.33333333 2.66666667,4.73637967 2.66666667,4 C2.66666667,3.26362033 3.26362033,2.66666667 4,2.66666667 C4.73637967,2.66666667 5.33333333,3.26362033 5.33333333,4 C5.33333333,4.73637967 4.73637967,5.33333333 4,5.33333333 Z" />
  </svg>
)
const Offline: React.FC<any> = () => (
  <svg data-testid="offline-presence-icon" height="100%" version="1.1" viewBox="0 0 8 8" width="100%" xmlns="http://www.w3.org/2000/svg">
    <path fill="#6B778C" d="M4,8 C6.209139,8 8,6.209139 8,4 C8,1.790861 6.209139,0 4,0 C1.790861,0 0,1.790861 0,4 C0,6.209139 1.790861,8 4,8 Z M4,6 C5.1045695,6 6,5.1045695 6,4 C6,2.8954305 5.1045695,2 4,2 C2.8954305,2 2,2.8954305 2,4 C2,5.1045695 2.8954305,6 4,6 Z" />
    <path fill="#DFE1E6" d="M4,6 C5.1045695,6 6,5.1045695 6,4 C6,2.8954305 5.1045695,2 4,2 C2.8954305,2 2,2.8954305 2,4 C2,5.1045695 2.8954305,6 4,6 Z" />
  </svg>
)

const PresenceIcon: React.FC<{presence: string, color?:string}> = ({presence, color}) => {
  switch(presence) {
    case PRESENSE_TYPE.ONLINE:
      return <Online color={color} />
    case PRESENSE_TYPE.BUSY:
      return <Busy color={color} />
    case PRESENSE_TYPE.FOCUS:
      return <Focus color={color} />
    case PRESENSE_TYPE.OFFLINE:
    default:
      return <Offline />
  }
}

export type PresenceProp = {
  presence: string;
  color?: string;
}
const Presence:  React.FC<PresenceProp> = ({presence, color}) => {
  if (!presence) {
    throw Error('presence prop is required.');
  }
  return (
    <span className="rounded-circle d-flex align-items-center w-100 h-100 " style={{
      backgroundColor: 'rgb(255, 255, 255)',
      boxSizing: 'border-box',
      overflow: 'hidden',
      border: '2px solid rgb(255, 255, 255)',
    }}>
      <PresenceIcon presence={presence} color={color} />
    </span>
  )
}
export default Presence;
