import React from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TClusterFilterCondition } from "./ClustersFilter";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/app.store";
import { groupedTasksListOptionSelector } from "../../../store/selectors";

export default function TaskDefinitionsAutoComplete(props: {
	value: string|null;
	condition: TClusterFilterCondition;
	onChange: (event:any) => void;
}) {
	const {taskDefinitionType} = useSelector((state: RootState) => state.clusters.quickFilters);
	const taskDefinitions = useSelector(groupedTasksListOptionSelector);
	const options = taskDefinitionType === 'all'
		? // list all task definitions
			[
				...taskDefinitions.default,
				...taskDefinitions.shared
			].sort()
		: (
			taskDefinitionType === 'regular'
				// list non-shared task definitions
				? taskDefinitions.default
				// list shared task definitions
				: taskDefinitions.shared
		);

	return (
    <Autocomplete
      id="task-definitions-autocomplete"
			openOnFocus
      options={options}
      getOptionLabel={(option: string) => option as string}
      value={props.value}
			onChange={(event, newValue) => {
				// console.log('TaskDefinitionsAutoComplete', newValue)
				props.onChange({target: {value: newValue}});
			}}
      renderInput={(params: any) =>
				<TextField {...params} label="Task Definitions" size="small" />
			}
    />
  );
}
