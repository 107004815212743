import { ActionsObservable, combineEpics, Epic, ofType, StateObservable } from "redux-observable";
import { RootState } from "../../store/app.store";
import { getTaskDefinitions } from "./taskList.slice";
import { map } from 'rxjs/operators';

function reloadTaskDefinitions(): Epic<any, any, any, any> {
  return (action$: ActionsObservable<any>, state$: StateObservable<RootState>) => action$.pipe(
    ofType(
      'tasks/addTaskDefinition/fulfilled',
      'tasks/updateTaskDefinition/fulfilled',
      'tasks/deleteTaskDefinition/fulfilled',
      'tasks/addTaskDefinition/rejected',
      'tasks/updateTaskDefinition/rejected',
      'tasks/deleteTaskDefinition/rejected',
    ),
    map(() => {
      return getTaskDefinitions({includeResourceDetails: true})
    })
  )
}

export const taskDefinitionEpic: Epic = combineEpics(
  reloadTaskDefinitions()
)
