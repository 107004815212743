import { connect, ConnectedProps, useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/app.store";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Paper } from "@material-ui/core";
import { useMediaQuery } from 'react-responsive'
import { userRoleSelector } from "../../store/selectors";
import TaskTable from "../../components/task-definitions/TaskTable";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { useState } from "react";
import TaskDefinitionFormDialog, { TTaskDefinitionFormValues } from "../../components/task-definitions/TaskDefinitionFormDialog";
import { addTaskDefinition } from "../../components/task-definitions/taskList.slice";
import { gbToMb } from "../../utils/task-definition.utils";
import { TTaskDefinition } from "../../types/app.types";
/* istanbul ignore file */

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  fabButton: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  }
}));

const mapStateToProps = (state: RootState, props: any) => ({
	// users: state.users.data,
	loading: state.tasks.loading,
  data: state.tasks.data
});
const mapDispatchToProps = (dispatch: any) => ({
	
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
const TaskDefinitionListPage: React.FC<PropsFromRedux> = ({
  loading,
  data,
}) => {
  const classes = useStyles();
  const role = useSelector(userRoleSelector);
  const isMobile = useMediaQuery({ maxWidth: 864 });
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const handleNewClick = () => {setCreateDialogOpen(true)}
  const dispatch = useAppDispatch();
  const handleNewDialogSubmit = (e: Omit<TTaskDefinition, "version">) => {
    // console.log("TaskDefinitionFormDialog.handleNewDialogSubmit", e);
    dispatch(addTaskDefinition({
      name: e.name,
      cpu: e.cpu,
      memory: e.memory,
      image: e.image,
      path: "",
      taskDefinitionArn: "",
      version: "",
      tags: e.tags
    }));
  }
  return (
    <div className="container-fluid pt-3 px-5">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">
        <div className="d-flex">
          <Typography variant="h4" component="h1" className="d-inline-block mr-2">
            Task Definitions
          </Typography>
          {isMobile ? (
            <Fab color="primary"
              disabled={role === 'user'}
              className={classes.fabButton}
              aria-label="Add new task definition"
              onClick={handleNewClick}>
              <AddIcon />
            </Fab>
          ) : (
            <Button className="ml-3"
              disabled={role === 'user'}
              color="primary"
              aria-label="Add new task definition"
              onClick={handleNewClick}>
              Add New
            </Button>
          )}
          <TaskDefinitionFormDialog open={createDialogOpen}
            onSubmit={handleNewDialogSubmit}
            onClose={(e: any) => setCreateDialogOpen(false)}
             />
        </div>
        <div></div>
      </div>
      <div className="row">
        <div className="col-12">
          <Paper className="d-flex flex-column p-2 p-md-3 p-lg-4" elevation={1}>
            <div>
              <TaskTable data={data}/>
            </div>
          </Paper>
          
        </div>
      </div>
    </div>
  )
}

export default connector(TaskDefinitionListPage);
