type ItemSelector<T> = (item: T) => string|number;
type DataItemType =  Record<string, any>|string|number;

type SortFunction<T extends DataItemType> = {
  ascending: () => T[];
  descending: () => T[];
  asc: () => T[];
  desc: () => T[];
}

export type TSortUtil<T extends DataItemType> = (data: T[]) => SortFunction<T> & {
  by: (selectorFn: ItemSelector<T>) => SortFunction<T>
}
/**
 * Utility function to easily filter values from provided data
 * 
 * -----------------------------------------------------------
 * @example
 * 
 * interface IData {
 *   id: number;
 *   name: string;
 * }
 * 
 * const TestArr = [
 *   { id: 1, name: 'Milo' },
 *   { id: 2, name: 'Michelle' },
 *   { id: 3, name: 'Dennis' },
 *   { id: 4, name: 'Robert' }
 * ];
 * 
 * const output = sort(TestArr)
 *   .by((item: any) => item.name)
 *   .asc();
 * 
 * const output = sort(TestArr)
 *   .by((item: any) => item.name)
 *   .desc();
 * 
 * @param data 
 */
export function sort<T extends DataItemType = any>(data: T[] = []): {
  by: (selectorFn: ItemSelector<T>) => SortFunction<T>
  ascending?: () => T[];
  descending?: () => T[];
  asc?: () => T[];
  desc?: () => T[];
} {

  if (!data) {
    throw Error('You must pass data to sort');
    // return;
  }

  const defaultSelector: ItemSelector<T> = (item) => {
    switch(typeof item) {
      case 'number':
        return Number(item);
      default:
        return String(item);
    }
  };

  function by(selectorFn: ItemSelector<T>): SortFunction<T> {
    return sortFn(selectorFn);
  };

  function sortFn(selectorFn: ItemSelector<T>): SortFunction<T> {

    // const _value = (
    //   val: string|number
    // ) => ({
    //   toFilter: caseInSensitive ? String(filterValue).toLowerCase() : String(filterValue),
    //   origin: caseInSensitive ? String(targetValue).toLowerCase() : String(targetValue)
    // })

    function ascending() {

      return data.sort(( a, b ) => {
        const valueA = selectorFn(a);
        const valueB = selectorFn(b);
        if((typeof valueA === 'string' && valueA.trim() === "") || valueA === null) return 1;
        if((typeof valueB === 'string' && valueB.trim() === "") || valueB === null) return -1;
        return valueA > valueB ? 1 : -1;
      });
    }

    function descending() {
      return data.sort(( a, b ) => {
        const valueA = selectorFn(a);
        const valueB = selectorFn(b);
        if((typeof valueA === 'string' && valueA.trim() === "") || valueA === null) return 1;
        if((typeof valueB === 'string' && valueB.trim() === "") || valueB === null) return -1;
        return valueA < valueB ? 1 : -1;
      });
    }

    return {
      ascending,
      descending,
      asc: ascending, // alias
      desc: descending, // alias
    };
  }

  switch(typeof data[0]) {
    case 'string':
    case 'number':
      return {
        by,
        ...sortFn(defaultSelector)
      };
    default:
      return {
        by
      };
  }
}

// used for material table sort header
function descendingComparator<D extends Record<string, any> = any, K extends keyof D = string>(a: D, b: D, orderBy: K) {

  // console.log(a[orderBy], b[orderBy])
  // Trimming string to catch emptystring with whitespace " "
  // typeof a[orderBy] === 'string' && a[orderBy].trim() === ""

  if((typeof a[orderBy] === 'string' && a[orderBy].trim() === "") || a[orderBy] === null) return -1;
  if((typeof b[orderBy] === 'string' && b[orderBy].trim() === "") || b[orderBy] === null) return 1;
  const valueA = typeof a[orderBy] === 'string' ? a[orderBy].toLowerCase() : a[orderBy];
  const valueB = typeof b[orderBy] === 'string' ? b[orderBy].toLowerCase() : b[orderBy];
  // console.log(valueA, valueB)
  if (valueB < valueA) {
    return -1;
  }
  if (valueB > valueA) {
    return 1;
  }
  return 0;
}

// used for material table sort header
export function getComparator<D extends Record<string, any>, K extends keyof D = string>(order: 'asc'|'desc', orderBy: K) {
  return order === 'desc'
    ? (a: D, b: D) => descendingComparator(a, b, orderBy)
    : (a: D, b: D) => -descendingComparator(a, b, orderBy);
}

// used for material table sort header
export function stableSort<D extends Record<string, any>>(array: D[], comparator: (a: D, b: D) => number) {
  const stabilizedThis = array.map<[D, number]>((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // console.log('stableSort', array)
  return stabilizedThis.map((el) => el[0]);
}
