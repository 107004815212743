import React, { useEffect, useRef } from "react";
import { TCluster } from "../../../types/app.types";
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
// import { withStyles } from "@material-ui/core";
// import { red } from "@material-ui/core/colors";
import { DialogCloseReason, DialogCloseEvent } from "../../../types/common.types";
import { useFormik } from "formik";
import TextField from '@material-ui/core/TextField';
import differenceInHours from 'date-fns/differenceInHours';
import subHours from 'date-fns/subHours';
import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO'
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { FormControlLabel } from '@material-ui/core';
import { addHours } from "date-fns";
import { AUTO_DESTROY_ADD_HOURS, AUTO_START_HOURS_BEFORE_CLASS, END_DATE_HELPER_TEXT_ON_AUTO_TERMINATION, END_DATE_HELPER_TEXT_ON_MANUAL_TERMINATION } from '../../../constants/app.constants';

function validate(values: {startDate: string, endDate: string}) {
  const errors: Record<string, string|undefined> = {};
  if (!values.endDate || values.endDate.trim() === "")
    errors.endDate = 'End date field is required';
  if (values.endDate && new Date(values.endDate).getTime() < Date.now())
    errors.startDate = 'End date must be after current time';
  if (values.startDate && values.endDate && new Date(values.endDate).getTime() < new Date(values.startDate).getTime())
    errors.endDate = 'End date must be after start date';
  return errors;
}

export type EditClassDateDialogPayload = {
  startDate: string;
  endDate: string;
  launchTimestamp: string;
  launchOnStart: boolean;
  scheduleTermination: boolean;
}

export type EditClassDateDialogProp = {
  data: TCluster;
  // statistics: {total: number; assigned: number;};
  canEditDate: boolean;
  open: boolean;
  onClose: (e?: DialogCloseEvent) => void;
  onConfirm: (payload: EditClassDateDialogPayload) => void;
}

/* istanbul ignore next */
const getStartDateHelperText = (launchOnStart: boolean, formik: any) => {
  const shouldLaunchImmediately =
    differenceInHours(new Date(formik.values.startDate), new Date()) < AUTO_START_HOURS_BEFORE_CLASS;
  const errorText: string = (formik.touched.startDate && formik.errors.startDate) || "";

  const launchTimestamp = formik.values.launchTimestamp !== ""
    ? format(new Date(formik.values.launchTimestamp), "MM/dd/yyyy hh:mm aa")
    : "";

  return !launchOnStart
  ?
    `Launch at ${AUTO_START_HOURS_BEFORE_CLASS} hours prior to start date.`+
    (shouldLaunchImmediately ? 'now' : launchTimestamp) +
    errorText
  : errorText;
}

/* istanbul ignore next */
const getEndDateHelperText = (scheduleTermination: boolean, formik: any) => {
  const { touched, errors } = formik;
  return touched.endDate
  ? (errors.endDate ? errors.endDate : scheduleTermination
        ? `${END_DATE_HELPER_TEXT_ON_AUTO_TERMINATION} (${format(addHours(new Date(formik.values.endDate), AUTO_DESTROY_ADD_HOURS), "MM/dd/yyyy hh:mm aa")})`
        : END_DATE_HELPER_TEXT_ON_MANUAL_TERMINATION
    )
  : scheduleTermination ? END_DATE_HELPER_TEXT_ON_AUTO_TERMINATION : END_DATE_HELPER_TEXT_ON_MANUAL_TERMINATION
}
/**
 * Displays a confirmation dialog when user triggers a deletion of a cluster
 * Requirements
 * - focus on "cancel" button when dialog opens
 *   to avoid user accidentally press "Enter" key
 *   https://github.com/arielpartners/training-image-manager/issues/79
 */
export default function EditClassDateDialog(props: EditClassDateDialogProp) {
  const { onClose, onConfirm, open, data, canEditDate } = props;
  const [ launchOnStart, setLaunchOnStart] = React.useState(false);
  const [ scheduleTermination, setScheduleTermination] = React.useState(true);
  const handleClose = (e: any, reason: DialogCloseReason) => {
    onClose({reason});
  };
  const handleConfirm = () => {
    onConfirm({
      startDate: formatISO(new Date(formik.values.startDate)),
      endDate: formatISO(new Date(formik.values.endDate)),
      launchTimestamp: formik.values.launchTimestamp && formik.values.launchTimestamp !== "" ? formatISO(new Date(formik.values.launchTimestamp)) : "",
      launchOnStart,
      scheduleTermination
    });
    onClose({reason: 'confirmClick'});
  }

  // console.log(data)

  const formik = useFormik({
    initialValues: {
      startDate: format(new Date(data.startDate), "yyyy-MM-dd'T'HH:mm"),
      endDate: format(new Date(data.endDate), "yyyy-MM-dd'T'HH:mm"),
      launchTimestamp: data.launchTimestamp && data.launchTimestamp !== "" ? format(new Date(data.launchTimestamp), "yyyy-MM-dd'T'HH:mm") : ""
    },
    validate,
    onSubmit: (values) => { }
  });

  useEffect(() => {
    // console.log('data change', data)
    formik.setFieldValue('startDate', format(new Date(data.startDate), "yyyy-MM-dd'T'HH:mm"));
    formik.setFieldValue('endDate', format(new Date(data.endDate), "yyyy-MM-dd'T'HH:mm"));
    // formik.setFieldValue('endDate', format(new Date(data.endDate), "yyyy-MM-dd'T'HH:mm"));
  }, [data.startDate, data.endDate])

  return (
    <Dialog onClose={handleClose} aria-labelledby="edit-date-dialog-title" open={open}>
      <DialogTitle data-testid="edit-date-dialog-title">
        Change class date
      </DialogTitle>

      <DialogContent>
        <DialogContentText data-testid="edit-date-dialog-description">
          Class name: {data.className}
        </DialogContentText>
        <div className="row">
          <div className="col-12 col-lg-6 mb-3">
            <TextField
              error={!!(formik.touched.startDate && formik.errors.startDate)}
              id="startDate"
              label="Start date"
              type="datetime-local"
              fullWidth required 
              // defaultValue={format(new Date(), "yyyy-MM-dd'T'HH:mm")}
              value={formik.values.startDate}
              onBlur={formik.handleBlur}
              onChange={(e: any) => {
                formik.handleChange(e);
                if (e.target.value && e.target.value !== "") {
                  const diff = differenceInHours(new Date(e.target.value), new Date());
                  // console.log('diff', diff);
                  setLaunchOnStart(diff < AUTO_START_HOURS_BEFORE_CLASS);
                  const launchTimestamp = diff < AUTO_START_HOURS_BEFORE_CLASS
                    ? format(new Date(e.target.value), "yyyy-MM-dd'T'HH:mm")
                    : subHours(new Date(e.target.value), AUTO_START_HOURS_BEFORE_CLASS);
                  formik.setFieldValue('launchTimestamp', format(new Date(launchTimestamp), "yyyy-MM-dd'T'HH:mm"));
                  
                  // formik.values.launchTimestamp = e.value;
                }
                
              }}
              InputLabelProps={{
                shrink: true, style: { fontWeight: 500 }
              }}
              helperText={getStartDateHelperText(launchOnStart, formik)}
              autoFocus
            />
            <div className="px-2 mb-3">
                <FormControlLabel
                  control={
                    <Checkbox checked={launchOnStart} onClick={() => {setLaunchOnStart(!launchOnStart)}} />
                  }
                  label={
                    <Typography variant="caption">
                      Launch immediately.
                    </Typography>
                  }
                />
            </div>
          </div>
          <div className="col-12 col-lg-6 mb-3">
            <TextField
              error={!!(formik.errors.endDate)}
              id="endDate"
              label="End date"
              type="datetime-local"
              fullWidth required
              value={formik.values.endDate}
              helperText={getEndDateHelperText(scheduleTermination, formik)}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              inputProps={{
                min: formik.values.startDate,
              }}
              InputLabelProps={{
                shrink: true, style: { fontWeight: 500 }
              }}
            />
            <div className="px-2 mb-3">
              <FormControlLabel
                control={
                  <Checkbox checked={scheduleTermination} onClick={() => {setScheduleTermination(!scheduleTermination)}} />
                }
                label={
                  <Typography variant="caption">
                    {scheduleTermination
                      ? "Terminate automatically"
                      : "Terminate manually"
                    }
                  </Typography>
                }
              />
            </div>
          </div>
        </div>
        {/* <pre className="w-50 d-inline-block">
            {formik.values ? JSON.stringify(formik.values, null, "\t") : ""}
          </pre> */}
        {/* <pre className="w-50 d-inline-block">
            {formik.values ? JSON.stringify(formik.values, null, "\t") : ""}
          </pre>
          <pre className="w-50 d-inline-block">
            {formik.errors ? JSON.stringify(formik.errors, null, "\t") : ""}
          </pre>
        
        {canEditDate + ""} */}
      </DialogContent>
      <DialogActions>
        <Button data-testid="edit-date-dialog-confirm-button" color="primary" variant="contained" disabled={!canEditDate || !formik.isValid} onClick={handleConfirm}>
          Confirm
        </Button>
        <Button data-testid="edit-date-dialog-cancel-button" onClick={() => onClose({reason: 'cancelClick'})}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
