import React, { useRef, useEffect, useState } from "react";
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { TParticipant } from "../../types/app.types";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Tooltip from '@material-ui/core/Tooltip';
import SaveIcon from '@material-ui/icons/Save';
import ReplayIcon from '@material-ui/icons/Replay';

function displayName(participant: TParticipant, excludeTitle: boolean = false) {
  const {title, firstName, lastName} = participant;
  const fullName = `${firstName} ${lastName}`;

  if (!excludeTitle && title && title !== "")
    return `${title}. ${fullName}`;
  else
    return fullName;
}

export type ParticipantInlineEditV2Prop = {
  id: string|number;
  taskArn: string;
  defaultValue: TParticipant;
  disabled?: boolean;
  isEditing?: boolean;
  onOpen?: () =>void;
  onClose?: () =>void;
  onSave: any; onCancel?: any; validate?: any;
};

const ParticipantInlineEditV2: React.FC<ParticipantInlineEditV2Prop> = ({
  id, defaultValue, disabled, onSave, onCancel, onOpen, onClose,
  validate, isEditing, taskArn
}) => {

  const firstNameInputRef = useRef<HTMLInputElement|null>(null);
  const lastNameInputRef = useRef<HTMLInputElement|null>(null);
  const prefixInputRef = useRef<HTMLInputElement|null>(null);
  const emailInputRef = useRef<HTMLInputElement|null>(null);

  const [value, setValue] = useState<TParticipant>({
    firstName: defaultValue.firstName || "",
    lastName: defaultValue.lastName || "",
    email: defaultValue.email || "",
    title: defaultValue.title || "",
  });

  const [editing, setState] = useState(isEditing);
  const [error, setError] = useState<Record<string, string>>({});

  const hasError = () => !!Object.keys(error).length;

  const handleChange = (field: keyof TParticipant) => (e: any) => {
    const newVal = {...value, [field]: e.target.value };
    if (!validate) {
      setValue(newVal);
      return;
    }
    const validateResult = validate(e.target.value);
    setError(validateResult);
    setValue(newVal);
  };

  const handleMouseDown = (event: any) => {
    event.preventDefault();
  };

  const handleKeyDown = (action: (event: any) => void) => (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      action(e)
    }
  }
  const handleSaveClick = (e: any) => {
    e.preventDefault();
    setState(false);

    if (firstNameInputRef.current && lastNameInputRef.current && emailInputRef.current && prefixInputRef.current) {
      const newValue = {
        firstName: firstNameInputRef.current.value.trim(),
        lastName: lastNameInputRef.current.value.trim(),
        email: emailInputRef.current.value.trim(),
        title: prefixInputRef.current.value.trim()
      };
      setValue(newValue);
      // setIsEmpty(checkEmpty(newValue))
      if (newValue !== defaultValue && onSave){
        onSave(newValue);
      }
      if (onClose) onClose();
    }
  }
  const handleCancelClick = (e: any) => {
    e.preventDefault();
    setState(false);
    setValue(val => ({...val, ...defaultValue}));
    if (onCancel) onCancel();
    if (onClose) onClose();
  }
  const handleViewFieldClick = (e: any) => {
    e.preventDefault();
    if (!disabled) {
      setState(true);
      if (onOpen) onOpen();
    }
  }

  const getFieldId = (fieldName: string) => {
    return `${fieldName}-${taskArn}-${id}`;
  }

  // Side effect for whenever, editing is set to true
  // Focus to input field
  useEffect(() => {
    if (editing && prefixInputRef.current) prefixInputRef.current.focus();
  }, [editing]);

  useEffect(() => {
    if (isEditing) {
      setState(true);
    } else {
      setState(false);
    }
    if (value !== defaultValue) {
      setValue(defaultValue);
    }

  }, [isEditing, defaultValue])

  return (
    <>
      <div data-testid="participant-inline-view-wrapper" className="w-100" onClick={(e) => handleViewFieldClick(e)}
        style={{display: editing ? 'none' : 'flex'}}>
        <Grid container spacing={0}>
          <Hidden lgUp>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={4}>
              <Typography data-testid="participant-name" variant="subtitle1" className="mr-2">
                {displayName(value)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={8}>
              <Typography data-testid="participant-email" variant="body2">
                {value.email}
              </Typography>
            </Grid>
          </Hidden>
          <Hidden mdDown>
            <Grid item lg={2} className="pl-3 d-flex align-items-center" style={{maxWidth: 80}}>
              <Typography data-testid="participant-title" variant="subtitle1">
                {value.title}
              </Typography>
            </Grid>
            <Grid item lg={5} className="d-flex align-items-center">
              <Typography data-testid="participant-name" variant="subtitle1">
                {displayName(value, true)}
              </Typography>
            </Grid>
            <Grid item lg={5} className="d-flex align-items-center">
              <Typography data-testid="participant-email" variant="body1">
                {value.email}
              </Typography>
            </Grid>
          </Hidden>
        </Grid>
        
      </div>
      <div data-testid="participant-inline-edit-wrapper" className="w-100 justify-content-end align-items-center" style={{ display: editing ? 'flex' : 'none' }}>
        <Grid container spacing={0}>
          {/** Mobile view */}
          <Hidden lgUp>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={4}>
              <FormControl data-testid="participant-prefix-edit-control" variant="outlined" className="p-1" style={{minWidth: 60, maxWidth: 80}}>
                {/* <InputLabel className={classes.label} htmlFor={getFieldId('name-prefix')}>Title</InputLabel> */}
                <OutlinedInput
                  id={getFieldId('name-prefix')}
                  name="prefix"
                  type="text"
                  margin="dense"
                  inputRef={prefixInputRef}
                  value={value.title}
                  disabled={disabled}
                  onKeyDown={handleKeyDown(handleSaveClick)}
                  onChange={handleChange('title')} />
              </FormControl>
              <FormControl data-testid="participant-first-name-edit-control" variant="outlined" className="p-1" style={{minWidth: 100, width: 'calc(50% - 40px)'}}>
                {/* <InputLabel className={classes.label} htmlFor={getFieldId('firstName')}>First Name</InputLabel> */}
                <OutlinedInput
                  id={getFieldId('firstName')}
                  name="firstName"
                  type="text"
                  margin="dense"
                  inputRef={firstNameInputRef}
                  value={value.firstName}
                  disabled={disabled}
                  
                  onKeyDown={handleKeyDown(handleSaveClick)}
                  onChange={handleChange('firstName')} />
              </FormControl>
              <FormControl data-testid="participant-last-name-edit-control" variant="outlined" className="p-1" style={{minWidth: 100, width: 'calc(50% - 40px)'}}>
                {/* <InputLabel className={classes.label} htmlFor={getFieldId('lastName')}>Last Name</InputLabel> */}
                <OutlinedInput
                  id={getFieldId('lastName')}
                  name="lastName"
                  type="text"
                  margin="dense"
                  inputRef={lastNameInputRef}
                  value={value.lastName}
                  disabled={disabled}
                  
                  onKeyDown={handleKeyDown(handleSaveClick)}
                  onChange={handleChange('lastName')} />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={8}>
              <FormControl data-testid="participant-email-edit-control" variant="outlined" className="w-100 p-1">
                {/* <InputLabel className={classes.label} htmlFor={getFieldId('email')}>Email</InputLabel> */}
                <OutlinedInput
                  id={getFieldId('email')}
                  name="email"
                  type="text"
                  inputRef={emailInputRef}
                  value={value.email}
                  disabled={disabled}
                  margin="dense"
                  onKeyDown={handleKeyDown(handleSaveClick)}
                  onChange={handleChange('email')} />
              </FormControl>
            </Grid>
          </Hidden>
          {/** Desktop view */}
          <Hidden mdDown>
            <Grid item lg={2} className="pl-3" style={{maxWidth: 80}}>
              <FormControl data-testid="participant-prefix-edit-control" variant="outlined" className="p-1" style={{minWidth: 60, maxWidth: 80}}>
                {/* <InputLabel className={classes.label} htmlFor={getFieldId('name-prefix')}>Title</InputLabel> */}
                <OutlinedInput
                  id={getFieldId('name-prefix')}
                  name="prefix"
                  type="text"
                  inputRef={prefixInputRef}
                  value={value.title}
                  disabled={disabled}
                  margin="dense"
                  onKeyDown={handleKeyDown(handleSaveClick)}
                  onChange={handleChange('title')} />
              </FormControl>
            </Grid>
            <Grid item lg={5} style={{maxWidth: 'unset', flexGrow: 0}}>
              <FormControl data-testid="participant-first-name-edit-control" variant="outlined" className="w-50 p-1">
                {/* <InputLabel className={classes.label} htmlFor={getFieldId('firstName')}>First Name</InputLabel> */}
                <OutlinedInput
                  id={getFieldId('firstName')}
                  name="firstName"
                  type="text"
                  margin="dense"
                  inputRef={firstNameInputRef}
                  value={value.firstName}
                  disabled={disabled}
                  onKeyDown={handleKeyDown(handleSaveClick)}
                  onChange={handleChange('firstName')} />
              </FormControl>
              <FormControl data-testid="participant-last-name-edit-control" variant="outlined" className="w-50 p-1">
                {/* <InputLabel className={classes.label} htmlFor={getFieldId('lastName')}>Last Name</InputLabel> */}
                <OutlinedInput
                  id={getFieldId('lastName')}
                  name="lastName"
                  type="text"
                  margin="dense"
                  inputRef={lastNameInputRef}
                  value={value.lastName}
                  disabled={disabled}
                  
                  onKeyDown={handleKeyDown(handleSaveClick)}
                  onChange={handleChange('lastName')} />
              </FormControl>
            </Grid>
            <Grid item lg={5} style={{maxWidth: 'unset', flexGrow: 1}}>
              <FormControl data-testid="participant-email-edit-control" variant="outlined" className="w-100 p-1">
                {/* <InputLabel className={classes.label} htmlFor={getFieldId('email')}>Email</InputLabel> */}
                <OutlinedInput
                  id={getFieldId('email')}
                  name="email"
                  type="text"
                  margin="dense"
                  inputRef={emailInputRef}
                  value={value.email}
                  disabled={disabled}
                  
                  onKeyDown={handleKeyDown(handleSaveClick)}
                  onChange={handleChange('email')}
                  />
              </FormControl>
            </Grid>
          </Hidden>
        </Grid>
        
        <div className="d-flex justify-content-end align-items-center" style={{minWidth: 100}}>
          
          <IconButton data-testid="participant-inline-edit-save-btn" size="small" aria-label="Save value"
            disabled={hasError()}
            onClick={(e) => handleSaveClick(e)}
            onKeyDown={handleKeyDown(handleSaveClick)}
            onMouseDown={e => handleMouseDown(e)}>
            <Tooltip title="Save value">
              <SaveIcon />
            </Tooltip>
          </IconButton>
        
        
          <IconButton data-testid="participant-inline-edit-cancel-btn" size="small" aria-label="Cancel edit"
            onClick={e => handleCancelClick(e)}
            onKeyDown={handleKeyDown(handleCancelClick)}
            onMouseDown={e => handleMouseDown(e)}>
            <Tooltip title="Cancel edit">
              <ReplayIcon />
            </Tooltip>
          </IconButton>
        
        </div>
      </div>
    </>
  )
}

export default ParticipantInlineEditV2;
