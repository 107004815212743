import React, { useEffect, useRef } from "react";
import { TCluster } from "../../../types/app.types";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { DialogCloseReason, DialogCloseEvent } from "../../../types/common.types";
import addHours from 'date-fns/addHours';
import format from 'date-fns/format';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { FormControlLabel } from '@material-ui/core';
import formatISO from 'date-fns/formatISO'
import { useLayoutEffect } from "react";
import { createRef } from "react";
import { RemoveClusterArgs } from "../clusters.actions";

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

export type DeleteClusterDialogProp = {
  data: TCluster;
  statistics: {total: number; assigned: number;};
  open: boolean;
  onClose: (e?: DialogCloseEvent) => void;
  onConfirm: (payload?: RemoveClusterArgs) => void;
}
let intervalFn: any = createRef();
/**
 * Displays a confirmation dialog when user triggers a deletion of a cluster
 * Requirements
 * - focus on "cancel" button when dialog opens
 *   to avoid user accidentally press "Enter" key
 *   https://github.com/arielpartners/training-image-manager/issues/79
 */
export default function DeleteClusterDialog(props: DeleteClusterDialogProp) {
  const { onClose, onConfirm, open, data, statistics } = props;
  const handleClose = (e: any, reason: DialogCloseReason) => {
    onClose({reason});
  };
  const handleConfirm = () => {
    onConfirm({
      cluster: data,
      terminateImmediately: true,
      terminateAt: format(new Date(), "yyyy-MM-dd'T'HH:mm")
    });
    onClose({reason: 'confirmClick'});
  }


  return (
    <Dialog onClose={handleClose}
      data-testid="delete-cluster-dialog"
      aria-labelledby="delete-cluster-dialog-title"
      open={open}>
      <DialogTitle data-testid="delete-cluster-dialog-title">
        You're about to delete this class
      </DialogTitle>

      <DialogContent>
        <DialogContentText component="div" data-testid="delete-cluster-dialog-description">
          <div>
            Class name: {data.className}
          </div>
        </DialogContentText>
        <List>
          <ListItem>
            <ListItemText primary={'There are total ' + statistics.total + ' instance(s).'} />
          </ListItem>
          <ListItem>
            <ListItemText primary={statistics.assigned + ' instance(s) are assigned'} />
          </ListItem>
          <ListItem>
            <ListItemText primary={data.runningTasks + ' instance(s) in use'} />
          </ListItem>
          <ListItem>
            <ListItemText primary={data.pendingTasks + ' instance(s) are currently pending'} />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <ColorButton data-testid="delete-cluster-dialog-confirm-button"  onClick={handleConfirm}>
          Delete
        </ColorButton>
        <Button data-testid="delete-cluster-dialog-cancel-button" onClick={() => onClose({reason: 'cancelClick'})} autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
